export const generateUniqueId = (): string => {
 const timestamp = new Date().getTime().toString(16); // Ottieni la data corrente come stringa esadecimale
 const randomStr = Math.random().toString(16).substr(2); // Genera un numero casuale come stringa esadecimale

 return `${timestamp}-${randomStr}`;
};

/*** Get the price adding the tax amount
 * @param {number} value represents the price of the element without taxes
 * @param {number} tax represents the tax augment in %
 */
export const getPriceWithIva = (value: number, tax: number): number => {
 const valueToAdd = (value * tax) / 100;
 return value + valueToAdd;
};

/*** Get the price removing the amount added by tax
 * @param {number} value represents the price of the element
 * @param {number} tax represents the augment in %
 */
export const getPriceWithoutIva = (value: number, tax: number): number => {
 return (value * 100) / (100 + tax);
};

export const checkWeekBefore = (date: string): boolean => {
 const target = new Date(date);
 const today = new Date();

 const differenceInTime = target.getTime() - today.getTime();

 const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

 if (Math.abs(differenceInTime) < oneWeekInMilliseconds) {
  return true;
 } else {
  return false;
 }
};

export const checkForRender = (
 list: Array<any>,
 search: string,
 partner: string,
 firstDataPresent: boolean
): boolean => {
 return (
  firstDataPresent || list.length > 0 || (list.length === 0 && (search !== "" || partner !== ""))
 );
};

const base64Conversion = (file: File): Promise<string> => {
 return new Promise((resolve, reject) => {
  var reader = new FileReader();
  reader.onload = function () {
   resolve(reader.result as string);
  };
  reader.onerror = function (error) {
   console.log("Error: ", error);
   reject();
  };
  reader.readAsDataURL(file);
 });
};

export const getBase64 = async (file: File): Promise<string> => {
 const convertedData = await base64Conversion(file);
 return convertedData;
};

export const decodeFileFromBase64 = (data: string, type: string): Blob => {
 const byteCharacters = atob(data);
 const byteNumbers = new Array(byteCharacters.length);

 for (let i = 0; i < byteCharacters.length; i++) {
  byteNumbers[i] = byteCharacters.charCodeAt(i);
 }

 const byteArray = new Uint8Array(byteNumbers);
 const blob = new Blob([byteArray], { type: type });
 return blob;
};

export const getWeekday = (dayNumber: number): string => {
 switch (dayNumber) {
  case 1:
   return "app.monday";
  case 2:
   return "app.tuesday";
  case 3:
   return "app.wednesday";
  case 4:
   return "app.thursday";
  case 5:
   return "app.friday";
  case 6:
   return "app.saturday";
  case 7:
   return "app.sunday";
  default:
   return "app.today";
 }
};

export const retriveStripeIva = (x: number): number => {
 return x / 122;
};
