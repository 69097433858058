import React, { ReactElement, useState } from "react";
import { useIntl } from "react-intl";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import useMediaQuery from "@mui/material/useMediaQuery";

import { IAllAddonsAPI } from "types/api/addonsApiInterface";
import { addonsSectionType, addonsTeamsPermissionType } from "types/global/teams";
import { addonsPermissionType, addonsSelectedList } from "types/global/user";

import TeamsCloudbox from "./addonsSettingSections/TeamsCloudbox";
import TeamsCloudbucket from "./addonsSettingSections/TeamsCloudbucket";
import TeamsFirewall from "./addonsSettingSections/TeamsFirewall";
import TeamsFloatingIp from "./addonsSettingSections/TeamsFloatingIp";
import TeamsLoadBalancer from "./addonsSettingSections/TeamsLoadBalancer";
import TeamsNetwork from "./addonsSettingSections/TeamsNetwork";
import TeamsSnapshot from "./addonsSettingSections/TeamsSnapshot";
import TeamsVolumes from "./addonsSettingSections/TeamsVolumes";

const AddonsSection = ({
 control,
 addons,
 selectedAddons,
 addonsPermissions,
 setAddons,
 addAddons,
 setPermission
}: {
 control: any;
 addons: IAllAddonsAPI | null;
 selectedAddons: addonsSelectedList;
 addonsPermissions: addonsPermissionType;
 setAddons: (section: addonsSectionType, value: Array<number>) => void;
 addAddons: (section: addonsSectionType, value: Array<{ id: number; label: string }>) => void;
 setPermission: (section: addonsTeamsPermissionType, value: boolean) => void;
}): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [section, setSection] = useState<number>(0);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 const RenderTabContent = (): ReactElement => {
  switch (section) {
   case 0:
    return (
     <TeamsCloudbox
      control={control}
      addons={addons}
      selectedAddons={selectedAddons}
      addonsPermissions={addonsPermissions}
      setAddons={setAddons}
      addAddons={addAddons}
      setPermission={setPermission}
     />
    );
   case 1:
    return (
     <TeamsVolumes
      control={control}
      addons={addons}
      selectedAddons={selectedAddons}
      addonsPermissions={addonsPermissions}
      setAddons={setAddons}
      addAddons={addAddons}
      setPermission={setPermission}
     />
    );
   case 2:
    return (
     <TeamsFloatingIp
      control={control}
      addons={addons}
      selectedAddons={selectedAddons}
      addonsPermissions={addonsPermissions}
      setAddons={setAddons}
      addAddons={addAddons}
      setPermission={setPermission}
     />
    );
   case 3:
    return (
     <TeamsNetwork
      control={control}
      addons={addons}
      selectedAddons={selectedAddons}
      addonsPermissions={addonsPermissions}
      setAddons={setAddons}
      addAddons={addAddons}
      setPermission={setPermission}
     />
    );
   case 4:
    return (
     <TeamsSnapshot
      control={control}
      addons={addons}
      selectedAddons={selectedAddons}
      addonsPermissions={addonsPermissions}
      setAddons={setAddons}
      addAddons={addAddons}
      setPermission={setPermission}
     />
    );
   case 5:
    return (
     <TeamsFirewall
      control={control}
      addons={addons}
      selectedAddons={selectedAddons}
      addonsPermissions={addonsPermissions}
      setAddons={setAddons}
      addAddons={addAddons}
      setPermission={setPermission}
     />
    );
   case 6:
    return (
     <TeamsLoadBalancer
      control={control}
      addons={addons}
      selectedAddons={selectedAddons}
      addonsPermissions={addonsPermissions}
      setAddons={setAddons}
      addAddons={addAddons}
      setPermission={setPermission}
     />
    );
   case 7:
    return (
     <TeamsCloudbucket
      control={control}
      addons={addons}
      selectedAddons={selectedAddons}
      addonsPermissions={addonsPermissions}
      setAddons={setAddons}
      addAddons={addAddons}
      setPermission={setPermission}
     />
    );
   default:
    return (
     <TeamsCloudbox
      control={control}
      addons={addons}
      selectedAddons={selectedAddons}
      addonsPermissions={addonsPermissions}
      setAddons={setAddons}
      addAddons={addAddons}
      setPermission={setPermission}
     />
    );
  }
 };

 return (
  <Accordion>
   <AccordionSummary expandIcon={<ExpandMoreIcon />}>Addons</AccordionSummary>
   <AccordionDetails>
    <Tabs
     value={section}
     onChange={handleChangeTab}
     variant={desktopViewPort ? "standard" : "scrollable"}
     scrollButtons={true}
     allowScrollButtonsMobile
     sx={{ mb: 2 }}>
     <Tab label={intl.formatMessage({ id: "app.cloudbox" })} />
     <Tab label={intl.formatMessage({ id: "app.volumes" })} />
     <Tab label={intl.formatMessage({ id: "app.floatingIp" })} />
     <Tab label={intl.formatMessage({ id: "app.networks" })} />
     <Tab label={intl.formatMessage({ id: "app.snapshots" })} />
     <Tab label={intl.formatMessage({ id: "app.firewall" })} />
     <Tab label={intl.formatMessage({ id: "app.loadBalancer" })} />
     <Tab label={intl.formatMessage({ id: "app.cloudbuckets" })} />
    </Tabs>
    <RenderTabContent />
   </AccordionDetails>
  </Accordion>
 );
};

export default AddonsSection;
