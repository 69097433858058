import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getBase64 } from "helpers/generic";

import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

const VisuallyHiddenInput = styled("input")({
 clip: "rect(0 0 0 0)",
 clipPath: "inset(50%)",
 height: 1,
 overflow: "hidden",
 position: "absolute",
 bottom: 0,
 left: 0,
 whiteSpace: "nowrap",
 width: 1
});

const FileUploader = ({
 description,
 setFile
}: {
 description: string;
 setFile: (file: string) => void;
}): ReactElement => {
 const [loading, setLoading] = useState<boolean>(false);
 const [fileError, setFileError] = useState<boolean>(false);
 const [fileData, setFileData] = useState<string>("");

 const handleCreateFile = async (files: FileList | null, index: number) => {
  setLoading(true);
  if (files && files.length > 0) {
   const fileExtension = files[0].name.substring(files[0].name.lastIndexOf(".") + 1);
   if (fileExtension !== "pdf") {
    setFileError(true);
   } else {
    setFileError(false);
   }
   const size = files[0].size;
   if (size > 128000000) {
    setFileError(true);
   } else {
    const result = await getBase64(files[0]);
    setFileData(result);
    setFile(result);
   }
  }
  setLoading(false);
 };

 return loading ? (
  <Stack direction="row" alignItems="center" spacing={2}>
   <CircularProgress />
   <Typography>
    <FormattedMessage id="profile.security.fileInUpload" />
   </Typography>
  </Stack>
 ) : (
  <Stack direction="row" alignItems="center" spacing={2}>
   <IconSelector
    icon={fileData ? "TaskIcon" : "InsertDriveFileIcon"}
    props={{ color: fileError ? "error" : fileData ? "success" : "info" }}
   />
   {fileError ? (
    <Typography color="error">
     <FormattedMessage id="profile.security.fileFormatError" />
    </Typography>
   ) : fileData ? (
    <Typography color="green">
     <FormattedMessage id="profile.security.fileUploaded" />
    </Typography>
   ) : (
    <Typography>
     <FormattedMessage id={description} />
    </Typography>
   )}
   <IconButton role={undefined} tabIndex={-1} component="label">
    <IconSelector icon="FileUploadIcon" />
    <VisuallyHiddenInput
     type="file"
     accept=".pdf"
     onChange={(event) => handleCreateFile(event.target.files, 0)}
    />
   </IconButton>
  </Stack>
 );
};

export default FileUploader;
