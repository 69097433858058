import React, { ReactElement } from "react";

import DeleteTeamsUser from "./actions/DeleteTeamsUser";
import UpdateTeamsPassword from "./actions/UpdateTeamsPassword";
import UpdateTeamsUser from "./actions/UpdateTeamsUser";

const TeamsActions = ({
 id,
 user,
 closeMenu
}: {
 id: number;
 user?: string;
 closeMenu: () => void;
}): ReactElement => {
 return (
  <>
   <UpdateTeamsUser id={id} userMail={user || ""} closeMenu={closeMenu} />
   <UpdateTeamsPassword id={id} closeMenu={closeMenu} />
   <DeleteTeamsUser id={id} closeMenu={closeMenu} />
  </>
 );
};

export default TeamsActions;
