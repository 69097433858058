import React, { ReactElement, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";

import WebIcon from "@mui/icons-material/Web";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { domainValidation } from "constants/regexp";
import { steps } from "constants/sites";

import AppModal from "components/shared/modal/AppModal";

import { postGetServerPhpTypes, postGetServerPhpVersion } from "redux/handlers/serverHandler";
import { addNewSite, getAllIspNotPaged, postCheckSiteName } from "redux/handlers/websitesHandler";

import { getIspList } from "redux/selectors/ispSelector";

import { useAppDispatch } from "hooks/reduxHook";

const SitesWizard = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const navigate = useNavigate();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const ispList = useSelector(getIspList);

 const [activeStep, setActiveStep] = useState<number>(0);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [versions, setVersions] = useState<Array<string>>([]);
 const [types, setTypes] = useState<Array<string>>([]);
 const [nameChecked, setNameChecked] = useState<boolean>(false);
 const [stepLoading, setStepLoading] = useState<boolean>(false);
 const [firstCheck, setFirstCheck] = useState<boolean>(false);
 const [aliasSite, setAliasSite] = useState<string>("");
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   await dispatch(getAllIspNotPaged("webserver"));
   setIsLoading(false);
  })();
 }, []);

 const { control, formState, reset, watch, getValues, setValue } = useForm({
  defaultValues: {
   site: "",
   quota: 5000,
   php_mode: "",
   isp_id: 0,
   ip: "",
   php_version: "",
   lets_encrypt: false
  }
 });

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);
 const handleReset = () => {
  reset();
  setNameChecked(false);
  setFirstCheck(false);
  setAliasSite("");
  setActiveStep(0);
 };

 const handleNext = async () => {
  if (activeStep === 2) {
   setVersions(
    await dispatch(
     postGetServerPhpVersion(
      ispList.find((element) => element.id === watch("isp_id"))?.hostname || ""
     )
    )
   );
   setTypes(
    await dispatch(
     postGetServerPhpTypes(
      ispList.find((element) => element.id === watch("isp_id"))?.hostname || ""
     )
    )
   );
  }
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleConfirm = async () => {
  setLoading(true);
  const dataToSend = getValues();
  await dispatch(
   addNewSite({
    site: dataToSend.site,
    quota: dataToSend.quota,
    php_mode: dataToSend.php_mode,
    isp_id: dataToSend.isp_id,
    ip: dataToSend.ip,
    php_version: dataToSend.php_version,
    lets_encrypt: dataToSend.lets_encrypt
   })
  );

  setLoading(false);
  handleClose();
  handleReset();
  navigate("/app/sites");
 };

 const handleCheckSiteName = async () => {
  setStepLoading(true);
  let check = await dispatch(postCheckSiteName(getValues("site"), getValues("isp_id")));
  setNameChecked(check.check);
  if (!check.check && check.site) setAliasSite(check.site);
  setFirstCheck(true);
  setStepLoading(false);
 };

 const renderSiteChecked = () => {
  if (nameChecked) {
   return (
    <Alert severity="success">
     <FormattedMessage id="site.add.nameAvailable" />
    </Alert>
   );
  } else {
   if (aliasSite) {
    return (
     <Alert severity="error">
      <FormattedMessage id="site.add.nameNotAvailableAlias" values={{ site: aliasSite }} />
     </Alert>
    );
   } else {
    return (
     <Alert severity="error">
      <FormattedMessage id="site.add.nameNotAvailable" />
     </Alert>
    );
   }
  }
 };

 const renderStepContent = (index: number) => {
  switch (index) {
   case 1:
    return (
     <Controller
      name="site"
      control={control}
      rules={{
       required: true,
       pattern: domainValidation
      }}
      render={({ field }) => (
       <TextField
        {...field}
        onChange={(e) => {
         setValue("site", e.target.value);
         setNameChecked(false);
         setFirstCheck(false);
         setAliasSite("");
        }}
        fullWidth={true}
        label={intl.formatMessage({ id: "sites.name" })}
        error={formState.isDirty && !!formState?.errors?.site}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="on"
       />
      )}
     />
    );
   case 2:
    return (
     <Controller
      name="quota"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "sites.hd_quota" })}
        error={formState.isDirty && !!formState?.errors?.quota}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="on"
        type="number"
       />
      )}
     />
    );
   case 0:
    return (
     <Controller
      name="isp_id"
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        onChange={(e, value) => setValue("isp_id", value?.id || 0)}
        options={ispList
         .filter((element) => element.id)
         .map((element) => {
          return { label: element.hostname || "", id: element.id };
         })}
        value={ispList
         .filter((element) => element.id)
         .map((element) => {
          return { label: element.hostname || "", id: element.id };
         })
         .find((element) => element.id === getValues("isp_id"))}
        renderInput={(params) => (
         <TextField
          {...params}
          {...field}
          error={formState.isDirty && !!formState?.errors?.isp_id}
          label={<FormattedMessage id="sites.isp_id" />}
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
      )}
     />
    );
   case 3:
    return (
     <Stack>
      <Controller
       name="php_mode"
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) => setValue("php_mode", value || "")}
         options={types}
         value={types.find((element) => element === getValues("php_mode"))}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           error={formState.isDirty && !!formState?.errors?.php_mode}
           label={<FormattedMessage id="sites.php_mode" />}
           InputLabelProps={{ shrink: true }}
          />
         )}
        />
       )}
      />
      <Controller
       name="php_version"
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) => setValue("php_version", value || "")}
         options={versions}
         value={versions.find((element) => element === getValues("php_version"))}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           error={formState.isDirty && !!formState?.errors?.php_version}
           label={<FormattedMessage id="sites.php_version" />}
           InputLabelProps={{ shrink: true }}
          />
         )}
        />
       )}
      />
     </Stack>
    );
   case 4:
    return (
     <Stack>
      <Controller
       name="ip"
       control={control}
       rules={{ required: true }}
       render={({ field }) => (
        <Autocomplete
         fullWidth={true}
         autoHighlight
         sx={{ my: 2 }}
         onChange={(e, value) => setValue("ip", value?.ip || "")}
         options={ispList
          .filter((element) => element.id === getValues("isp_id"))
          .map((element) => {
           return { label: element.ip1 || "", ip: element.ip1 };
          })}
         value={ispList
          .map((element) => {
           return { label: element.ip1 || "", ip: element.ip1 };
          })
          .find((element) => element.ip === getValues("ip"))}
         renderInput={(params) => (
          <TextField
           {...params}
           {...field}
           error={formState.isDirty && !!formState?.errors?.ip}
           label={<FormattedMessage id="sites.ip" />}
           InputLabelProps={{ shrink: true }}
          />
         )}
        />
       )}
      />
      <Stack justifyContent="center" alignContent="center" direction="column">
       <Controller
        name="lets_encrypt"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Switch
            {...field}
            checked={field.value || false}
            onChange={(e) => setValue("lets_encrypt", e.target.checked)}
           />
          }
          label={<FormattedMessage id="sites.enableEncrypt" />}
         />
        )}
       />
      </Stack>
      {watch("lets_encrypt") && (
       <Stack>
        <Alert severity="warning">
         <FormattedMessage id="sites.add.encryptMessage" />
        </Alert>
       </Stack>
      )}
     </Stack>
    );
  }
 };

 const disableNextButton = (index: number) => {
  switch (index) {
   case 1:
    return (
     watch("site").length === 0 ||
     !domainValidation.test(watch("site")) ||
     watch("site").includes("www.")
    );
   case 2:
    return watch("quota") < 1 && Number(watch("quota")) !== -1;
   case 0:
    return watch("isp_id") === 0;
   case 3:
    return watch("php_mode").length === 0 || watch("php_version").length === 0;
   case 4:
    return watch("ip").length === 0;
   default:
    return false;
  }
 };

 const checkDisableConfirm = () => {
  return (
   watch("site") === "" ||
   watch("php_mode") === "" ||
   watch("quota") === 0 ||
   watch("quota") < -1 ||
   watch("isp_id") === 0 ||
   watch("php_version") === "" ||
   watch("ip") === ""
  );
 };

 return !isLoading ? (
  <Stack spacing={2}>
   <Stack alignItems="center" mt={5} p={3}>
    <WebIcon />
    <Typography>
     <FormattedMessage id={"sites.newSite"} />
    </Typography>
   </Stack>
   <Stack direction={desktopViewPort ? "row" : "column"} justifyContent="center">
    <Box
     sx={{ maxWidth: desktopViewPort ? 1000 : "auto", minWidth: desktopViewPort ? 500 : "auto" }}
     m={10}>
     <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
       <Step key={step.label}>
        <StepLabel>
         <FormattedMessage id={step.label} />
        </StepLabel>
        {!stepLoading ? (
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id={step.description} />
            </Typography>
           </Alert>
           {renderStepContent(index)}
           {index === 1 && firstCheck && renderSiteChecked()}
          </Stack>
          <Box sx={{ mb: 2 }}>
           <div>
            {index !== steps.length - 1 ? (
             <Button
              disabled={disableNextButton(index)}
              variant="contained"
              onClick={() => (index === 1 && !nameChecked ? handleCheckSiteName() : handleNext())}
              sx={{ mt: 1, mr: 1 }}>
              {index === 1 && !nameChecked ? (
               <FormattedMessage id="app.wizard.checkValidation" />
              ) : (
               <FormattedMessage id="app.wizard.continue" />
              )}
             </Button>
            ) : (
             <></>
            )}
            {index === 4 && (
             <>
              <Button
               sx={{ mt: 1, mr: 1 }}
               variant="contained"
               disabled={checkDisableConfirm()}
               onClick={handleOpen}>
               <FormattedMessage id="sites.confirmSite" />
              </Button>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
               <FormattedMessage id="sites.siteReset" />
              </Button>
             </>
            )}
            <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
             <FormattedMessage id="sites.back" />
            </Button>
           </div>
          </Box>
         </StepContent>
        ) : (
         <StepContent>
          <Stack spacing={2}>
           <Alert severity="info">
            <Typography>
             <FormattedMessage id="server.checkExecution" />
            </Typography>
           </Alert>
           {renderStepContent(index)}
           <CircularProgress />
          </Stack>
         </StepContent>
        )}
       </Step>
      ))}
     </Stepper>
    </Box>
    <Paper
     elevation={0}
     sx={{
      m: 5,
      p: 2,
      maxHeight: 380,
      position: "sticky",
      top: 30,
      minWidth: desktopViewPort ? 400 : "auto",
      borderRadius: "10px",
      boxShadow: 0
     }}>
     <Stack mb={2}>
      <Typography variant="h5" fontWeight="bold">
       <FormattedMessage id="sites.add.recap" />
      </Typography>
     </Stack>
     <Divider textAlign="left">
      <Typography variant="overline">
       <FormattedMessage id="sites.siteData" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} textAlign="start" fontWeight="bold">
       <FormattedMessage id="sites.name" />:
      </Typography>
      <Typography noWrap>{watch("site")}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} textAlign="start" fontWeight="bold">
       <FormattedMessage id="sites.quota" />:
      </Typography>
      <Typography>{watch("quota")} MB</Typography>
     </Stack>
     <Divider textAlign="left" sx={{ mt: 2 }}>
      <Typography variant="overline">
       <FormattedMessage id="sites.sitesServer" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} textAlign="start" fontWeight="bold">
       <FormattedMessage id="sites.host" />:
      </Typography>
      <Typography noWrap>
       {ispList.find((element) => element.id === watch("isp_id"))?.hostname || ""}
      </Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} textAlign="start" fontWeight="bold">
       <FormattedMessage id="sites.ip" />:
      </Typography>
      <Typography>{watch("ip")}</Typography>
     </Stack>
     <Divider textAlign="left" sx={{ mt: 2 }}>
      <Typography variant="overline">
       <FormattedMessage id="sites.sitePhp" />
      </Typography>
     </Divider>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} textAlign="start" fontWeight="bold">
       <FormattedMessage id="sites.phpMode" />:
      </Typography>
      <Typography>{watch("php_mode")}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} textAlign="start" fontWeight="bold">
       <FormattedMessage id="sites.phpVersion" />:
      </Typography>
      <Typography>{watch("php_version")}</Typography>
     </Stack>
     <Stack direction="row" spacing={2}>
      <Typography minWidth={120} textAlign="start" fontWeight="bold">
       <FormattedMessage id="sites.encrypt" />:
      </Typography>
      <Typography>{watch("lets_encrypt") ? "Abilitato" : "Disabilitato"}</Typography>
     </Stack>
    </Paper>
   </Stack>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({ id: "sites.newSite" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <FormattedMessage id="sites.confirmSiteCreation" />
   </AppModal>
  </Stack>
 ) : (
  <></>
 );
};

export default SitesWizard;
