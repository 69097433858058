export const chatFaq = [
 {
  q: "chat.faq1Q",
  a: "chat.faq1A"
 },
 {
  q: "chat.faq2Q",
  a: "chat.faq2A"
 },
 {
  q: "chat.faq3Q",
  a: "chat.faq3A"
 },
 {
  q: "chat.faq4Q",
  a: "chat.faq4A"
 },
 {
  q: "chat.faq5Q",
  a: "chat.faq5A"
 },
 {
  q: "chat.faq6Q",
  a: "chat.faq6A"
 }
];
