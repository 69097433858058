import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { retriveStripeIva } from "helpers/generic";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import { getStripeProductList, postCreateCheckoutSession } from "redux/handlers/rechargeHandler";

import { getStripeAutopayment } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IStripeProductAPI } from "types/api/userApiInterface";

const StripeSubscription = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const confirm = useSelector(getStripeAutopayment);

 const [products, setProducts] = useState<Array<IStripeProductAPI>>([]);
 const [loading, setLoading] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   setLoading(true);
   setProducts(await dispatch(getStripeProductList()));
   setLoading(false);
  })();
 }, []);

 const handleRedirectToCheckout = async (price_id: string, amount: number) => {
  const url = await dispatch(
   postCreateCheckoutSession(
    price_id,
    "https://konsolex.onthecloud.srl/profile/recharge/autopayment",
    amount
   )
  );
  if (url !== "") window.location.href = url;
 };

 return !loading ? (
  confirm === 1 ? (
   <Stack>
    <Alert severity="warning">
     <FormattedMessage id="recharge.autopayment.stripeAlreadyActive" />
    </Alert>
   </Stack>
  ) : (
   <Grid container>
    {products.map((element, index) => {
     return (
      <Grid key={`product-index-${index}`} xs={"auto"} p={2}>
       <Card sx={{ minWidth: 300 }}>
        <CardHeader
         title={
          <Typography textTransform="uppercase" fontWeight="bold">
           {element.name}
          </Typography>
         }
         subheader={element.description}
         sx={{ textAlign: "left" }}
        />
        <Divider sx={{ borderColor: "#EF711A", mx: 1 }} />
        <Stack px={1}>
         <Typography textAlign="left" variant="caption">{`${intl.formatMessage({
          id: "recharge.autopayment.amountMessage"
         })} ${retriveStripeIva(element.price)}€ + IVA`}</Typography>
         <Typography textAlign="left" variant="caption">{`Total: ${
          element.price / 100
         }€`}</Typography>
        </Stack>
        <CardActions disableSpacing>
         <Button onClick={() => handleRedirectToCheckout(element.price_id, element.price / 100)}>
          Seleziona
         </Button>
        </CardActions>
       </Card>
      </Grid>
     );
    })}
   </Grid>
  )
 ) : (
  <CircularProgress />
 );
};

export default StripeSubscription;
