import React, { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import { isNil } from "ramda";

import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { IAllAddonsAPI, IAllAddonsDataset } from "types/api/addonsApiInterface";
import { addonsSectionType, addonsTeamsPermissionType } from "types/global/teams";
import { addonsPermissionType, addonsSelectedList } from "types/global/user";

const TeamsFirewall = ({
 control,
 addons,
 selectedAddons,
 addonsPermissions,
 setAddons,
 addAddons,
 setPermission
}: {
 control: any;
 addons: IAllAddonsAPI | null;
 selectedAddons: addonsSelectedList;
 addonsPermissions: addonsPermissionType;
 setAddons: (section: addonsSectionType, value: Array<number>) => void;
 addAddons: (section: addonsSectionType, value: Array<{ id: number; label: string }>) => void;
 setPermission: (section: addonsTeamsPermissionType, value: boolean) => void;
}): ReactElement => {
 const intl = useIntl();

 return (
  <Stack spacing={2}>
   <Controller
    name="firewalls"
    control={control}
    render={({ field }) => (
     <Autocomplete
      {...field}
      multiple
      id="tags-outlined"
      options={
       addons?.firewalls
        ? addons?.firewalls?.map((element: IAllAddonsDataset) => {
           return {
            id: element.id,
            label: element.name
           };
          })
        : []
      }
      filterSelectedOptions
      value={addons?.firewalls
       .filter((element) => selectedAddons.firewalls.includes(element.id))
       .map((element) => {
        return {
         id: element.id,
         label: element.name
        };
       })}
      getOptionLabel={(option) => option.label}
      onChange={(e, value) => {
       isNil(value)
        ? setAddons("firewalls", selectedAddons.firewalls)
        : addAddons("firewalls", value);
      }}
      renderInput={(params) => (
       <TextField
        {...params}
        label={intl.formatMessage({ id: "teams.usersettings.selectFirewalls" })}
        placeholder="Site..."
       />
      )}
     />
    )}
   />
   <Stack direction="row">
    <Stack>
     <Controller
      name="showFirewall"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showFirewall}
          onChange={(e, checked) => {
           setPermission("showFirewall", checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showFirewall" })}
       />
      )}
     />
     <Controller
      name="showFirewallDetails"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showFirewallDetails}
          onChange={(e, checked) => {
           setPermission("showFirewallDetails", checked);
           if (!checked) setPermission("editFirewallDetails", false);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showFirewallDetails" })}
       />
      )}
     />
     <Controller
      name="showFirewallServer"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showFirewallServer}
          onChange={(e, checked) => {
           setPermission("showFirewallServer", checked);
           if (!checked) setPermission("editFirewallServer", false);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showFirewallServer" })}
       />
      )}
     />
     <Controller
      name="showFirewallInboundRules"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showFirewallInboundRules}
          onChange={(e, checked) => {
           setPermission("showFirewallInboundRules", checked);
           if (!checked) setPermission("editFirewallInboundRules", false);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showFirewallInboundRules" })}
       />
      )}
     />
     <Controller
      name="showFirewallOutboundRules"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showFirewallOutboundRules}
          onChange={(e, checked) => {
           setPermission("showFirewallOutboundRules", checked);
           if (!checked) setPermission("editFirewallOutboundRules", false);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showFirewallOutboundRules" })}
       />
      )}
     />
    </Stack>
    <Stack>
     {addonsPermissions.showFirewallDetails && (
      <Controller
       name="editFirewallDetails"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         control={
          <Checkbox
           {...field}
           checked={addonsPermissions.editFirewallDetails}
           onChange={(e, checked) => {
            setPermission("editFirewallDetails", checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "teams.usersettings.editFirewallDetails" })}
        />
       )}
      />
     )}
     {addonsPermissions.showFirewallServer && (
      <Controller
       name="editFirewallServer"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         control={
          <Checkbox
           {...field}
           checked={addonsPermissions.editFirewallServer}
           onChange={(e, checked) => {
            setPermission("editFirewallServer", checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "teams.usersettings.editFirewallServer" })}
        />
       )}
      />
     )}
     {addonsPermissions.showFirewallInboundRules && (
      <Controller
       name="editFirewallInboundRules"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         control={
          <Checkbox
           {...field}
           checked={addonsPermissions.editFirewallInboundRules}
           onChange={(e, checked) => {
            setPermission("editFirewallInboundRules", checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "teams.usersettings.editFirewallInboundRules" })}
        />
       )}
      />
     )}
     {addonsPermissions.showFirewallOutboundRules && (
      <Controller
       name="editFirewallOutboundRules"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         control={
          <Checkbox
           {...field}
           checked={addonsPermissions.editFirewallOutboundRules}
           onChange={(e, checked) => {
            setPermission("editFirewallOutboundRules", checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "teams.usersettings.editFirewallOutboundRules" })}
        />
       )}
      />
     )}
    </Stack>
   </Stack>
  </Stack>
 );
};

export default TeamsFirewall;
