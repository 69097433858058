import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Alert from "@mui/material/Alert";

const CardPaymentInfo = (): ReactElement => {
 return (
  <Alert severity="info">
   <FormattedMessage id="recharge.cardPayment" />
  </Alert>
 );
};

export default CardPaymentInfo;
