import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: Array<number> = [];

const NewMessageReducer = createSlice({
 name: "newmessages",
 initialState,
 reducers: {
  setNewMessages: (state, { payload }: PayloadAction<number>) => {
   console.log(payload);
   return [...state, payload];
  },
  removeMessage: (state, { payload }: PayloadAction<number>) => {
   if (state.find((element) => element === payload)) {
    if (state.length === 1) {
     return [];
    } else {
     const index = state.indexOf(payload);
     const datas = state;
     datas.splice(index, 1);
     return datas;
    }
   } else {
    return state;
   }
  },
  resetNewMessages: () => {
   return initialState;
  }
 }
});

export default NewMessageReducer;
