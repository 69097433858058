import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { chatFaq } from "constants/chat";

const ChatFAQ = (): ReactElement => {
 return (
  <Stack
   sx={{
    mx: 4,
    my: 2,
    backgroundColor: "white",
    color: "black",
    borderRadius: 2
   }}>
   {chatFaq.map((element, index) => {
    return (
     <Accordion key={`chat-index-${index}`}>
      <AccordionSummary
       expandIcon={<ExpandMoreIcon />}
       aria-controls="panel1-content"
       id="panel1-header">
       <Typography component="span" textAlign="left">
        <FormattedMessage id={element.q} />
       </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ textAlign: "left" }}>
       <Typography component="span" textAlign="left">
        <FormattedMessage id={element.a} />
       </Typography>
      </AccordionDetails>
     </Accordion>
    );
   })}
  </Stack>
 );
};

export default ChatFAQ;
