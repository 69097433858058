import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import RetryAuthinfo from "../retryActions/RetryAuthinfo";
import RetryFiscalCode from "../retryActions/RetryFiscalCode";
import RetryNs from "../retryActions/RetryNs";

const DomainsOrderAction = ({
 error,
 id,
 action
}: {
 error: number;
 id: number;
 action: string;
}): ReactElement => {
 const RenderErrorMessage = ({ response }: { response: number }): ReactElement => {
  switch (response) {
   case 2:
    return (
     <Typography variant="subtitle2">
      <FormattedMessage id="domain.error.code02" />
     </Typography>
    );
   case 3:
    return (
     <Typography variant="subtitle2">
      <FormattedMessage id="domain.error.code03" />
     </Typography>
    );
   case 4:
    return (
     <Typography variant="subtitle2">
      <FormattedMessage id="domain.error.code04" />
     </Typography>
    );
   case 5:
    return (
     <Typography variant="subtitle2">
      <FormattedMessage id="domain.error.code05" />
     </Typography>
    );
   default:
    return <></>;
  }
 };

 const RenderModal = ({ response }: { response: number }): ReactElement => {
  switch (response) {
   case 2:
    return <RetryNs id={id} action={action} />;
   case 3:
    return <RetryAuthinfo id={id} action={action} />;
   case 4:
    return <RetryFiscalCode id={id} action={action} />;
   default:
    return <></>;
  }
 };

 return (
  <Stack>
   <RenderErrorMessage response={error} />
   <RenderModal response={error} />
  </Stack>
 );
};

export default DomainsOrderAction;
