import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";

import {
 IAdminChatList,
 IChatApi,
 IChatAssistant,
 IChatTickets,
 IMessage,
 ITicket,
 IUsersData
} from "types/api/chatApiInterface";

import { ApiService } from "service/ApiService";

export const getAllMessages =
 (user_id: string): AppAction<Promise<IChatApi>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IChatApi>(`/messages?userId=${user_id}`);
   return data;
  } catch (error) {
   console.warn("FAILED TO GET MESSAGES DATA");
   return { allMessages: [] };
  }
 };

export const getAllUserMessages =
 (user_id: string): AppAction<Promise<Array<IMessage>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IMessage>>("/getMessagesUserGptByUserId", "POST", {
    userId: user_id
   });
   return data;
  } catch (error) {
   console.warn("FAILED TO GET MESSAGES DATA");
   return [];
  }
 };

export const postSendMessage =
 (userId: string, text: string, tg_id: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/sendMessage", "POST", {
    userId: userId,
    text: text,
    tg_id: tg_id
   });
  } catch (error) {
   console.warn("FAILED TO POST MESSAGES DATA");
  }
 };

export const getTicketList = (): AppAction<Promise<IChatTickets>> => async (dispatch) => {
 try {
  const { data } = await ApiService<IChatTickets>("/admin/ticketList");
  return data;
 } catch (error) {
  console.warn("FAILED TO POST MESSAGES DATA");
  return { allTickets: [] };
 }
};

export const getAllUsers = (): AppAction<Promise<Array<IUsersData>>> => async (dispatch) => {
 try {
  const { data } = await ApiService<{ allUsers: Array<IUsersData> }>("/admin/allUsersData");
  return data.allUsers;
 } catch (error) {
  console.warn("FAILED TO POST MESSAGES DATA");
  return [];
 }
};

export const postCloseTicket =
 (user_id: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IChatTickets>("/admin/closeTicket", "POST", {
    userId: user_id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Ticket closed succesfully",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to close ticket - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postSendAdminMessage =
 (text: string, userid: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/admin/replyTicket", "POST", {
    message: text,
    userId: userid
   });
  } catch (error) {
   console.warn("FAILED TO POST MESSAGES DATA");
  }
 };

export const getAllTickets = (): AppAction<Promise<Array<Array<ITicket>>>> => async (dispatch) => {
 try {
  const { data } = await ApiService<Array<Array<ITicket>>>("/admin/getAllReplayMessagesAdminUser");
  return data;
 } catch (error) {
  console.warn("FAILED TO GET MESSAGES DATA");
  return [];
 }
};

export const getAllUserConversation =
 (): AppAction<Promise<Array<IAdminChatList>>> => async (dispatch) => {
  try {
   const { data } = await ApiService<{ allUsers: Array<IAdminChatList> }>(
    "/admin/getMessagesUserGpt"
   );
   return data.allUsers;
  } catch (error) {
   console.warn("FAILED TO GET MESSAGES DATA");
   return [];
  }
 };

export const getAssistants =
 (user_id: string): AppAction<Promise<Array<IChatAssistant>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IChatAssistant>>(
    "/getMessagesByUserIdAssistantId",
    "POST",
    {
     userId: user_id
    }
   );
   return data;
  } catch (error) {
   console.warn("FAILED TO GET MESSAGES DATA");
   return [];
  }
 };

export const getUserTickets =
 (user_id: string): AppAction<Promise<Array<Array<ITicket>>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<Array<ITicket>>>("/getTicketsMessagesUser", "POST", {
    userId: user_id
   });
   return data;
  } catch (error) {
   console.warn("FAILED TO GET MESSAGES DATA");
   return [];
  }
 };

export const postCheckOpenTicket =
 (user_id: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<{ openTicket: boolean }>("/checkUserOpenTickets", "POST", {
    userId: user_id
   });
   return data.openTicket;
  } catch (error) {
   console.warn("FAILED TO GET MESSAGES DATA");
   return false;
  }
 };
