/* eslint-disable react/style-prop-object */
import React, { ReactElement } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import {
 getAmount,
 getBank,
 getCfDocument,
 getIban,
 getIdentityDocument,
 getRidBalanceLimit,
 getRidDocument,
 getRidDocumentVerified,
 getUserId
} from "redux/selectors/userSelector";

import DisableAutoPay from "../../actions/DisableAutoPay";
import DownloadDocument from "../../actions/DownloadDocument";
import EnableAutoPay from "../../actions/EnableAutoPay";
import RemoveAllData from "../../actions/RemoveAllData";
import ShowInfoDocument from "../../actions/ShowInfoDocument";
import UploadDocument from "../../actions/UploadDocument";

type propsType = {
 confirm: boolean;
};

const SepaPaymentCard = ({ confirm }: propsType): ReactElement => {
 const iban = useSelector(getIban);
 const bank = useSelector(getBank);
 const amount = useSelector(getAmount);
 const id = useSelector(getUserId);
 const document = useSelector(getRidDocument);
 const identityDc = useSelector(getIdentityDocument);
 const cfDc = useSelector(getCfDocument);
 const documentConfirmed = useSelector(getRidDocumentVerified);
 const ridLimit = useSelector(getRidBalanceLimit);

 return (
  <Card sx={{ minWidth: 370 }}>
   <CardHeader
    avatar={
     <IconSelector
      icon={"AccountBalanceIcon"}
      props={{ sx: { fontSize: 40 }, color: "kxOrange" }}
     />
    }
    title={
     <Typography textTransform="uppercase" fontWeight="bold">
      <FormattedMessage id="profile.security.sepaDebit" />
     </Typography>
    }
    subheader={<FormattedMessage id="profile.security.sepaDebitSubHeader" />}
    sx={{ textAlign: "left" }}
   />
   <Divider sx={{ borderColor: "#EF711A", mx: 1 }} />
   <Stack spacing={1} px={2} py={1}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
     <Typography variant="caption" fontWeight="bold">
      Status
     </Typography>
     {documentConfirmed ? (
      <Tooltip title={confirm ? "Online" : "Offline"}>
       <Stack>
        <IconSelector
         icon={confirm ? "CheckCircleIcon" : "ReportIcon"}
         props={{ color: confirm ? "success" : "error" }}
        />
       </Stack>
      </Tooltip>
     ) : (
      <Tooltip title={<FormattedMessage id="profile.security.documentToVerify" />}>
       <Stack>
        <IconSelector icon="WarningIcon" props={{ color: "warning" }} />
       </Stack>
      </Tooltip>
     )}
    </Stack>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
     <Typography variant="caption" fontWeight="bold">
      <FormattedMessage id="recharge.iban" />
     </Typography>
     <Typography variant="caption">{iban}</Typography>
    </Stack>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
     <Typography variant="caption" fontWeight="bold">
      <FormattedMessage id="recharge.bank" />
     </Typography>
     <Typography variant="caption">{bank}</Typography>
    </Stack>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
     <Typography variant="caption" fontWeight="bold">
      <FormattedMessage id="recharge.balanceLimit" />
     </Typography>
     <Typography variant="caption">
      <FormattedNumber value={ridLimit || 0} style={"currency"} currency="EUR" />
     </Typography>
    </Stack>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
     <Typography variant="caption" fontWeight="bold">
      <FormattedMessage id="app.amount" />
     </Typography>
     <Typography variant="caption" color="green">
      <FormattedNumber value={amount || 0} style={"currency"} currency="EUR" />
     </Typography>
    </Stack>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
     <Typography variant="caption" fontWeight="bold">
      <FormattedMessage id="profile.security.identityDocument" />
     </Typography>
     <Typography variant="caption" color={identityDc ? "green" : "orange"}>
      {identityDc ? (
       <FormattedMessage id="profile.security.filePresent" />
      ) : (
       <FormattedMessage id="profile.security.fileNotPresent" />
      )}
     </Typography>
    </Stack>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
     <Typography variant="caption" fontWeight="bold">
      <FormattedMessage id="profile.security.cfDocument" />
     </Typography>
     <Typography variant="caption" color={cfDc ? "green" : "orange"}>
      {cfDc ? (
       <FormattedMessage id="profile.security.filePresent" />
      ) : (
       <FormattedMessage id="profile.security.fileNotPresent" />
      )}
     </Typography>
    </Stack>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
     <Typography variant="caption" fontWeight="bold">
      <FormattedMessage id="profile.security.sepaDocument" />
     </Typography>
     <Typography variant="caption" color={document ? "green" : "orange"}>
      {document ? (
       <FormattedMessage id="profile.security.filePresent" />
      ) : (
       <FormattedMessage id="profile.security.fileNotPresent" />
      )}
     </Typography>
    </Stack>
   </Stack>
   {confirm ? (
    <CardActions disableSpacing sx={{ justifyContent: "flex-end" }}>
     {documentConfirmed ? (
      <DisableAutoPay id={id} />
     ) : (
      <Stack direction="row">
       <ShowInfoDocument />
       <UploadDocument id={id} />
       <DownloadDocument />
      </Stack>
     )}
    </CardActions>
   ) : (
    <CardActions disableSpacing sx={{ justifyContent: "flex-end" }}>
     <EnableAutoPay id={id} />
     <RemoveAllData id={id} />
    </CardActions>
   )}
  </Card>
 );
};

export default SepaPaymentCard;
