import { PaymentIntent } from "@stripe/stripe-js";
import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";

import { IStripeProductAPI } from "types/api/userApiInterface";

import { ApiService } from "service/ApiService";

export const postPayWithCard = (amount: number) => {
 console.log(amount);
};

export const postPayWithTransfer =
 (amount: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/order/createorder", "POST", {
    amount: amount
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Bank transfer order created",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to create bank transfer order - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postSetupStripePayment =
 (amount: number): AppAction<Promise<string>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<PaymentIntent>("/stripe/create-payment-intent", "POST", {
    amount: amount
   });
   return data.client_secret || "";
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to setup Stripe payment - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return "";
  }
 };

export const postCompleteStripePayment =
 (amount: number, request_amount: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/stripe/completepayment", "POST", {
    amount: amount,
    request_amount: request_amount
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Payment order created",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to setup Stripe payment - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const getStripeProductList =
 (): AppAction<Promise<Array<IStripeProductAPI>>> => async (dispatch) => {
  try {
   const { data } = await ApiService<{ dataset: Array<IStripeProductAPI>; totalCount: number }>(
    "/stripe/getproductslist"
   );
   return data.dataset.sort((a, b) => a.price - b.price);
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get products - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postCreateCheckoutSession =
 (price_id: string, domain: string, amount: number): AppAction<Promise<string>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<{ url: string }>("/stripe/create-checkout-session", "POST", {
    price_id,
    domain,
    amount
   });
   if (data) {
    return data.url;
   } else {
    return "";
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to redirect to checkout - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return "";
  }
 };

export const postCreatePortalSession =
 (session_id: string, domain: string): AppAction<Promise<string>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/stripe/create-portal-session", "POST", {
    session_id,
    domain
   });
   if (data) {
    return data.url;
   } else {
    return "";
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to redirect to portal - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return "";
  }
 };
