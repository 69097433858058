import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generateUniqueId } from "helpers/generic";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { getExportStatements } from "redux/handlers/userHandler";

import notificationReducer from "redux/reducers/notificationReducer";

import { useAppDispatch } from "hooks/reduxHook";

const ExportStatements = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  const data = await dispatch(getExportStatements());
  if (data) {
   // Create a URL for the Blob
   const url = URL.createObjectURL(data);

   // Create an anchor tag for downloading
   const a = document.createElement("a");

   // Set the URL and download attribute of the anchor tag
   a.href = url;
   a.download = "orders.zip";

   // Trigger the download by clicking the anchor tag
   a.click();
  } else {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "No new statements to export found.",
     timestamp: Date.now(),
     type: "success"
    })
   );
  }
  setLoading(false);
  handleClose();
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="profile.orders.exportStatements" />}>
    <IconButton onClick={handleOpen}>
     <IconSelector icon="AssignmentReturnedIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={open}
    close={handleClose}
    loading={loading}
    title={intl.formatMessage({ id: "profile.orders.exportStatements" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="profile.orders.exportStatementsMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default ExportStatements;
