import React, { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import { isNil } from "ramda";

import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { IAllAddonsAPI, IAllAddonsDataset } from "types/api/addonsApiInterface";
import { addonsSectionType, addonsTeamsPermissionType } from "types/global/teams";
import { addonsPermissionType, addonsSelectedList } from "types/global/user";

const TeamsNetwork = ({
 control,
 addons,
 selectedAddons,
 addonsPermissions,
 setAddons,
 addAddons,
 setPermission
}: {
 control: any;
 addons: IAllAddonsAPI | null;
 selectedAddons: addonsSelectedList;
 addonsPermissions: addonsPermissionType;
 setAddons: (section: addonsSectionType, value: Array<number>) => void;
 addAddons: (section: addonsSectionType, value: Array<{ id: number; label: string }>) => void;
 setPermission: (section: addonsTeamsPermissionType, value: boolean) => void;
}): ReactElement => {
 const intl = useIntl();

 return (
  <Stack spacing={2}>
   <Controller
    name="networks"
    control={control}
    render={({ field }) => (
     <Autocomplete
      {...field}
      multiple
      id="tags-outlined"
      options={
       addons?.networks
        ? addons?.networks?.map((element: IAllAddonsDataset) => {
           return {
            id: element.id,
            label: element.name
           };
          })
        : []
      }
      filterSelectedOptions
      value={addons?.networks
       .filter((element) => selectedAddons.networks.includes(element.id))
       .map((element) => {
        return {
         id: element.id,
         label: element.name
        };
       })}
      getOptionLabel={(option) => option.label}
      onChange={(e, value) => {
       isNil(value) ? setAddons("networks", selectedAddons.networks) : addAddons("networks", value);
      }}
      renderInput={(params) => (
       <TextField
        {...params}
        label={intl.formatMessage({ id: "teams.usersettings.selectNetworks" })}
        placeholder="Network..."
       />
      )}
     />
    )}
   />
   <Stack direction="row">
    <Stack>
     <Controller
      name="showNetwork"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showNetwork}
          onChange={(e, checked) => {
           setPermission("showNetwork", checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showNetwork" })}
       />
      )}
     />
     <Controller
      name="showNetworkDetails"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showNetworkDetails}
          onChange={(e, checked) => {
           setPermission("showNetworkDetails", checked);
           if (!checked) setPermission("editNetworkDetails", false);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showNetworkDetails" })}
       />
      )}
     />
     <Controller
      name="showNetworkSubnet"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showNetworkSubnet}
          onChange={(e, checked) => {
           setPermission("showNetworkSubnet", checked);
           if (!checked) setPermission("editNetworkSubnet", false);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showNetworkSubnet" })}
       />
      )}
     />
     <Controller
      name="showNetworkRoute"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showNetworkRoute}
          onChange={(e, checked) => {
           setPermission("showNetworkRoute", checked);
           if (!checked) setPermission("editNetworkRoute", false);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showNetworkRoute" })}
       />
      )}
     />
    </Stack>
    <Stack>
     {addonsPermissions.showNetworkDetails && (
      <Controller
       name="editNetworkDetails"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         control={
          <Checkbox
           {...field}
           checked={addonsPermissions.editNetworkDetails}
           onChange={(e, checked) => {
            setPermission("editNetworkDetails", checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "teams.usersettings.editNetworkDetails" })}
        />
       )}
      />
     )}
     {addonsPermissions.showNetworkSubnet && (
      <Controller
       name="editNetworkSubnet"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         control={
          <Checkbox
           {...field}
           checked={addonsPermissions.editNetworkSubnet}
           onChange={(e, checked) => {
            setPermission("editNetworkSubnet", checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "teams.usersettings.editNetworkSubnet" })}
        />
       )}
      />
     )}
     {addonsPermissions.showNetworkRoute && (
      <Controller
       name="editNetworkRoute"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         control={
          <Checkbox
           {...field}
           checked={addonsPermissions.editNetworkRoute}
           onChange={(e, checked) => {
            setPermission("editNetworkRoute", checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "teams.usersettings.editNetworkRoute" })}
        />
       )}
      />
     )}
    </Stack>
   </Stack>
  </Stack>
 );
};

export default TeamsNetwork;
