import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

const ShowInfoDocument = (): ReactElement => {
 const intl = useIntl();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const [openModal, setOpenModal] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 return (
  <>
   <Tooltip title={<FormattedMessage id="profile.security.automaticPaymentInfo" />}>
    <IconButton onClick={handleOpen}>
     <IconSelector icon="InfoIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    handleConfirm={handleClose}
    showConfirmButton={false}
    title={intl.formatMessage({ id: "profile.security.automaticPaymentInfo" })}>
    <Stack>
     <object
      height={desktopViewPort ? 600 : "100%"}
      width={desktopViewPort ? 850 : "100%"}
      data={`${process.env.PUBLIC_URL}/documents/SDD_informazioni.pdf`}
      type="application/pdf">
      <div>No PDF viewer available</div>
     </object>
    </Stack>
   </AppModal>
  </>
 );
};

export default ShowInfoDocument;
