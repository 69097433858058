import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDebounce } from "react-use";

import SearchIcon from "@mui/icons-material/Search";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getAllRidUsers } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IRidUserDataset } from "types/api/userApiInterface";

import AutopaymentMenu from "./AutopaymentMenu";

const AutoPayment = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("lg"));

 const searchRef = useRef<HTMLInputElement>(null);

 const [loading, setLoading] = useState<boolean>(false);
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [searchValue, setSearchValue] = useState<string>("");
 const [usersRid, setUsersRid] = useState<Array<IRidUserDataset>>([]);
 const [usersRidNumber, setUsersRidNumber] = useState<number>(0);

 useEffect(() => {
  (async () => {
   setLoading(true);
   const users = await dispatch(getAllRidUsers(0, 10));
   setUsersRid(users.dataset);
   setUsersRidNumber(users.totalCount);
   setLoading(false);
  })();
 }, []);

 useDebounce(
  async () => {
   if (!loading) {
    setCurrentPage(0);
    const users = await dispatch(
     getAllRidUsers(currentPage, rowPerPage, searchRef?.current?.value)
    );
    setUsersRid(users.dataset);
    setUsersRidNumber(users.totalCount);
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  const users = await dispatch(getAllRidUsers(0, newRowsPerPage, searchRef?.current?.value));
  setUsersRid(users.dataset);
  setUsersRidNumber(users.totalCount);
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  const users = await dispatch(getAllRidUsers(startIndex, rowPerPage, searchRef?.current?.value));
  setUsersRid(users.dataset);
  setUsersRidNumber(users.totalCount);
 };

 const reloadData = async () => {
  const users = await dispatch(getAllRidUsers(currentPage, rowPerPage, searchRef?.current?.value));
  setUsersRid(users.dataset);
  setUsersRidNumber(users.totalCount);
 };

 const RenderRidStatus = ({
  document,
  verified,
  online
 }: {
  document: string | null;
  verified: boolean;
  online: boolean;
 }): ReactElement => {
  if (verified && online) {
   return (
    <Chip
     size="small"
     color="success"
     label={intl.formatMessage({ id: "configurator.autopayment.online" })}
    />
   );
  } else if (verified && !online) {
   return (
    <Chip
     size="small"
     color="error"
     label={intl.formatMessage({ id: "configurator.autopayment.offline" })}
    />
   );
  } else if (document) {
   return (
    <Chip
     size="small"
     color="warning"
     label={intl.formatMessage({ id: "configurator.autopayment.verificationPending" })}
    />
   );
  } else {
   return (
    <Chip
     size="small"
     color="warning"
     label={intl.formatMessage({ id: "configurator.autopayment.waitingDocument" })}
    />
   );
  }
 };

 return !loading ? (
  <Stack spacing={2}>
   <Stack justifyContent="space-between" direction="row">
    <Stack direction={desktopViewPort ? "row" : "column"} spacing={2} alignItems="center">
     <Typography>
      {usersRidNumber} <FormattedMessage id="configurator.autopayment.total" />
     </Typography>
     <TextField
      size="small"
      autoComplete="new-password"
      label={<FormattedMessage id="configurator.search.userRid" />}
      inputRef={searchRef}
      sx={{ ml: 4 }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" />, autoComplete: "off" }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
    </Stack>
   </Stack>
   <Stack>
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow>
        <TableCell>
         <FormattedMessage id="configurator.autopayment.status" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="configurator.autopayment.name" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="configurator.autopayment.bankData" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="configurator.autopayment.amount" />
        </TableCell>
        <TableCell align="center">
         <FormattedMessage id="configurator.autopayment.actions" />
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {usersRid?.map((row, index) => (
        <TableRow key={`isp-config-list-${index}`}>
         <TableCell component="th" scope="row">
          <Typography fontWeight="bold">
           <RenderRidStatus
            online={row.rid_confirmed}
            verified={row.rid_document_verified}
            document={row.rid_document}
           />
          </Typography>
         </TableCell>
         <TableCell component="th" scope="row">
          <Stack>
           <Typography variant="caption">{row.companyName}</Typography>
           <Typography variant="caption">{`${row.lastname} ${row.firstname}`}</Typography>
          </Stack>
         </TableCell>
         <TableCell component="th" scope="row">
          <Typography variant="caption">{row.rid_bank}</Typography>
         </TableCell>
         <TableCell component="th" scope="row">
          <Stack>
           <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption" fontWeight="bold">
             Credito
            </Typography>
            <Typography variant="caption">{`${row.rid_amount}€`}</Typography>
           </Stack>
           <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption" fontWeight="bold">
             Totale + IVA
            </Typography>
            <Typography variant="caption">
             {`${row.rid_amount + (row.rid_amount * row.tax) / 100}€`}
            </Typography>
           </Stack>
          </Stack>
         </TableCell>
         <TableCell component="th" scope="row" align="center">
          <Typography variant="subtitle2" fontWeight="bold">
           <AutopaymentMenu
            userid={row.userid}
            cfDocument={row.rid_fc_card}
            identityDocument={row.rid_identity_card}
            ridDocument={row.rid_document}
            documentConfirmed={row.rid_document_verified}
            reloadData={reloadData}
           />
          </Typography>
         </TableCell>
        </TableRow>
       ))}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     component="div"
     count={usersRidNumber || 0}
     page={currentPage}
     rowsPerPage={rowPerPage}
     onPageChange={handleOnPageChange}
     onRowsPerPageChange={handleOnRowsPerPageChange}
    />
   </Stack>
  </Stack>
 ) : (
  <></>
 );
};

export default AutoPayment;
