export const axiosUrl = [
 {
  urls: [
   "/auth/login",
   "/auth/register",
   "/user/forgetpwd",
   "/user/resetpwd",
   "/user/changepassword",
   "/users/me",
   "/contact/addcontact",
   "/contact/deletecontact",
   "/contact/updatecontact",
   "/teams/addteams",
   "/teams/updateteams",
   "/teams/deleteteams",
   "/host/addhost",
   "/host/updatehost",
   "/host/deletehost",
   "/contact/allcontacts",
   "host/allhosts",
   "/teams/allteams",
   "/teams/updatepassword",
   "/services/allservices",
   "/contact/contactslist",
   "/host/hostslist",
   "services/updateautorenew",
   "/movement/allmovements",
   "/user/list",
   "/user/adminauth",
   "/user/restore",
   "/user/editprofiledata",
   "/services/updateexpiration",
   "/order/createorder",
   "/order/allorders",
   "/order/completemoneytransfer",
   "/movement/addmovement",
   "/user/generatepwd",
   "/services/renewfloatingip",
   "/services/manualserverrenew",
   "/services/renewdomain",
   "/services/renewsnapshot",
   "/services/renewvolume",
   "/services/renewcloudbox",
   "/services/renewbarmetal",
   "/stripe/create-payment-intent",
   "/stripe/completepayment",
   "/notific/getnotifics",
   "/notific/deletenotific",
   "/notific/markasread",
   "/notific/markallasread",
   "/services/gettypes",
   "/user/gettype",
   "/usernotification/getbytypeforrenews",
   "/usernotification/preferences",
   "/usernotification/update",
   "/services/renewnetwork",
   "/verify",
   "/services/renewfirewall",
   "/notific/deleteallnotifics",
   "/services/updateservice",
   "/services/renewextraservice",
   "/movement/exportmovements",
   "/services/addservicemanually",
   "/user/changelang",
   "/services/renewloadbalancer",
   "/contact/getusercontacts",
   "/teams/getsitepreferences",
   "/teams/setsitepreferences",
   "/teams/getdatas",
   "/teams/setpreferences",
   "/movement/monthlyexpenses",
   "/contact/getuserscontactslist",
   "/user/changeactive",
   "/user/updatedatas",
   "/services/changebusinesscontinuity",
   "/order/exportorders",
   "/services/addonsinfo",
   "/auth/enable-2fa",
   "/auth/verify-2fa",
   "/auth/disable-2fa",
   "/auth/recovery",
   "/order/exportordersstatements",
   "/services/deleteserviceadmin",
   "/teams/teamslist",
   "/user/getridusers",
   "/user/setriddocumentverified",
   "/stripe/getproductslist",
   "/stripe/create-checkout-session",
   "/stripe/create-portal-session",
   "/stripe/deletesubscription"
  ],
  apiUrl: "https://konsolex-auth.onthecloud.srl/api"
 },
 {
  urls: ["/socket/initiallogs"],
  apiUrl: "https://konsolex-websocket.onthecloud.srl/api"
 },
 {
  urls: [
   "/docker/image/getimages",
   "/docker/container/getcontainers",
   "/docker/network/getnetworks",
   "/docker/volume/getvolumes",
   "/docker/stack/getstacks",
   "/docker/service/getservices",
   "/docker/container/installproxy",
   "/docker/container/disableproxy",
   "/docker/container/installcontainer",
   "/docker/container/redirectproxytocontainer",
   "/docker/container/disinstallcontainer",
   "/docker/site/createsite",
   "/docker/site/applyssl",
   "/docker/site/deletesite",
   "/docker/order/getorders",
   "/docker/site/getsites",
   "/docker/container/checkproxy",
   "/docker/php/getversions",
   "/docker/repo/getrepo",
   "/docker/port/getports",
   "/docker/site/getbyserverid",
   "/docker/container/getbyserverid",
   "/docker/site/installfullsite",
   "/docker/site/deletewithcontainer",
   "/docker/site/activefilemanager",
   "/docker/site/deactivatefilemanager",
   "/docker/site/db/list",
   "/docker/site/installdb",
   "/docker/site/db/installphpmyadmin",
   "/docker/site/db/disinstallphpmyadmin",
   "/docker/site/disinstalldb",
   "/docker/site/cronjob/add",
   "/docker/site/cronjob/list",
   "/docker/site/cronjob/delete",
   "/docker/site/ftp/deactivate",
   "/docker/site/ftp/create",
   "/docker/site/ftp/list",
   "/docker/site/ftp/delete",
   "/docker/container/lifecycle",
   "/docker/container/tuning",
   "/docker/site/installmodule",
   "/docker/site/db/gettypes",
   "/docker/site/db/remote",
   "/docker/site/sshscript",
   "/docker/site/db/repairtables",
   "/docker/site/resetlitespeedpwd",
   "/docker/site/mountbucket",
   "/docker/site/unmountbucket",
   "/docker/site/resetprivileges",
   "/docker/dbversions/getversions",
   "/docker/site/db/installadminer",
   "/docker/site/db/disinstalladminer"
  ],
  apiUrl: "https://konsolex-container.onthecloud.srl/api"
 },
 {
  urls: [
   "/sendMessage",
   "/messages",
   "/admin/ticketList",
   "/admin/closeTicket",
   "/admin/replyTicket",
   "/admin/allUsersData",
   "/getMessagesByUserIdAssistantId",
   "/admin/getAllReplayMessagesAdminUser",
   "/getTicketsMessagesUser",
   "/admin/getMessagesUserGpt",
   "/getMessagesUserGptByUserId",
   "/checkUserOpenTickets"
  ],
  apiUrl: "https://konsolex-ai.onthecloud.srl/api"
 },
 {
  urls: [
   "/getGatewayList",
   "/addGateway",
   "/deleteGateway",
   "/modifyGateway",
   "/getDomainsList",
   "/getNetworksList",
   "/getTransportsList",
   "/getBlacklistList",
   "/getWhitelistList",
   "/addDomains",
   "/deleteDomains",
   "/addNetworks",
   "/modifyNetworks",
   "/deleteNetworks",
   "/addTransports",
   "/modifyTransports",
   "/deleteTransports",
   "/addWhitelist",
   "/modifyWhitelist",
   "/deleteWhitelist",
   "/addBlacklist",
   "/modifyBlacklist",
   "/deleteBlacklist",
   "/getSpamList",
   "/actionToSpam",
   "/getVirusList",
   "/tracking",
   "/actionToVirus",
   "/getorders",
   "/trackingLogs"
  ],
  apiUrl: "https://konsolex-gateway.onthecloud.srl/api"
 },
 {
  urls: ["/agent/createtask", "/agent/getAgentVersion"],
  apiUrl: "https://konsolex-logs.onthecloud.srl/api"
 },
 {
  urls: [
   "/domain/checkavailability",
   "/domain/getdomainprice",
   "/register/getregisters",
   "/domain/adddomain",
   "/domainorder/getdomainorders",
   "/domain/alldomains",
   "/domain/updatedomain",
   "/domain/manualdomainrenew",
   "/domain/changens",
   "/domain/changeowners",
   "/domainorder/retry",
   "/domain/changelock",
   "/domain/restoreprice",
   "/domain/restore",
   "/domain/ibsemailverification",
   "/domain/updateauthinfo",
   "/domain/deletetransfer",
   "/domain/list"
  ],
  apiUrl: "https://konsolex-domains.onthecloud.srl/api"
 },
 {
  urls: [
   "/hetznerproject/allprojects",
   "/hetznerproject/addproject",
   "/hetznerproject/updateproject",
   "/hetznerproject/deleteproject",
   "/server/getservers",
   "/server/getserver/",
   "/server/changeservername",
   "/server/rebuildserver",
   "/server/resetserver",
   "/server/poweron",
   "/server/resetrootpassword",
   "/server/changelocked",
   "/server/changednsptr",
   "/image/allimages",
   "/location/alllocations",
   "/servertype/allservertypes",
   "/server/addserver",
   "/provider/allproviders",
   "/server/checkname",
   "/backup/allbackup",
   "/backup/serverbackup",
   "/backup/addbackup",
   "/backup/deletebackup",
   "/backup/converttosnapshot",
   "/snapshot/allsnapshot",
   "/snapshot/addsnapshot",
   "/snapshot/deletesnapshot",
   "/snapshot/changeprotection",
   "/server/snapshotprice",
   "/snapshot/changedescription",
   "/snapshot/addsnapshot",
   "/snapshot/serversnapshot",
   "/snapshot/datafromserver",
   "/snapshot/rebuildserver",
   "/server/disablebackup",
   "/server/enablebackup",
   "/backup/rebuildserver",
   "/backup/datafromserver",
   "/server/addserverfrombackup",
   "/hetznerorder/allorders",
   "/hetznerorder/allserverorders",
   "/hetznerorder/finalizecreation",
   "/server/changerescale",
   "/server/serverupgradedata",
   "/server/upgradeserver",
   "/server/poweroff",
   "/server/restartservice",
   "/server/repairsqltable",
   "/server/requestconsole",
   "/server/rootlogin",
   "/iso/fetchiso",
   "/iso/attachiso",
   "/iso/detachiso",
   "/volume/getvolumes",
   "/volume/checkvolumename",
   "/volume/updatevolume",
   "/volume/deletevolume",
   "/volume/changeprotection",
   "/volume/resize",
   "/volume/detachvolume",
   "/volume/attachvolume",
   "/volume/calculateprice",
   "/volume/addvolume",
   "/server/restartagent",
   "/configurephp/getdata",
   "/configurephp/setdata",
   "/floatingip/getfloatingips",
   "/floatingip/changeprotection",
   "/floatingip/update",
   "/floatingip/delete",
   "/floatingip/assign",
   "/floatingip/unassign",
   "/floatingip/chandednsptr",
   "/floatingip/price",
   "/floatingip/create",
   "/cloudbox/getcloudboxes",
   "/cloudbox/assignuser",
   "/cloudbox/updatecloudbox",
   "/cloudbox/updatepassword",
   "/snapshotcloudbox/delete",
   "/snapshotcloudbox/create",
   "/cloudbox/completecreation",
   "/snapshotcloudbox/updateplan",
   "/cloudbox/getprices",
   "/cloudbox/create",
   "/cloudbox/rebuild",
   "/cloudbox/upgrade",
   "/cloudbox/completeupgrade",
   "/barmetal/getbarmetals",
   "/barmetal/updatename",
   "/barmetal/completecreation",
   "/barmetal/create",
   "/barmetal/reboot",
   "/barmetal/assign",
   "/network/getnetworks",
   "/network/delete",
   "/network/update",
   "/network/create",
   "/network/checkname",
   "/network/changeprotection",
   "/network/addsubnet",
   "/network/deletesubnet",
   "/server/attachtonetwork",
   "/server/detachfromnetwork",
   "/network/checkiprange",
   "/network/price",
   "/network/addroute",
   "/network/deleteroute",
   "/firewall/getfirewalls",
   "/firewall/price",
   "/firewall/delete",
   "/firewall/create",
   "/firewall/applytoserver",
   "/firewall/update",
   "/firewall/removefromserver",
   "/firewall/checkname",
   "/firewall/addrule",
   "/firewall/updaterule",
   "/firewall/removerule",
   "/server/upgradeagent",
   "/hetznerorder/deleteorder",
   "/server/addons",
   "/server/settoken",
   "/hetznerorder/retry",
   "/server/changestatusipv6",
   "/loadbalancer/getloadbalancers",
   "/loadbalancer/deleteloadbalancer",
   "/loadbalancertype/gettypes",
   "/loadbalancer/createloadbalancer",
   "/loadbalancer/checkname",
   "/loadbalancer/updateloadbalancer",
   "/loadbalancer/changedeleteprotection",
   "/loadbalancer/changealgorithm",
   "/loadbalancer",
   "/certificate/getcertificates",
   "/certificate/updatecertificate",
   "/certificate/deletecertificate",
   "/certificate/createcertificate",
   "/loadbalancer/detachfromnetwork",
   "/loadbalancer/changednsptr",
   "/loadbalancer/enablepublicinterface",
   "/loadbalancer/disablepublicinterface",
   "/loadbalancer/getmetrics",
   "/loadbalancer/addservice",
   "/loadbalancer/deleteloadbalancerservice",
   "/loadbalancer/addtarget",
   "/loadbalancer/removetarget",
   "/network/getusernetworks",
   "/server/addonslist",
   "/loadbalancer/attachtonetwork",
   "/loadbalancer/list",
   "/loadbalancer/detachfromnetwork",
   "/server/installagent",
   "/server/countserversforlocations",
   "/server/getperformances",
   "/server/installcloudbox",
   "/server/removecloudbox",
   "/cloudbox/getdirectories",
   "/volume/mount",
   "/volume/unmount",
   "/server/getproviderssetup",
   "/server/resetpassword",
   "/server/contabo/serverupgradedata",
   "/server/contabo/upgraderequest",
   "/server/contabo/completeupgrade",
   "/bucket/getbuckets",
   "/bucket/calculateamount",
   "/bucket/checkname",
   "/bucket/createbucket",
   "/bucket/completecreation",
   "/bucket/updates3",
   "/server/installbucket",
   "/server/completebucketinstall",
   "/server/getinstalledbuckets",
   "/server/diconnectbucket",
   "/bucket/getamounthetzner"
  ],
  apiUrl: "https://konsolex-hetzner.onthecloud.srl/api"
 },
 {
  urls: [
   "/maildomain/getmaildomain",
   "/maildomain/getmailpolicy",
   "/maildomain/deletemaildomain",
   "/maildomain/updatemaildomain",
   "/maildomain/addmaildomain",
   "/mailbox/addmailbox",
   "/mailbox/deletemailbox",
   "/mailbox/updatemailbox",
   "/maildomain/setstatus",
   "/site/deletesite",
   "/site/setsitestatus",
   "/site/addnewsite",
   "/ispconfig",
   "/dns/getdnsrr",
   "/dns/getzones",
   "/dns/deletezone",
   "/dns/addzone",
   "/dns/updatednsrr",
   "/dns/deletednsrr",
   "/dns/adddnsrr",
   "/dns/setzonestatus",
   "/site/getallsites",
   "/site/getsitedetails",
   "/site/updatesite",
   "/sitesftp/addnewftp",
   "/sitesftp/updateftp",
   "/sitesftp/deleteftp",
   "/alias/addalias",
   "/alias/updatealias",
   "/alias/deletealias",
   "/sitesdb/adddb",
   "/sitesdb/updatedbuser",
   "/sitesdb/updatedb",
   "/sitesdb/deletedb",
   "/isporder/allisporders",
   "/dns/getzonebyzoneid",
   "/ispconfig/modify",
   "/sitebackup/restore",
   "/sitescron/deletecron",
   "/sitescron/updatecron",
   "/sitescron/addsitecron",
   "/sitesdb/updatedbuser",
   "/ispconfig/list",
   "/site/checkname",
   "/mailbox/getautoresponder",
   "/mailbox/updateautoresponder",
   "/dns/list",
   "/maildomain/list",
   "/site/list",
   "/sitesftp/updatedir",
   "/maildomain/blackandwhitelist/add",
   "/maildomain/blackandwhitelist/list",
   "/maildomain/blackandwhitelist/delete",
   "/maildomain/blackandwhitelist/update",
   "/mailbox/getmailsforwardandalias",
   "/mailbox/addmailforwardoralias",
   "/mailbox/deletemailforwardoralias",
   "/mailbox/getbyispid",
   "/mailbox/updatemailforwardoralias",
   "/mailbox/getbackups",
   "/mailbox/updatebackup",
   "/mailbox/restorebackup",
   "/app/getstats",
   "/site/createsslcertificate",
   "/site/updatesslcertificate"
  ],
  apiUrl: "https://konsolex-ispconfig.onthecloud.srl/api"
 }
];
