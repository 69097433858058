import React, { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { sepaTexts } from "constants/recharge";

import IconSelector from "components/shared/images/IconSelector";

const RidTransferInfo = ({
 iban,
 bank,
 ridConfirmed,
 balance,
 setData
}: {
 iban: string;
 bank: string;
 ridConfirmed: boolean;
 balance: number;
 setData: (
  type: "iban" | "bank" | "confirmed" | "balance",
  value: string | boolean | number
 ) => void;
}): ReactElement => {
 const intl = useIntl();

 return (
  <>
   <Alert severity="info">
    <Stack sx={{ textAlign: "left" }}>
     <Typography mb={2} variant="h6">
      <FormattedMessage id="recharge.sepaActivation" />
     </Typography>
     <Typography mb={2}>
      <FormattedMessage id="recharge.sepaActivationExplain" />
     </Typography>
    </Stack>
    <Stack sx={{ textAlign: "left" }}>
     <Typography fontWeight="bold">
      <FormattedMessage id="recharge.sepaTransfer1" />
     </Typography>
     <ul>
      {sepaTexts.firstSection.map((element, index) => {
       return (
        <li key={`sepa-firstlist-${index}`}>
         <Typography>
          <FormattedMessage id={element} />
         </Typography>
        </li>
       );
      })}
     </ul>
    </Stack>
    <Stack sx={{ textAlign: "left" }}>
     <Typography fontWeight="bold">
      <FormattedMessage id="recharge.sepaTransfer2" />
     </Typography>
     <ul>
      {sepaTexts.secondSection.map((element, index) => {
       return (
        <li key={`sepa-firstlist-${index}`}>
         <Typography>
          <FormattedMessage id={element} />
         </Typography>
        </li>
       );
      })}
     </ul>
    </Stack>
    <Typography sx={{ textAlign: "left" }}>
     <FormattedMessage id="recharge.sepaLastDescription" />
    </Typography>
   </Alert>
   <Stack spacing={2}>
    <TextField
     autoComplete="off"
     label={intl.formatMessage({ id: "recharge.iban" })}
     InputLabelProps={{ shrink: true }}
     InputProps={{
      startAdornment: (
       <IconSelector icon="AssuredWorkloadIcon" props={{ color: "disabled", sx: { mr: 1 } }} />
      )
     }}
     value={iban}
     onChange={({ currentTarget }) => setData("iban", currentTarget.value)}
    />
    <TextField
     autoComplete="off"
     label={intl.formatMessage({ id: "recharge.bank" })}
     InputLabelProps={{ shrink: true }}
     InputProps={{
      startAdornment: (
       <IconSelector icon="AccountBalanceIcon" props={{ color: "disabled", sx: { mr: 1 } }} />
      )
     }}
     value={bank}
     onChange={({ currentTarget }) => setData("bank", currentTarget.value)}
    />
    <TextField
     autoComplete="off"
     label={intl.formatMessage({ id: "recharge.balanceLimit" })}
     InputLabelProps={{ shrink: true }}
     helperText={intl.formatMessage({ id: "recharge.limitHelper" })}
     value={balance}
     type="number"
     onChange={({ currentTarget }) => setData("balance", currentTarget.value)}
    />
    <FormControlLabel
     control={
      <Checkbox
       checked={ridConfirmed}
       onChange={(e) => {
        setData("confirmed", e.target.checked);
       }}
      />
     }
     label={intl.formatMessage({ id: "recharge.confirmRidActivation" })}
    />
   </Stack>
  </>
 );
};

export default RidTransferInfo;
