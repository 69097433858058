/* eslint-disable react/style-prop-object */
import React, { ReactElement } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import {
 getStripeAutopayment,
 getStripeRechargeAmount,
 getStripeRechargeDate
} from "redux/selectors/userSelector";

import DisableStripeAutopay from "../../actions/DisableStripeAutopay";

const StripePaymentCard = (): ReactElement => {
 const confirm = useSelector(getStripeAutopayment);
 const payDate = useSelector(getStripeRechargeDate);
 const amount = useSelector(getStripeRechargeAmount);

 return (
  <Card sx={{ minWidth: 370 }}>
   <CardHeader
    avatar={
     <IconSelector icon={"CreditCardIcon"} props={{ sx: { fontSize: 40 }, color: "kxOrange" }} />
    }
    title={
     <Typography textTransform="uppercase" fontWeight="bold">
      <FormattedMessage id="profile.security.stripeDebit" />
     </Typography>
    }
    subheader={<FormattedMessage id="profile.security.stripeDebitSubHeader" />}
    sx={{ textAlign: "left" }}
   />
   <Divider sx={{ borderColor: "#EF711A", mx: 1 }} />
   <Stack spacing={1} px={2} py={1}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
     <Typography variant="caption" fontWeight="bold">
      Status
     </Typography>
     <Tooltip title={confirm === 1 ? "Online" : "Offline"}>
      <Stack>
       <IconSelector
        icon={confirm === 1 ? "CheckCircleIcon" : "ReportIcon"}
        props={{ color: confirm === 1 ? "success" : "error" }}
       />
      </Stack>
     </Tooltip>
    </Stack>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
     <Typography variant="caption" fontWeight="bold">
      <FormattedMessage id="app.nextDate" />
     </Typography>
     <Typography variant="caption">{payDate}</Typography>
    </Stack>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
     <Typography variant="caption" fontWeight="bold">
      <FormattedMessage id="app.amount" />
     </Typography>
     <Typography variant="caption" color="green">
      <FormattedNumber value={amount || 0} style={"currency"} currency="EUR" />
     </Typography>
    </Stack>
   </Stack>
   {confirm === 1 ? (
    <CardActions disableSpacing sx={{ justifyContent: "flex-end" }}>
     <DisableStripeAutopay />
    </CardActions>
   ) : (
    <CardActions disableSpacing sx={{ justifyContent: "flex-end" }}></CardActions>
   )}
  </Card>
 );
};

export default StripePaymentCard;
