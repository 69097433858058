import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";

import { websiteContainerDatabaseSteps } from "constants/server";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import {
 getDockerDbTypes,
 getDockerDbVersions,
 postInstallDatabase
} from "redux/handlers/dockerHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { dbData } from "types/global/container";

import DbName from "./databaseStepComponents/DbName";
import DbPassword from "./databaseStepComponents/DbPassword";
import DbRecap from "./databaseStepComponents/DbRecap";
import DbType from "./databaseStepComponents/DbType";
import DbUsername from "./databaseStepComponents/DbUsername";
import DbVersion from "./databaseStepComponents/DbVersion";

const initialValue = {
 name: "",
 type: "",
 version: "",
 user: "",
 password: ""
};

const CreateDatabase = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);
 const [goToStepper, setGoToStepper] = useState<boolean>(false);
 const [activeStep, setActiveStep] = useState<number>(0);
 const [dbTypes, setDbTypes] = useState<Array<{ id: number; name: string }>>([]);
 const [dbVersions, setDbVersions] = useState<Array<{ id: number; name: string }>>([]);
 const [databaseData, setDatabaseData] = useState<dbData>(initialValue);

 const handleOpen = async () => {
  setGoToStepper(false);
  setDbTypes(await dispatch(getDockerDbTypes()));
  handleReset();
  setOpen(true);
 };
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(
   postInstallDatabase(
    id,
    databaseData.name,
    databaseData.user,
    databaseData.password,
    databaseData.type,
    databaseData.version
   )
  );
  setLoading(false);
  handleClose();
 };

 const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
 };

 const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
 };

 const handleReset = () => {
  setDatabaseData(initialValue);
  setActiveStep(0);
 };

 const handleChange = (key: keyof dbData, value: string) => {
  setDatabaseData({ ...databaseData, [key]: value });
 };

 useEffect(() => {
  (async () => {
   if (databaseData.type !== "")
    setDbVersions(await dispatch(getDockerDbVersions(databaseData.type)));
  })();
 }, [databaseData.type]);

 const RenderStepContent = (): ReactElement => {
  switch (activeStep) {
   case 0:
    return (
     <DbName
      activeStep={activeStep}
      firstValue={databaseData.name}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropUsername={(value) => handleChange("name", value)}
     />
    );
   case 1:
    return (
     <DbType
      activeStep={activeStep}
      firstValue={databaseData.type}
      dbTypes={dbTypes}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropsValue={(value) => handleChange("type", value)}
     />
    );
   case 2:
    return (
     <DbVersion
      activeStep={activeStep}
      firstValue={databaseData.version}
      dbVersions={dbVersions}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropsValue={(value) => handleChange("version", value)}
     />
    );
   case 3:
    return (
     <DbUsername
      activeStep={activeStep}
      firstValue={databaseData.user}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropUsername={(value) => handleChange("user", value)}
     />
    );
   case 4:
    return (
     <DbPassword
      activeStep={activeStep}
      firstValue={databaseData.password}
      handleNext={handleNext}
      handleBack={handleBack}
      handleReset={handleReset}
      setPropValue={(value) => handleChange("password", value)}
     />
    );
   case 5:
    return (
     <DbRecap databaseData={databaseData} handleBack={handleBack} handleReset={handleReset} />
    );
   default:
    return <></>;
  }
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <FormattedMessage id="docker.website.createDatabase" />
   </Button>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "docker.website.createDatabase" })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading}
    showConfirmButton={activeStep === websiteContainerDatabaseSteps.length - 1}>
    {!goToStepper ? (
     <Stack>
      <Stack direction="row">
       <Stack justifyContent="center" spacing={2}>
        <Typography>
         <FormattedMessage id="docker.website.firstExplainDatabase" />
        </Typography>
        <Typography>
         <FormattedMessage id="docker.website.secondExplainDatabase" />
        </Typography>
        <Typography>
         <FormattedMessage id="docker.website.thirdExplainDatabase" />
        </Typography>
       </Stack>
       <Stack alignItems="center" justifyContent="center">
        <IconSelector
         icon="DatabaseIcon"
         props={{ sx: { fontSize: "200px", color: "lightgray" } }}
        />
       </Stack>
      </Stack>
      <Button onClick={() => setGoToStepper(true)}>
       <FormattedMessage id="docker.website.startNow" />
      </Button>
     </Stack>
    ) : (
     <Stack>
      <Stepper activeStep={activeStep} alternativeLabel>
       {websiteContainerDatabaseSteps.map((label) => (
        <Step key={label.label}>
         <StepLabel optional={label.description ? <FormattedMessage id={label.description} /> : ""}>
          {<FormattedMessage id={label.label} />}
         </StepLabel>
        </Step>
       ))}
      </Stepper>
      <RenderStepContent />
     </Stack>
    )}
   </AppModal>
  </>
 );
};

export default CreateDatabase;
