import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import IconSelector from "components/shared/images/IconSelector";

import { getZoneDetails } from "redux/selectors/zoneSelector";

const ZoneDetails = (): ReactElement => {
 const zoneDetails = useSelector(getZoneDetails);

 return (
  <Grid container spacing={2}>
   <Grid xs={12}>
    <Paper
     elevation={0}
     sx={{ mt: 3, pb: 0.7, minHeight: 216, borderRadius: "10px", boxShadow: 0, pt: 1 }}>
     <Stack spacing={2}>
      <Stack direction="row" alignItems="center" spacing={2} pl={1}>
       <IconSelector icon="DetailsIcon" props={{ fontSize: "medium", color: "secondary" }} />
       <Typography variant="h6">
        <FormattedMessage id="zone.details" />
       </Typography>
      </Stack>
      <Stack>
       <Stack direction="row" p={1} justifyContent="space-between">
        <Typography fontWeight="bold">
         <FormattedMessage id="zone.status" />
        </Typography>
        <IconSelector
         icon={zoneDetails?.active ? "CheckCircleIcon" : "WarningIcon"}
         props={{ color: zoneDetails?.active ? "success" : "error" }}
        />
       </Stack>
       <Divider />
       <Stack direction="row" p={1} justifyContent="space-between">
        <Typography fontWeight="bold">
         <FormattedMessage id="zone.records" />
        </Typography>
        <Typography>{zoneDetails?.records.length}</Typography>
       </Stack>
       <Divider />
       <Stack direction="row" p={1} justifyContent="space-between">
        <Typography fontWeight="bold">
         <FormattedMessage id="zone.email" />
        </Typography>
        <Typography>{zoneDetails?.mail}</Typography>
       </Stack>
       <Divider />
       <Stack direction="row" p={1} justifyContent="space-between">
        <Typography fontWeight="bold">
         <FormattedMessage id="zone.ttl" />
        </Typography>
        <Typography>{zoneDetails?.ttl}</Typography>
       </Stack>
      </Stack>
     </Stack>
    </Paper>
   </Grid>
  </Grid>
 );
};

export default ZoneDetails;
