import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import StyledBadge from "components/shared/badge/StyledBadge";

import { saveNewMessageId } from "redux/handlers/newMessageHandler";

import { getNewMessagesList } from "redux/selectors/newMessageSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ChatButton = (): ReactElement => {
 const dispatch = useAppDispatch();

 const messageIdList = useSelector(getNewMessagesList);

 const { socket } = useContext(AppContext);

 const [dataToUpdate, setDataToUpdate] = useState<number>(-1);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("chat_messages", (value): void => {
    if (value?.updated) {
     setDataToUpdate(Number(value?.chat_id));
    }
   });
  return () => {
   !isNil(socket) && socket.off("chat_messages");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (dataToUpdate !== -1 && !messageIdList?.includes(-20)) dispatch(saveNewMessageId(-20));
   if (dataToUpdate && dataToUpdate !== -1 && !messageIdList?.includes(dataToUpdate)) {
    dispatch(saveNewMessageId(dataToUpdate));
   }
  })();
 }, [dataToUpdate]);

 return messageIdList?.includes(-20) ? (
  <StyledBadge
   overlap="circular"
   anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
   variant="dot">
   <img
    width={25}
    height={25}
    src={`${process.env.PUBLIC_URL}/svg/loghi/kxIcon.png`}
    alt="konsolex"
   />
  </StyledBadge>
 ) : (
  <img
   width={25}
   height={25}
   src={`${process.env.PUBLIC_URL}/svg/loghi/kxIcon.png`}
   alt="konsolex"
  />
 );
};

export default ChatButton;
