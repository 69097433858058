import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { isNil } from "ramda";

import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import ZoneRecords from "components/zone/ZoneRecords";

import { postGetAllDomainRecords } from "redux/handlers/domainsHandler";

import { getDomainsList } from "redux/selectors/domainsSelector";

import { Record } from "types/api/appsApiInterface";

const DomainDns = (): ReactElement => {
 const domainData = useSelector(getDomainsList)[0];

 const [records, setRecords] = useState<Array<Record>>([]);
 const [loading, setLoading] = useState<boolean>(true);

 useEffect(() => {
  (async () => {
   setLoading(true);
   if (!isNil(domainData.isp_id) && !isNil(domainData.zone_id))
    setRecords(await postGetAllDomainRecords(domainData.isp_id, domainData.zone_id));
   setLoading(false);
  })();
 }, []);

 return (
  <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
   <Stack>
    <Stack alignItems="center" justifyContent="flex-start" direction="row" px={2} pt={1}>
     <IconSelector icon="KeyIcon" props={{ fontSize: "medium", color: "secondary" }} />
     <Typography variant="h6" component="h2" ml={1}>
      <FormattedMessage id="domains.dns" />{" "}
     </Typography>
    </Stack>
    <Stack p={2} spacing={2}>
     <Alert severity="warning">
      <FormattedMessage id="domains.dns.nsChangeMessage" />
     </Alert>
     {loading ? (
      <CircularProgress />
     ) : (
      <ZoneRecords
       ispId={domainData.isp_id}
       zoneId={domainData.zone_id}
       data={records}
       domain={domainData.name}
       reloadData={async () =>
        setRecords(await postGetAllDomainRecords(domainData.isp_id, domainData.zone_id))
       }
      />
     )}
    </Stack>
   </Stack>
  </Paper>
 );
};

export default DomainDns;
