import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const BankTransferInfo = (): ReactElement => {
 return (
  <Alert severity="warning">
   <Typography mb={2} sx={{ textAlign: "left" }}>
    <FormattedMessage id="recharge.transferMessage" />
   </Typography>
   <Stack sx={{ textAlign: "left" }}>
    <Typography>
     <FormattedMessage id="recharge.transfer1" />
    </Typography>
    <Typography>
     <FormattedMessage id="recharge.transfer2" />
    </Typography>
    <Typography>
     <FormattedMessage id="recharge.transfer3" />
    </Typography>
    <Typography>
     <FormattedMessage id="recharge.transfer4" />
    </Typography>
   </Stack>
  </Alert>
 );
};

export default BankTransferInfo;
