import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import HostPage from "pages/profilePages/HostPage";
import TeamsPage from "pages/profilePages/TeamsPage";
import TeamsUserPage from "pages/profilePages/TeamsUserPage";

import { getUserChannels } from "redux/handlers/notificationHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { ITypesRenew } from "types/api/notificationApiInterface";

import AppChannel from "./channels/AppChannel";
import EmailChannel from "./channels/EmailChannel";
import TelegramChannel from "./channels/TelegramChannel";

const ProfileSettings = (): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();
 const location = useLocation();
 const { state } = location;

 const [selectedChannel, setSelectedChannel] = useState<string>("Email");
 const [typePreferences, setTypePreferences] = useState<ITypesRenew | null>(null);
 const [section, setSection] = useState<number>(0);

 const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
  setSection(newValue);
 };

 useEffect(() => {
  (async () => {
   setTypePreferences(await dispatch(getUserChannels("server")));
   setSection(state?.section || 0);
  })();
 }, []);

 const RenderOptions = (): ReactElement => {
  switch (selectedChannel) {
   case "Email":
    return <EmailChannel defaultPreferences={typePreferences} />;
   case "Telegram":
    return <TelegramChannel defaultPreferences={typePreferences} />;
   case "Sito":
    return <AppChannel />;
   default:
    return <></>;
  }
 };

 const RenderTabContent = (): ReactElement => {
  switch (section) {
   case 0:
    return (
     <>
      <Alert severity="info">
       <FormattedMessage id="profile.settings.sectionMessage" />
      </Alert>
      <Divider />
      <Stack>
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        options={["Email"]}
        value={selectedChannel}
        onChange={(e, value) => setSelectedChannel(value || "Email")}
        renderInput={(params) => (
         <TextField
          {...params}
          label={intl.formatMessage({ id: "profile.settings.channel" })}
          variant="standard"
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
       <RenderOptions />
      </Stack>
     </>
    );
   case 1:
    return <HostPage />;
   case 2:
    return <TeamsPage />;
   case 3:
    return <TeamsUserPage />;
   default:
    return (
     <>
      <Alert severity="info">
       <FormattedMessage id="profile.settings.sectionMessage" />
      </Alert>
      <Divider />
      <Stack>
       <Autocomplete
        fullWidth={true}
        autoHighlight
        sx={{ my: 2 }}
        options={["Email"]}
        value={selectedChannel}
        onChange={(e, value) => setSelectedChannel(value || "Email")}
        renderInput={(params) => (
         <TextField
          {...params}
          label={intl.formatMessage({ id: "profile.settings.channel" })}
          variant="standard"
          InputLabelProps={{ shrink: true }}
         />
        )}
       />
       <RenderOptions />
      </Stack>
     </>
    );
  }
 };

 return (
  <Paper elevation={0} sx={{ mt: 2, borderRadius: "10px", boxShadow: 0 }}>
   <Stack spacing={1} p={2}>
    <Stack justifyContent="space-between" alignItems="flex-end" direction="row" pb={1}>
     <Typography fontWeight="bold" fontSize={16} textTransform="uppercase">
      <FormattedMessage id="profile.settings" />
     </Typography>
    </Stack>
    <Tabs value={section} onChange={handleChangeTab}>
     <Tab label="Notifiche" />
     <Tab label="Host" />
     <Tab label="Teams" />
     <Tab label={intl.formatMessage({ id: "profile.teamsPermission" })} />
    </Tabs>
    <RenderTabContent />
   </Stack>
  </Paper>
 );
};

export default ProfileSettings;
