import React from "react";

import { createSvgIcon } from "@mui/material/utils";

export const DatabaseManagementIcon = createSvgIcon(
 <svg
  fill="currentColor"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  width="800px"
  height="800px"
  viewBox="0 0 208.465 208.465"
  xmlSpace="preserve">
  <title>database management</title>
  <g>
   <g id="_x39_6_54_">
    <g>
     <path
      d="M166.056,148.325c0.381,0.319,0.903,0.63,1.63,0.954c5.896,2.615,13.024,2.59,19.464,2.631
				c2.812,0.021,5.651-0.021,8.449-0.279c2.296-0.213,4.642-0.406,6.871-0.98c0.559-0.142,4.204-1.238,5.773-2.722
				c0-4.438,0-8.886,0-13.324c-5.297,3.326-17.088,2.96-21.1,2.915c-4.011-0.051-15.776,0.406-21.073-2.895
				c-0.005,3.661-0.005,7.317-0.01,10.979h0.065c-0.025,0.02-0.04,0.045-0.065,0.07
				C166.061,146.562,166.061,147.446,166.056,148.325z"
     />
     <path
      d="M208.238,152.972c0-0.66,0-1.32,0-1.976c-5.297,3.326-17.088,2.96-21.1,2.915c-4.017-0.046-15.808,0.411-21.094-2.899
				c0,0.69,0,1.386,0,2.076c0,2.474-0.762,6.226,0.614,8.48c2.494,4.083,10.674,3.936,14.666,4.235
				c8.013,0.599,17.473,0.833,24.775-2.905C209.492,161.178,208.238,156.023,208.238,152.972z"
     />
     <path
      d="M166.075,111.494c0,7.15,16.895,7.201,21.079,7.262c4.179,0.066,21.089-0.198,21.089-7.262
				c0-7.149-16.899-7.195-21.089-7.256C182.97,104.304,166.075,104.345,166.075,111.494z"
     />
     <path
      d="M208.238,116.247c-1.549,1.519-3.951,2.712-7.257,3.545c-4.743,1.193-10.095,1.381-12.867,1.381
				c-0.381,0-0.717-0.005-1.001-0.01l-0.396-0.005c-4.408-0.076-15.879-0.265-20.637-4.81c0,4.286-0.006,8.572-0.011,12.873h0.046
				c-0.016,0.016-0.03,0.035-0.046,0.051c0,0.894,0,1.792,0,2.691c0.376,0.31,0.894,0.624,1.61,0.935
				c5.895,2.615,13.024,2.595,19.463,2.635c2.813,0.021,5.647-0.02,8.45-0.279c2.295-0.218,4.642-0.406,6.87-0.979
				c0.559-0.138,4.205-1.229,5.774-2.712C208.238,126.444,208.238,121.346,208.238,116.247z"
     />
     <path
      d="M176.419,82.126c1.193,0,2.377,0.099,3.555,0.178c0.168-1.176,0.3-2.356,0.361-3.547
				c-1.158-21.498-16.463-36.785-37.943-37.945c-12.604-0.675-23.115,5.759-29.858,15.168c-6.736-8.869-17.217-14.48-29.879-15.168
				c-21.439-1.152-36.868,18.199-37.932,37.945c-0.12,2.536,0.041,4.977,0.437,7.335c-1.343-0.19-2.709-0.32-4.103-0.398
				c-23.176-1.249-39.852,19.672-41.002,41c-1.181,21.805,17.265,37.851,37.222,40.628v0.381h101.679
				c-8.389-9.095-13.559-21.205-13.559-34.561C125.391,104.969,148.241,82.126,176.419,82.126z"
     />
    </g>
   </g>
  </g>
 </svg>,
 "DatabaseManagement"
);
