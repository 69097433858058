import React, { ReactElement } from "react";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { profileMenuLinks } from "constants/staticLinks";

import CreditsPage from "pages/profilePages/CreditsPage";
import CustomersPage from "pages/profilePages/CustomersPage";
import ProfilePage from "pages/profilePages/ProfilePage";
import RechargePage from "pages/profilePages/RechargePage";

import Services from "components/profile/services/Services";
import ProfileSettings from "components/profile/settings/ProfileSettings";
import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import MobileSectionMenu from "components/shared/sideMenu/MobileSectionMenu";
import SectionMenu from "components/shared/sideMenu/SectionMenu";

import OrdersPage from "./OrdersPage";
import SecurityPage from "./SecurityPage";

const UserProfile = (): ReactElement => {
 const location = useLocation();
 const params = useParams<{ section: string }>();
 const theme = useTheme();
 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));

 const RenderProfilePages = (): ReactElement => {
  switch (location.pathname) {
   case "/profile/customers":
    return <CustomersPage />;
   case "/profile/recharge":
    return <RechargePage />;
   case "/profile/credits":
    return <CreditsPage />;
   case "/profile/services":
    return <Services />;
   case "/profile/orders":
    return <OrdersPage />;
   case "/profile/settings":
    return <ProfileSettings />;
   case "/profile/security":
    return <SecurityPage />;
   default:
    return <ProfilePage />;
  }
 };

 return (
  <Container maxWidth="xl">
   <Stack mt={2}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "profile", url: "/profile/user" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container mt={desktopViewPort ? 8 : 0}>
    {desktopViewPort ? (
     <Grid xs={desktopViewPort ? 3 : 0}>
      <Stack>
       <SectionMenu
        menuList={profileMenuLinks
         .filter(
          (element) =>
           element.name !== "profile.host" &&
           element.name !== "profile.teams" &&
           element.name !== "profile.teamsPermission"
         )
         .map((item) => ({
          name: item.name,
          url: `/profile/${item.url}`,
          icon: item.icon,
          disabled: false
         }))}
       />
      </Stack>
     </Grid>
    ) : (
     <MobileSectionMenu links={profileMenuLinks} disabled={[]} url="/profile" />
    )}
    <Grid xs={desktopViewPort ? 9 : 12} mt={desktopViewPort ? 0 : 2}>
     <RenderProfilePages />
    </Grid>
   </Grid>
  </Container>
 );
};

export default UserProfile;
