import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

const ConfirmSepa = ({
 disabledButton,
 buttonText,
 confirmSelection
}: {
 disabledButton: boolean;
 buttonText: string;
 confirmSelection: () => void;
}) => {
 const intl = useIntl();

 const [loading, setLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);
 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleSubmit = async () => {
  setLoading(true);
  confirmSelection();
  setLoading(false);
  setOpenModal(false);
 };

 return (
  <>
   <Button variant="kxActionButton" disabled={disabledButton} onClick={handleOpen}>
    <FormattedMessage id={buttonText} />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={loading}
    handleConfirm={handleSubmit}
    title={intl.formatMessage({ id: "recharge.confirmOrder" })}>
    <Stack spacing={2}>
     <Alert severity="warning">
      <FormattedMessage id="recharge.sepa.needToCompile" />
     </Alert>
     <Typography>
      <FormattedMessage id="recharge.confirmOrderMessage" />
     </Typography>
    </Stack>
   </AppModal>
  </>
 );
};

export default ConfirmSepa;
