import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postEnableAutoPayment } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

const EnableAutoPay = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleSubmit = async () => {
  setIsLoading(true);
  await dispatch(postEnableAutoPayment(id));
  setIsLoading(false);
  setOpenModal(false);
 };

 return (
  <>
   <Tooltip title={<FormattedMessage id="profile.security.enableAutoPay" />}>
    <IconButton onClick={handleOpen} color="success">
     <IconSelector icon="AddCircleIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    loading={isLoading}
    handleConfirm={handleSubmit}
    title={intl.formatMessage({ id: "profile.security.enableAutoPay" })}>
    <Stack spacing={2}>
     <FormattedMessage id="profile.security.enableAutoPayMessage" />
    </Stack>
   </AppModal>
  </>
 );
};

export default EnableAutoPay;
