import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { decodeFileFromBase64 } from "helpers/generic";
import JSZip from "jszip";

import MenuItem from "@mui/material/MenuItem";

const DownloadDocuments = ({
 ridDocument,
 cfDocument,
 identityDocument,
 closeMenu
}: {
 ridDocument: string;
 cfDocument: string;
 identityDocument: string;
 closeMenu: () => void;
}): ReactElement => {
 const handleDownloadFile = () => {
  const ridD = decodeFileFromBase64(
   ridDocument.replace("data:application/pdf;base64,", ""),
   "application/pdf"
  );
  const cfD = decodeFileFromBase64(
   cfDocument.replace("data:application/pdf;base64,", ""),
   "application/pdf"
  );
  const idD = decodeFileFromBase64(
   identityDocument.replace("data:application/pdf;base64,", ""),
   "application/pdf"
  );

  const zip = new JSZip();
  zip.file("sepa_document.pdf", ridD);
  zip.file("codice_fiscale.pdf", cfD);
  zip.file("documento_identita.pdf", idD);
  zip.generateAsync({ type: "blob" }).then(function (content) {
   // see FileSaver.js
   const url = URL.createObjectURL(content);

   // Create an anchor tag for downloading
   const a = document.createElement("a");

   // Set the URL and download attribute of the anchor tag
   a.href = url;
   a.download = "documents.zip";

   // Trigger the download by clicking the anchor tag
   a.click();
  });

  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={handleDownloadFile}>
    <FormattedMessage id="configurator.autopayment.downloadDocument" />
   </MenuItem>
  </>
 );
};

export default DownloadDocuments;
