import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import IconSelector from "components/shared/images/IconSelector";

const DownloadDocument = (): ReactElement => {
 const handleDownloadDocument = () => {
  const url = `${process.env.PUBLIC_URL}/documents/Modulo_Sepa_B2B.xlsx`;

  // Create an anchor tag for downloading
  const a = document.createElement("a");

  // Set the URL and download attribute of the anchor tag
  a.href = url;
  a.download = "sepa_module_b2b.xlsx";

  // Trigger the download by clicking the anchor tag
  a.click();
 };

 return (
  <Tooltip title={<FormattedMessage id="profile.security.downloadCompileDocument" />}>
   <IconButton onClick={handleDownloadDocument}>
    <IconSelector icon="DownloadIcon" />
   </IconButton>
  </Tooltip>
 );
};

export default DownloadDocument;
