import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import IconSelector from "components/shared/images/IconSelector";

import { getZoneDetails } from "redux/selectors/zoneSelector";

import ZoneRecords from "../ZoneRecords";

const ZoneRecordsManage = (): ReactElement => {
 const zoneDetails = useSelector(getZoneDetails);

 return (
  <Grid container spacing={2}>
   <Grid xs={12}>
    <Paper
     elevation={0}
     sx={{ mt: 3, pb: 0.7, minHeight: 216, borderRadius: "10px", boxShadow: 0, pt: 1 }}>
     <Stack spacing={2}>
      <Stack direction="row" alignItems="center" spacing={2} pl={1}>
       <IconSelector icon="ServiceIcon" props={{ fontSize: "medium", color: "secondary" }} />
       <Typography variant="h6">
        <FormattedMessage id="zone.manage.records" />
       </Typography>
      </Stack>
      <Stack p={2}>
       <ZoneRecords
        zoneId={zoneDetails?.zone_id || 0}
        ispId={zoneDetails?.isp_id || 0}
        data={zoneDetails?.records || []}
        domain={zoneDetails?.zone || ""}
       />
      </Stack>
     </Stack>
    </Paper>
   </Grid>
  </Grid>
 );
};

export default ZoneRecordsManage;
