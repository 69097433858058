import React, { ReactElement } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const MessageBubble = ({
 message,
 date,
 type
}: {
 message: string;
 date: string;
 type: number;
}): ReactElement => {
 const senderMessage = {
  backgroundColor: "#4e5c7e",
  borderRadius: 3,
  borderBottomRightRadius: 0,
  maxWidth: 340,
  color: "white",
  overflowWrap: "break-word"
 };

 const receiverMessage = {
  backgroundColor: "#0D1934",
  borderRadius: 3,
  borderBottomLeftRadius: 0,
  maxWidth: 340,
  color: "white",
  overflowWrap: "break-word"
 };

 const ticketOpenMessage = {
  backgroundColor: "#47aa9b",
  borderRadius: 3,
  borderBottomLeftRadius: 0,
  maxWidth: 340,
  color: "white",
  overflowWrap: "break-word"
 };

 const formatDate = (): string => {
  const formattedDate = new Date(date);

  return `${
   formattedDate.getHours() < 10 ? "0" + formattedDate.getHours() : formattedDate.getHours()
  }:${
   formattedDate.getMinutes() < 10 ? "0" + formattedDate.getMinutes() : formattedDate.getMinutes()
  }`;
 };

 const DynamicRenderer = ({ text }: { text: string }) => {
  // Split the text and dynamically render React components
  const parsedContent = text.split(/(<b>.*?<\/b>)/g).map((part, index) => {
   if (part.startsWith("<b>") && part.endsWith("</b>")) {
    const content = part.replace("<b>", "").replace("</b>", "");
    return <b key={index}>{content}</b>;
   } else if (part.includes("<br>")) {
    return (
     <span key={index}>
      {part.replaceAll("<br>", "")}
      <br />
     </span>
    );
   }

   return part; // Return plain text parts as-is
  });

  return <div>{parsedContent}</div>;
 };

 return (
  <Stack direction="row" justifyContent={type === 1 ? "flex-end" : "flex-start"}>
   <Stack
    px={1}
    sx={
     message.includes("New ticket from")
      ? ticketOpenMessage
      : type === 1
      ? senderMessage
      : receiverMessage
    }
    textAlign={"left"}>
    <Typography>
     <DynamicRenderer text={message.replaceAll("<bold>", "<b>").replaceAll("</bold>", "</b>")} />
    </Typography>
    <Typography textAlign={"right"} variant="caption">
     {formatDate()}
    </Typography>
   </Stack>
  </Stack>
 );
};

export default MessageBubble;
