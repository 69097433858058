import React, { Fragment, ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { getAllUserConversation } from "redux/handlers/chatHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IAdminChatList, IMessage } from "types/api/chatApiInterface";
import { IUsersList } from "types/api/userApiInterface";

import ChatDateBubble from "../components/ChatDateBubble";
import MessageBubble from "../components/MessageBubble";
import MessageListElement from "../components/MessageListElement";

const AdminChatList = ({ userList }: { userList: Array<IUsersList> }): ReactElement => {
 const dispatch = useAppDispatch();

 const messagesEndRef = useRef<HTMLDivElement>(null);
 const { socket } = useContext(AppContext);

 const [openChat, setOpenChat] = useState<boolean>(false);
 const [assistantMessages, setAssistantMessages] = useState<Array<IAdminChatList>>([]);
 const [messageList, setMessageList] = useState<Array<IMessage>>([]);
 const [reloadData, setReloadData] = useState<boolean>(true);
 const [selectedAssistant, setSelectedAssistant] = useState<string>("");

 useEffect(() => {
  (async () => {
   if (reloadData) {
    const datas = await dispatch(getAllUserConversation());
    setAssistantMessages(datas);
    if (datas.find((element) => element.userId === selectedAssistant)) {
     setMessageList(datas.find((element) => element.userId === selectedAssistant)?.messages || []);
    }
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 useEffect(() => {
  scrollToBottom();
 }, [messageList]);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("chat_messages", (value): void => {
    if (value?.updated) {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("chat_messages");
  };
 }, [socket]);

 const scrollToBottom = () => {
  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
 };

 return !openChat ? (
  <Stack p={2} minHeight={300} sx={{ backgroundColor: "#f9f9f9" }}>
   {assistantMessages.map((element, index) => {
    if (element.messages.length > 0) {
     return (
      <Stack
       key={`message-list-${index}`}
       onClick={() => {
        setOpenChat(true);
        setMessageList(element.messages);
        setSelectedAssistant(element.userId);
       }}>
       <MessageListElement
        name={
         userList.find((user) => user.userid === element.userId)?.companyName ||
         "Name not available"
        }
        lastMessage={element.messages[element.messages.length - 1]?.message || ""}
        avatar={""}
        newMessage={false}
        date={element?.lastTimeUsed || ""}
        border={false}
       />
      </Stack>
     );
    } else {
     return <></>;
    }
   })}
  </Stack>
 ) : (
  <Stack justifyContent="space-between">
   <Stack p={2} minHeight={300} maxHeight={400} sx={{ backgroundColor: "#f9f9f9" }}>
    <Stack direction="row" pb={2}>
     <Button
      startIcon={<IconSelector icon="ArrowBackIosIcon" />}
      onClick={() => setOpenChat(false)}>
      <FormattedMessage id="app.back" />
     </Button>
    </Stack>
    <Stack
     overflow="auto"
     spacing={2}
     sx={{ scrollbarWidth: "thin", scrollbarColor: "#0D1934 transparent" }}>
     {messageList.map((element, index) => {
      let controlledDate = false;
      if (index === 0) controlledDate = true;
      if (index > 0) {
       const date1 = new Date(element.datetime);
       const date2 = new Date(messageList[index - 1]?.datetime);

       controlledDate = date1.getUTCDate() !== date2.getUTCDate();
      }
      return (
       <Fragment key={`message-${index}`}>
        {controlledDate && <ChatDateBubble date={element.datetime || ""} />}
        <MessageBubble
         message={element?.message || ""}
         date={element?.datetime || ""}
         type={element?.type === "User" ? 1 : 2}
        />
       </Fragment>
      );
     })}
     <div ref={messagesEndRef} />
    </Stack>
   </Stack>
   <Divider />
  </Stack>
 );
};

export default AdminChatList;
