import React, { ReactElement, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import SendIcon from "@mui/icons-material/Send";

import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { postSendMessage } from "redux/handlers/chatHandler";

import { getTelegramId, getUserIdString } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const UserReply = ({ reloadData }: { reloadData?: () => void }): ReactElement => {
 const dispatch = useAppDispatch();
 const intl = useIntl();

 const userId = useSelector(getUserIdString);
 const telegramId = useSelector(getTelegramId);

 const [message, setMessage] = useState<string>("");

 const handleKeyDown = (event: any) => {
  if (event.key === "Enter") {
   sendMessage(message);
  }
 };

 const sendMessage = async (message: string) => {
  setMessage("");
  await dispatch(postSendMessage(userId, message, telegramId));
  reloadData && reloadData();
 };

 return (
  <Stack direction={"row"} px={2} py={0.5} alignItems={"center"}>
   <TextField
    className="message-input"
    value={message}
    fullWidth
    size="small"
    onChange={(e) => setMessage(e.target.value)}
    placeholder={intl.formatMessage({ id: "chat.insertMessage" })}
    onKeyDown={handleKeyDown}
   />
   <IconButton className="send-button" onClick={() => sendMessage(message)}>
    <SendIcon />
   </IconButton>
  </Stack>
 );
};

export default UserReply;
