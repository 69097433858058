import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { dbData } from "types/global/container";

const DbRecap = ({
 databaseData,
 handleBack,
 handleReset
}: {
 databaseData: dbData;
 handleBack: () => void;
 handleReset: () => void;
}): ReactElement => {
 return (
  <Stack p={2} spacing={2}>
   <Stack spacing={1}>
    <Typography>
     <FormattedMessage id="docker.website.checkForData" />
    </Typography>
    <Stack direction="row" spacing={1}>
     <Typography>
      <FormattedMessage id="docker.website.databaseName" />:
     </Typography>
     <Typography>{databaseData.name}</Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography>
      <FormattedMessage id="docker.website.databaseUser" />:
     </Typography>
     <Typography>{databaseData.user}</Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography>
      <FormattedMessage id="docker.website.databasePassword" />:
     </Typography>
     <Typography>{databaseData.password}</Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography>
      <FormattedMessage id="docker.website.databaseType" />:
     </Typography>
     <Typography>{databaseData.type}</Typography>
    </Stack>
    <Stack direction="row" spacing={1}>
     <Typography>
      <FormattedMessage id="docker.website.databaseVersion" />:
     </Typography>
     <Typography>{databaseData.version}</Typography>
    </Stack>
   </Stack>
   <Stack direction="row" spacing={2} justifyContent="center">
    <Button onClick={handleBack}>
     <FormattedMessage id="app.back" />
    </Button>
    <Button onClick={handleReset}>Reset</Button>
    <Button disabled>
     <FormattedMessage id="app.next" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default DbRecap;
