import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { zoneLinks } from "constants/staticLinks";

import SectionsBreadCrumbs from "components/shared/breadCrumbs/SectionsBreadCrumbs";
import MobileSectionMenu from "components/shared/sideMenu/MobileSectionMenu";
import SectionMenu from "components/shared/sideMenu/SectionMenu";

import { postSyncronizeSocket } from "redux/handlers/socketHandler";
import { getAllIspNotPaged } from "redux/handlers/websitesHandler";
import { getAllZone } from "redux/handlers/zoneHandler";

import { getZoneDetails } from "redux/selectors/zoneSelector";

import { useAppDispatch } from "hooks/reduxHook";

import ZoneDetails from "./manage/ZoneDetails";
import ZoneRecordsManage from "./manage/ZoneRecordsManage";

const ZoneManage = (): ReactElement => {
 const theme = useTheme();
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);
 const location = useLocation();

 const desktopViewPort = useMediaQuery(theme.breakpoints.up("md"));
 const params = useParams<{ zone_id: string; section: string }>();

 const zoneDetails = useSelector(getZoneDetails);

 const [loading, setLoading] = useState<boolean>(true);
 const [reloadData, setReloadData] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   await dispatch(getAllIspNotPaged("nameserver"));
   await dispatch(getAllZone(0, 10, "", params.zone_id));
   setLoading(false);
   await dispatch(postSyncronizeSocket("zone"));
  })();
 }, []);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("app_data", (value): void => {
    if (value?.completed && value?.section === "zone") {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("app_data");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    dispatch(getAllZone(0, 10, "", params.zone_id));
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 const RenderZoneManageSection = (): ReactElement => {
  switch (params.section) {
   case "details":
    return <ZoneDetails />;
   case "records":
    return <ZoneRecordsManage />;
   default:
    return <></>;
  }
 };

 return !loading ? (
  <Container maxWidth="xl">
   <Stack pt={1}>
    <SectionsBreadCrumbs
     links={[
      { name: "home", url: "/" },
      { name: "apps", url: "/app/sites" },
      { name: "zone", url: "/app/zone" },
      { name: params?.section || "", url: location.pathname }
     ]}
    />
   </Stack>
   <Grid container>
    {desktopViewPort ? (
     <Grid xs={desktopViewPort ? 3 : 0}>
      <Stack mt={6}>
       <SectionMenu
        menuList={zoneLinks.map((item) => ({
         name: item.name,
         url: `/app/zone/${params?.zone_id}/${item.url}`,
         icon: item.icon
        }))}
       />
      </Stack>
     </Grid>
    ) : (
     <MobileSectionMenu links={zoneLinks} disabled={[]} url={`/app/zone/${params?.zone_id}`} />
    )}
    <Grid xs={desktopViewPort ? 9 : 12} mt={3}>
     <Stack mt={5} justifyContent="space-between" direction={desktopViewPort ? "row" : "column"}>
      <Stack>
       <Stack spacing={1} alignItems="flex-start">
        <Typography fontWeight="bold" fontSize={16} ml={1} textTransform="uppercase">
         {zoneDetails?.zone}
        </Typography>
       </Stack>
      </Stack>
     </Stack>
     <RenderZoneManageSection />
    </Grid>
   </Grid>
  </Container>
 ) : (
  <></>
 );
};

export default ZoneManage;
