import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postChangeIpv6Status } from "redux/handlers/serverHandler";

import { getServerDetails } from "redux/selectors/serversSelector";

import { useAppDispatch } from "hooks/reduxHook";

const Ipv6StatusChange = ({ id, action }: { id: number; action: string }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const serverDetails = useSelector(getServerDetails);

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpenModal(true);
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postChangeIpv6Status(id, action === "enable" ? true : false));
  setLoading(false);

  handleClose();
 };

 return (
  <>
   <Button variant="kxActionButton" onClick={handleOpen}>
    <FormattedMessage id={action === "enable" ? "server.ip.enableIp" : "server.ip.disableIp"} />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    title={intl.formatMessage({
     id: action === "enable" ? "server.ip.enableIp" : "server.ip.disableIp"
    })}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    disabled={loading || (serverDetails.server_status === "running" && action === "disable")}>
    {serverDetails.server_status === "running" && action === "disable" ? (
     <Typography>
      <FormattedMessage id="server.ip.serverRunning" />
     </Typography>
    ) : (
     <Typography>
      <FormattedMessage
       id={action === "enable" ? "server.ip.enableIpMessage" : "server.ip.disableIpMessage"}
      />
     </Typography>
    )}
   </AppModal>
  </>
 );
};

export default Ipv6StatusChange;
