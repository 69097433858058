import React, { Fragment, ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import { getAllTickets, postCloseTicket } from "redux/handlers/chatHandler";
import { removeMessageId } from "redux/handlers/newMessageHandler";

import { getNewMessagesList } from "redux/selectors/newMessageSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { ITicket } from "types/api/chatApiInterface";
import { IUsersList } from "types/api/userApiInterface";

import AdminReply from "../actions/AdminReply";
import ChatDateBubble from "../components/ChatDateBubble";
import MessageBubble from "../components/MessageBubble";
import MessageListElement from "../components/MessageListElement";

const AdminTicketList = ({ userList }: { userList: Array<IUsersList> }): ReactElement => {
 const dispatch = useAppDispatch();

 const messagesEndRef = useRef<HTMLDivElement>(null);
 const { socket } = useContext(AppContext);
 const messageIdList = useSelector(getNewMessagesList);

 const [openChat, setOpenChat] = useState<boolean>(false);
 const [ticketList, setTicketList] = useState<Array<Array<ITicket>>>([]);
 const [messageList, setMessageList] = useState<Array<ITicket>>([]);
 const [reloadData, setReloadData] = useState<boolean>(true);
 const [chatIndex, setChatIndex] = useState<number>(-1);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    const tList = await dispatch(getAllTickets());
    setTicketList(tList);
    if (chatIndex !== -1) {
     setMessageList(tList[chatIndex]);
    }
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 useEffect(() => {
  scrollToBottom();
 }, [messageList]);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("chat_messages", (value): void => {
    if (value?.updated) {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("chat_messages");
  };
 }, [socket]);

 const scrollToBottom = () => {
  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
 };

 const handleCloseTicket = async () => {
  await dispatch(postCloseTicket(ticketList[chatIndex][0].user_id));
  setOpenChat(false);
  setReloadData(true);
 };

 const handleRemoveId = (id: number) => {
  removeMessageId(id);
 };

 return !openChat ? (
  <Stack p={2} minHeight={300} sx={{ backgroundColor: "#f9f9f9" }}>
   {ticketList.map((element, index) => {
    return (
     <Stack
      key={`message-list-${index}`}
      onClick={() => {
       setOpenChat(true);
       setMessageList(element);
       setChatIndex(index);
       if (messageIdList?.includes(element[0].ticket_id)) {
        handleRemoveId(element[0].ticket_id);
       }
      }}>
      <MessageListElement
       name={
        userList.find((user) => user.userid === element[0].user_id)?.companyName ||
        "Name not available"
       }
       lastMessage={element[element.length - 1]?.message || ""}
       avatar={""}
       newMessage={messageIdList?.includes(element[0].ticket_id) || false}
       date={element[element.length - 1]?.datetime || ""}
       border={element[0].ticketOpen}
       ticketSection={true}
       creationDate={true}
      />
     </Stack>
    );
   })}
  </Stack>
 ) : (
  <Stack justifyContent="space-between">
   <Stack p={2} minHeight={300} maxHeight={400} sx={{ backgroundColor: "#f9f9f9" }}>
    <Stack direction="row" justifyContent="space-between" pb={2}>
     <Button
      startIcon={<IconSelector icon="ArrowBackIosIcon" />}
      onClick={() => setOpenChat(false)}>
      <FormattedMessage id="app.back" />
     </Button>
     {ticketList[chatIndex][0].ticketOpen && (
      <Button onClick={handleCloseTicket}>chiudi ticket</Button>
     )}
    </Stack>
    <Stack
     overflow="auto"
     spacing={2}
     sx={{ scrollbarWidth: "thin", scrollbarColor: "#0D1934 transparent" }}>
     {messageList.map((element, index) => {
      let controlledDate = false;
      if (index === 0) controlledDate = true;
      if (index > 0) {
       const date1 = new Date(element.datetime);
       const date2 = new Date(messageList[index - 1]?.datetime);

       controlledDate = date1.getUTCDate() !== date2.getUTCDate();
      }

      return (
       <Fragment key={`message-${index}`}>
        {controlledDate && <ChatDateBubble date={element.datetime || ""} />}
        <MessageBubble
         message={element?.message || ""}
         date={element?.datetime || ""}
         type={element?.type === "User" ? 2 : 1}
        />
       </Fragment>
      );
     })}
     <div ref={messagesEndRef} />
    </Stack>
   </Stack>
   <Divider />
   {messageList[0].ticketOpen && (
    <AdminReply user_id={messageList[0].user_id} reloadData={() => setReloadData(true)} />
   )}
  </Stack>
 );
};

export default AdminTicketList;
