import {
 Action,
 AnyAction,
 combineReducers,
 configureStore,
 createListenerMiddleware,
 ThunkAction
} from "@reduxjs/toolkit";

import BaremetalReducer from "redux/reducers/baremetalReducer";
import cloudboxReducer from "redux/reducers/cloudboxReducer";
import creditsReducer from "redux/reducers/creditsReducer";
import customersReducer from "redux/reducers/customersReducer";
import dnsReducer from "redux/reducers/dnsReducer";
import DockerOrdersReducer from "redux/reducers/dockerOrderReducer";
import domainsOrdersReducer from "redux/reducers/domainsOrdersReducer";
import domainsReducer from "redux/reducers/domainsReducer";
import firewallReducer from "redux/reducers/firewallReducer";
import floatingIpReducer from "redux/reducers/floatingIpReducer";
import GatewayOrdersReducer from "redux/reducers/gatewayOrderReducer";
import hostReducer from "redux/reducers/hostReducer";
import ispReducer from "redux/reducers/ispIdReducers";
import jobqueueReducer from "redux/reducers/jobqueueReducer";
import LoadBalancerDetailsReducer from "redux/reducers/loadBalancerDetailsReducer";
import LoadBalancerReducer from "redux/reducers/loadBalancerReducer";
import MailboxReducer from "redux/reducers/mailboxReducer";
import mailsReducer from "redux/reducers/mailsReducer";
import networksReducer from "redux/reducers/networksReducer";
import notificationReducer from "redux/reducers/notificationReducer";
import serverDetailsReducer from "redux/reducers/serverDetailsReducer";
import serverJobQueueReducer from "redux/reducers/serverJobQueueReducer";
import serversReducer from "redux/reducers/serversReducer";
import servicesReducer from "redux/reducers/servicesReducer";
import snapshotsReducer from "redux/reducers/snapshotsReducer";
import teamsReducer from "redux/reducers/teamsReducer";
import userReducer from "redux/reducers/userReducer";
import volumesReducer from "redux/reducers/volumesReducer";
import WebsitesDetailsReducer from "redux/reducers/websiteDetailsReducer";
import websitesReducer from "redux/reducers/websiteReducer";
import zoneReducer from "redux/reducers/zoneReducer";

import CloudbucketReducer from "./reducers/cloudbucketReducer";
import DockerWebsiteReducer from "./reducers/dockerWebsiteReducer";
import NewMessageReducer from "./reducers/newMessageReducer";
import PermissionReducer from "./reducers/permissionReducer";
import serverServicesReducer from "./reducers/serverServicesReducer";
import SessionReducer from "./reducers/sessionReducer";

const rootReducer = combineReducers({
 [userReducer.name]: userReducer.reducer,
 [notificationReducer.name]: notificationReducer.reducer,
 [serversReducer.name]: serversReducer.reducer,
 [serverDetailsReducer.name]: serverDetailsReducer.reducer,
 [customersReducer.name]: customersReducer.reducer,
 [creditsReducer.name]: creditsReducer.reducer,
 [teamsReducer.name]: teamsReducer.reducer,
 [hostReducer.name]: hostReducer.reducer,
 [floatingIpReducer.name]: floatingIpReducer.reducer,
 [volumesReducer.name]: volumesReducer.reducer,
 [snapshotsReducer.name]: snapshotsReducer.reducer,
 [domainsReducer.name]: domainsReducer.reducer,
 [mailsReducer.name]: mailsReducer.reducer,
 [websitesReducer.name]: websitesReducer.reducer,
 [WebsitesDetailsReducer.name]: WebsitesDetailsReducer.reducer,
 [cloudboxReducer.name]: cloudboxReducer.reducer,
 [zoneReducer.name]: zoneReducer.reducer,
 [ispReducer.name]: ispReducer.reducer,
 [dnsReducer.name]: dnsReducer.reducer,
 [firewallReducer.name]: firewallReducer.reducer,
 [networksReducer.name]: networksReducer.reducer,
 [jobqueueReducer.name]: jobqueueReducer.reducer,
 [servicesReducer.name]: servicesReducer.reducer,
 [domainsOrdersReducer.name]: domainsOrdersReducer.reducer,
 [serverJobQueueReducer.name]: serverJobQueueReducer.reducer,
 [BaremetalReducer.name]: BaremetalReducer.reducer,
 [MailboxReducer.name]: MailboxReducer.reducer,
 [GatewayOrdersReducer.name]: GatewayOrdersReducer.reducer,
 [LoadBalancerReducer.name]: LoadBalancerReducer.reducer,
 [LoadBalancerDetailsReducer.name]: LoadBalancerDetailsReducer.reducer,
 [DockerOrdersReducer.name]: DockerOrdersReducer.reducer,
 [DockerWebsiteReducer.name]: DockerWebsiteReducer.reducer,
 [SessionReducer.name]: SessionReducer.reducer,
 [serverServicesReducer.name]: serverServicesReducer.reducer,
 [PermissionReducer.name]: PermissionReducer.reducer,
 [CloudbucketReducer.name]: CloudbucketReducer.reducer,
 [NewMessageReducer.name]: NewMessageReducer.reducer
});

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
 actionCreator: notificationReducer.actions.addAlert,
 effect: async (action, listenerApi) => {
  console.log("todoAdded effect : TODO: Add error manager here !", action);
 }
});

export const store = (initialState: AppState) =>
 configureStore({
  reducer: (state, action: Action) => rootReducer(state, action),
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
   getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  devTools: true
 });

export type RootStore = ReturnType<typeof store>;
export type AppDispatch = RootStore["dispatch"];
export type RootState = ReturnType<RootStore["getState"]>;
export type AppAction<ReturnType = void> = ThunkAction<ReturnType, RootState, undefined, AnyAction>;
export type AppState = Partial<ReturnType<typeof rootReducer>>;
