import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

import IconSelector from "components/shared/images/IconSelector";

import ConfirmDocuments from "./actions/ConfirmDocuments";
import DownloadDocuments from "./actions/DownloadDocuments";

const AutopaymentMenu = ({
 userid,
 ridDocument,
 cfDocument,
 identityDocument,
 documentConfirmed,
 reloadData
}: {
 userid: string;
 ridDocument: string | null;
 cfDocument: string | null;
 identityDocument: string | null;
 documentConfirmed: boolean;
 reloadData: () => void;
}): ReactElement => {
 const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
 const open = Boolean(anchorEl);

 const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 const checkDisabled = (): boolean => {
  return ridDocument === null || cfDocument === null || identityDocument === null;
 };

 return (
  <>
   <Button onClick={handleOpen}>
    <IconSelector icon="MenuIcon" />
   </Button>
   <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
     "aria-labelledby": "basic-button"
    }}>
    <Stack>
     {!checkDisabled() && !documentConfirmed ? (
      <ConfirmDocuments userid={userid} closeMenu={handleClose} reloadData={reloadData} />
     ) : (
      <MenuItem disabled>
       <FormattedMessage id="configurator.autopayment.confirmDocument" />
      </MenuItem>
     )}
     {!checkDisabled() ? (
      <DownloadDocuments
       ridDocument={ridDocument || ""}
       cfDocument={cfDocument || ""}
       identityDocument={identityDocument || ""}
       closeMenu={handleClose}
      />
     ) : (
      <MenuItem disabled>
       <FormattedMessage id="configurator.autopayment.downloadDocument" />
      </MenuItem>
     )}
    </Stack>
   </Menu>
  </>
 );
};

export default AutopaymentMenu;
