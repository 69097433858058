import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import AppModal from "components/shared/modal/AppModal";

import { generatePassword } from "redux/handlers/userHandler";
import { postAddDatabase } from "redux/handlers/websitesHandler";

import { getWebsitesDetails } from "redux/selectors/websitesSelector";

import { useAppDispatch } from "hooks/reduxHook";

const AddDatabase = ({ type }: { type: string }): ReactElement => {
 const intl = useIntl();
 const websitesDetails = useSelector(getWebsitesDetails);
 const params = useParams<{ domain_id: string; section: string }>();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [showPassword, setShowPassword] = useState<string>("password");

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const { control, handleSubmit, setValue, watch, formState, reset } = useForm({
  defaultValues: {
   db_name: "",
   db_quota: 500,
   username: "",
   password: ""
  }
 });

 const onSubmit: SubmitHandler<{
  db_name: string;
  db_quota: number;
  username: string;
  password: string;
 }> = async (data: { db_name: string; db_quota: number; username: string; password: string }) => {
  setIsLoading(true);
  await dispatch(
   postAddDatabase(
    websitesDetails.domain_id,
    websitesDetails.isp_id,
    data.username,
    data.password,
    data.db_name,
    data.db_quota,
    Number(params.domain_id)
   )
  );
  setIsLoading(false);
  setOpenModal(false);
 };

 const handleGeneratePassword = async () => {
  setValue("password", await generatePassword());
 };

 const checkDisabled = (): boolean => {
  return (
   watch("db_name") === "" ||
   watch("db_quota") === 0 ||
   watch("username") === "" ||
   watch("password") === "" ||
   watch("db_name").includes("-") ||
   watch("db_name").includes(" ") ||
   watch("username").includes(" ")
  );
 };

 return (
  <>
   {type === "icon" ? (
    <Stack maxWidth={600}>
     <Tooltip title={<FormattedMessage id="sites.create.database" />}>
      <Button variant="kxActionButton" onClick={handleOpen}>
       <AddIcon />
      </Button>
     </Tooltip>
    </Stack>
   ) : (
    <Stack alignItems="center" p={2} justifyContent="center">
     <Button variant="kxActionButton" onClick={handleOpen}>
      <FormattedMessage id="sites.create.database" />
      <AddIcon />
     </Button>
    </Stack>
   )}
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    title={intl.formatMessage({ id: "sites.dbOperations" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}
    disabled={checkDisabled() || isLoading}>
    <Stack justifyContent="center" alignContent="center" direction="column">
     <Alert severity="info">
      <FormattedMessage id="sites.dbQuotaMessage" />
     </Alert>
     <Controller
      name="db_name"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "sites.dbName" })}
        error={
         watch("db_name").includes("-") ? true : formState.isDirty && !!formState?.errors?.db_name
        }
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        helperText={
         watch("db_name").includes("-")
          ? intl.formatMessage({ id: "sites.dbErrorLine" })
          : formState.isDirty &&
            !!formState?.errors?.db_name &&
            intl.formatMessage({ id: "sites.dbNameError" })
        }
       />
      )}
     />
     <Controller
      name="db_quota"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "sites.dbQuota" })}
        error={formState.isDirty && !!formState?.errors?.db_quota}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="off"
        type="number"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.db_quota &&
         intl.formatMessage({ id: "sites.dbQuotaError" })
        }
       />
      )}
     />
     <Alert severity="info">
      <FormattedMessage id="sites.dbUserMessage" />
     </Alert>
     <Controller
      name="username"
      control={control}
      rules={{
       required: true
      }}
      render={({ field }) => (
       <TextField
        {...field}
        fullWidth={true}
        label={intl.formatMessage({ id: "sites.ftp.username" })}
        error={formState.isDirty && !!formState?.errors?.username}
        InputLabelProps={{ shrink: true }}
        sx={{ my: 2 }}
        autoComplete="new-password"
        helperText={
         formState.isDirty &&
         !!formState?.errors?.username &&
         intl.formatMessage({ id: "sites.ftp.usernameError" })
        }
       />
      )}
     />
     <Stack direction="row" spacing={2}>
      <Controller
       name="password"
       control={control}
       rules={{
        required: true,
        minLength: 8
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={intl.formatMessage({ id: "sites.ftp.password" })}
         error={formState.isDirty && !!formState?.errors?.password}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
         type={showPassword}
         autoComplete="new-password"
         helperText={
          formState.isDirty &&
          !!formState?.errors?.password &&
          intl.formatMessage({ id: "sites.ftp.passwordError" })
         }
         InputProps={{
          endAdornment: (
           <InputAdornment position="end">
            <IconButton
             aria-label="toggle password visibility"
             onClick={() => {
              setShowPassword(showPassword === "text" ? "password" : "text");
             }}
             edge="end">
             {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
           </InputAdornment>
          )
         }}
        />
       )}
      />
      <Button variant="kxActionButton" onClick={handleGeneratePassword} sx={{ width: "40%" }}>
       <FormattedMessage id="app.generatePassword" />
      </Button>
     </Stack>
    </Stack>
   </AppModal>
  </>
 );
};

export default AddDatabase;
