import React, { ReactElement, useEffect, useState } from "react";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import TeamsUserSection from "components/profile/teams/TeamsUserSection";
import SkeletonProfile from "components/shared/skeletons/SkeletonProfile";

import { getFullListSecondLevelUsers } from "redux/handlers/teamsHandler";

import { useAppDispatch } from "hooks/reduxHook";

const TeamsUserPage = (): ReactElement => {
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const dispatch = useAppDispatch();

 useEffect(() => {
  (async () => {
   await dispatch(getFullListSecondLevelUsers());
   setIsLoading(false);
  })();
 }, []);

 return (
  <Container maxWidth="xl">
   <Stack>{isLoading ? <SkeletonProfile id="profile.teams" /> : <TeamsUserSection />}</Stack>
  </Container>
 );
};

export default TeamsUserPage;
