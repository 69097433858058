import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IZoneApi, IZoneDataset } from "types/api/appsApiInterface";

const initialState: IZoneApi = { zones: [], length: 0 };

const ZoneReducer = createSlice({
 name: "zone",
 initialState,
 reducers: {
  setZone: (state, { payload }: PayloadAction<IZoneApi>) => {
   return payload;
  },
  resetZone: () => {
   return initialState;
  },
  setZoneDetails: (state, { payload }: PayloadAction<IZoneDataset>) => {
   return {
    zones: [payload],
    length: 1
   };
  }
 }
});

export default ZoneReducer;
