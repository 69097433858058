import React, { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import { isNil } from "ramda";

import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { IAllAddonsAPI, IAllAddonsDataset } from "types/api/addonsApiInterface";
import { addonsSectionType, addonsTeamsPermissionType } from "types/global/teams";
import { addonsPermissionType, addonsSelectedList } from "types/global/user";

const TeamsSnapshot = ({
 control,
 addons,
 selectedAddons,
 addonsPermissions,
 setAddons,
 addAddons,
 setPermission
}: {
 control: any;
 addons: IAllAddonsAPI | null;
 selectedAddons: addonsSelectedList;
 addonsPermissions: addonsPermissionType;
 setAddons: (section: addonsSectionType, value: Array<number>) => void;
 addAddons: (section: addonsSectionType, value: Array<{ id: number; label: string }>) => void;
 setPermission: (section: addonsTeamsPermissionType, value: boolean) => void;
}): ReactElement => {
 const intl = useIntl();

 return (
  <Stack spacing={2}>
   <Controller
    name="snapshots"
    control={control}
    render={({ field }) => (
     <Autocomplete
      {...field}
      multiple
      id="tags-outlined"
      options={
       addons?.snapshots
        ? addons?.snapshots?.map((element: IAllAddonsDataset) => {
           return {
            id: element.id,
            label: element.name
           };
          })
        : []
      }
      filterSelectedOptions
      value={addons?.snapshots
       .filter((element) => selectedAddons.snapshots.includes(element.id))
       .map((element) => {
        return {
         id: element.id,
         label: element.name
        };
       })}
      getOptionLabel={(option) => option.label}
      onChange={(e, value) => {
       isNil(value)
        ? setAddons("snapshots", selectedAddons.snapshots)
        : addAddons("snapshots", value);
      }}
      renderInput={(params) => (
       <TextField
        {...params}
        label={intl.formatMessage({ id: "teams.usersettings.selectSnapshots" })}
        placeholder="Snapshot..."
       />
      )}
     />
    )}
   />
   <Stack direction={"row"}>
    <Controller
     name="showSnapshot"
     control={control}
     render={({ field }) => (
      <FormControlLabel
       control={
        <Checkbox
         {...field}
         checked={addonsPermissions.showSnapshot}
         onChange={(e, checked) => {
          setPermission("showSnapshot", checked);
         }}
        />
       }
       label={intl.formatMessage({ id: "teams.usersettings.showSnapshot" })}
      />
     )}
    />
    <Controller
     name="editSnapshot"
     control={control}
     render={({ field }) => (
      <FormControlLabel
       control={
        <Checkbox
         {...field}
         checked={addonsPermissions.editSnapshot}
         onChange={(e, checked) => {
          setPermission("editSnapshot", checked);
         }}
        />
       }
       label={intl.formatMessage({ id: "teams.usersettings.editSnapshot" })}
      />
     )}
    />
   </Stack>
  </Stack>
 );
};

export default TeamsSnapshot;
