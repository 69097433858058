import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { getAllUserMessages, postCheckOpenTicket } from "redux/handlers/chatHandler";

import { getUserIdString } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IMessage } from "types/api/chatApiInterface";

import UserReply from "../actions/UserReply";
import MessageBubble from "../components/MessageBubble";

const UserSendAMessage = (): ReactElement => {
 const dispatch = useAppDispatch();
 const messagesEndRef = useRef<HTMLDivElement>(null);

 const userId = useSelector(getUserIdString);
 const { socket } = useContext(AppContext);

 const [allMessages, setAllMessages] = useState<Array<IMessage>>([]);
 const [reloadData, setReloadData] = useState<boolean>(true);
 const [ticketOpen, setTicketOpen] = useState<boolean>(false);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    setAllMessages(await dispatch(getAllUserMessages(userId)));
    setTicketOpen(await dispatch(postCheckOpenTicket(userId)));
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 useEffect(() => {
  scrollToBottom();
 }, [allMessages]);

 const scrollToBottom = () => {
  messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
 };

 useEffect(() => {
  !isNil(socket) &&
   socket.on("chat_messages", (value): void => {
    if (value?.updated) {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("chat_messages");
  };
 }, [socket]);

 return (
  <Stack justifyContent="space-between">
   <Stack p={2} minHeight={300} maxHeight={400} sx={{ backgroundColor: "#f9f9f9" }}>
    {allMessages.length > 0 ? (
     <Stack
      overflow="auto"
      spacing={2}
      sx={{ scrollbarWidth: "thin", scrollbarColor: "#0D1934 transparent" }}>
      {allMessages.map((element, index) => {
       return (
        <MessageBubble
         message={element?.message || ""}
         date={element?.datetime || ""}
         type={element?.type === "User" ? 1 : 2}
         key={`message-${index}`}
        />
       );
      })}
      <div ref={messagesEndRef} />
     </Stack>
    ) : (
     <Stack justifyContent="center" alignItems="center" height={300}>
      <Stack direction="row" justifyContent="center">
       <IconSelector
        icon="SpeakerNotesIcon"
        props={{ sx: { color: "lightgrey", fontSize: 100 } }}
       />
      </Stack>
      <Typography variant="h6">
       <FormattedMessage id="chat.noMessageSent" />
      </Typography>
     </Stack>
    )}
   </Stack>
   <Divider />
   {ticketOpen ? (
    <Typography textAlign="left" px={1} variant="subtitle2">
     <FormattedMessage id="chat.ticketElaborationMessage" />
    </Typography>
   ) : (
    <UserReply reloadData={() => setReloadData(true)} />
   )}
  </Stack>
 );
};

export default UserSendAMessage;
