import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import ChangePassword from "components/profile/actions/ChangePassword";

import { resetAction } from "redux/handlers/userHandler";

import { getEmail } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const ChangePasswordPage = (): ReactElement => {
 const dispatch = useAppDispatch();

 const email = useSelector(getEmail);

 const [recoverLoading, setRecoverLoading] = useState<boolean>(false);

 const sendRecoverMail = async () => {
  setRecoverLoading(true);
  const status = await dispatch(resetAction(email));
  if (status === 200) {
   setRecoverLoading(false);
  }
 };

 return (
  <Stack p={2}>
   <Typography textAlign="left" variant="h6">
    <FormattedMessage id="profile.security.password" />
   </Typography>
   <Divider />
   <Stack spacing={1} pt={1}>
    <Typography textAlign="left" variant="subtitle2">
     <FormattedMessage id="profile.security.passwordMessage" />
    </Typography>
    <Stack direction="row">
     <ChangePassword />
    </Stack>
    {recoverLoading ? (
     <CircularProgress />
    ) : (
     <Stack direction="row" spacing={1}>
      <Typography>
       <FormattedMessage id="login.forgottenPassword" />
      </Typography>
      <Link variant="body2" onClick={sendRecoverMail}>
       <Typography sx={{ cursor: "pointer" }}>
        <FormattedMessage id="login.sendLink" />
       </Typography>
      </Link>
     </Stack>
    )}
   </Stack>
  </Stack>
 );
};

export default ChangePasswordPage;
