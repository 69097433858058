import React, { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import { isNil } from "ramda";

import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { IAllAddonsAPI, IAllAddonsDataset } from "types/api/addonsApiInterface";
import { addonsSectionType, addonsTeamsPermissionType } from "types/global/teams";
import { addonsPermissionType, addonsSelectedList } from "types/global/user";

const TeamsVolumes = ({
 control,
 addons,
 selectedAddons,
 addonsPermissions,
 setAddons,
 addAddons,
 setPermission
}: {
 control: any;
 addons: IAllAddonsAPI | null;
 selectedAddons: addonsSelectedList;
 addonsPermissions: addonsPermissionType;
 setAddons: (section: addonsSectionType, value: Array<number>) => void;
 addAddons: (section: addonsSectionType, value: Array<{ id: number; label: string }>) => void;
 setPermission: (section: addonsTeamsPermissionType, value: boolean) => void;
}): ReactElement => {
 const intl = useIntl();

 return (
  <Stack spacing={2}>
   <Controller
    name="volumes"
    control={control}
    render={({ field }) => (
     <Autocomplete
      {...field}
      multiple
      id="tags-outlined"
      options={
       addons?.volumes
        ? addons?.volumes?.map((element: IAllAddonsDataset) => {
           return {
            id: element.id,
            label: element.name
           };
          })
        : []
      }
      filterSelectedOptions
      value={addons?.volumes
       .filter((element) => selectedAddons.volumes.includes(element.id))
       .map((element) => {
        return {
         id: element.id,
         label: element.name
        };
       })}
      getOptionLabel={(option) => option.label}
      onChange={(e, value) => {
       isNil(value) ? setAddons("volumes", selectedAddons.volumes) : addAddons("volumes", value);
      }}
      renderInput={(params) => (
       <TextField
        {...params}
        label={intl.formatMessage({ id: "teams.usersettings.selectVolumes" })}
        placeholder="Volume..."
       />
      )}
     />
    )}
   />
   <Stack direction="row">
    <Stack>
     <Controller
      name="showVolume"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showVolume}
          onChange={(e, checked) => {
           setPermission("showVolume", checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showVolume" })}
       />
      )}
     />
     <Controller
      name="editVolume"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.editVolume}
          onChange={(e, checked) => {
           setPermission("editVolume", checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.editVolume" })}
       />
      )}
     />
     <Controller
      name="editVolumeServer"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.editVolumeServer}
          onChange={(e, checked) => {
           setPermission("editVolumeServer", checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.editVolumeServer" })}
       />
      )}
     />
     <Controller
      name="editVolumeMount"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.editVolumeMount}
          onChange={(e, checked) => {
           setPermission("editVolumeMount", checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.editVolumeMount" })}
       />
      )}
     />
    </Stack>
   </Stack>
  </Stack>
 );
};

export default TeamsVolumes;
