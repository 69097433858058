import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";

import { getTeamsDataSet } from "redux/selectors/teamsSelector";

import UpdateUserSettings from "./actions/UpdateUserSettings";

const TeamsUserSection = (): ReactElement => {
 const teamsList = useSelector(getTeamsDataSet);

 const [selectedUser, setSelectedUser] = useState<number>(0);

 return (
  <Stack>
   <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0 }}>
    <Stack direction="row" alignItems="center" pb={2}>
     <FormControl>
      <InputLabel>
       <FormattedMessage id="teams.usersettings.teamsUsername" />
      </InputLabel>
      <Select
       value={selectedUser}
       label={<FormattedMessage id="teams.usersettings.teamsUsername" />}
       onChange={(e) => setSelectedUser(e.target.value as number)}>
       {teamsList.length !== 0 && <MenuItem value={0}>No user selected</MenuItem>}
       {teamsList.map((element, index) => {
        return (
         <MenuItem key={`${element.email}-user-${index}`} value={element.id}>
          {element.email}
         </MenuItem>
        );
       })}
       {teamsList.length === 0 && <MenuItem value={0}>No user available</MenuItem>}
      </Select>
     </FormControl>
    </Stack>
    {selectedUser !== 0 && <UpdateUserSettings id={selectedUser} />}
   </Paper>
  </Stack>
 );
};

export default TeamsUserSection;
