import React, { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import { isNil } from "ramda";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { ISimpleDomainData } from "types/api/domainsApiInterface";
import { domainPermissionsType } from "types/global/user";

type sectionType =
 | "showAuthinfo"
 | "showOwners"
 | "showNs"
 | "showDns"
 | "editAuthinfo"
 | "editNs"
 | "editDns";

const DomainsSection = ({
 control,
 domains,
 selectedDomains,
 domainPermissions,
 setDomainsList,
 addDomain,
 setPermission
}: {
 control: any;
 domains: Array<ISimpleDomainData>;
 selectedDomains: Array<number>;
 domainPermissions: domainPermissionsType;
 setDomainsList: (domains: Array<number>) => void;
 addDomain: (domain: Array<{ id: number; label: string }>) => void;
 setPermission: (section: sectionType, value: boolean) => void;
}): ReactElement => {
 const intl = useIntl();

 return (
  <Accordion>
   <AccordionSummary expandIcon={<ExpandMoreIcon />}>Domains</AccordionSummary>
   <AccordionDetails>
    <Stack spacing={2}>
     <Controller
      name="domains"
      control={control}
      render={({ field }) => (
       <Autocomplete
        {...field}
        multiple
        id="tags-outlined"
        options={domains.map((element) => {
         return {
          id: element.id,
          label: element.domain
         };
        })}
        filterSelectedOptions
        value={domains
         .filter((element) => selectedDomains.includes(element.id))
         .map((element) => {
          return {
           id: element.id,
           label: element.domain
          };
         })}
        getOptionLabel={(option) => option.label}
        onChange={(e, value, reason) => {
         isNil(value) ? setDomainsList(selectedDomains) : addDomain(value);
        }}
        renderInput={(params) => (
         <TextField
          {...params}
          label={intl.formatMessage({ id: "teams.usersettings.selectDomains" })}
          placeholder="Domain..."
         />
        )}
       />
      )}
     />
     <Stack direction="row">
      <Stack>
       <Controller
        name="showAuthinfo"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={domainPermissions.showAuthinfo}
            onChange={(e, checked) => {
             setPermission("showAuthinfo", checked);
             if (!checked) setPermission("editAuthinfo", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showAuthInfo" })}
         />
        )}
       />
       <Controller
        name="showDns"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={domainPermissions.showDns}
            onChange={(e, checked) => {
             setPermission("showDns", checked);
             if (!checked) setPermission("editDns", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showDns" })}
         />
        )}
       />
       <Controller
        name="showOwners"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={domainPermissions.showOwners}
            onChange={(e, checked) => {
             setPermission("showOwners", checked);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showOwners" })}
         />
        )}
       />
       <Controller
        name="showNs"
        control={control}
        render={({ field }) => (
         <FormControlLabel
          control={
           <Checkbox
            {...field}
            checked={domainPermissions.showNs}
            onChange={(e, checked) => {
             setPermission("showNs", checked);
             if (!checked) setPermission("editNs", false);
            }}
           />
          }
          label={intl.formatMessage({ id: "teams.usersettings.showNs" })}
         />
        )}
       />
      </Stack>
      <Stack>
       {domainPermissions.showAuthinfo && (
        <Controller
         name="editAuthinfo"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={domainPermissions.editAuthinfo}
             onChange={(e, checked) => {
              setPermission("editAuthinfo", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editAuthinfo" })}
          />
         )}
        />
       )}
       {domainPermissions.showDns && (
        <Controller
         name="editDns"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={domainPermissions.editDns}
             onChange={(e, checked) => {
              setPermission("editDns", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editDns" })}
          />
         )}
        />
       )}
       {domainPermissions.showNs && (
        <Controller
         name="editNs"
         control={control}
         render={({ field }) => (
          <FormControlLabel
           control={
            <Checkbox
             {...field}
             checked={domainPermissions.editNs}
             onChange={(e, checked) => {
              setPermission("editNs", checked);
             }}
            />
           }
           label={intl.formatMessage({ id: "teams.usersettings.editNs" })}
          />
         )}
        />
       )}
      </Stack>
     </Stack>
    </Stack>
   </AccordionDetails>
  </Accordion>
 );
};

export default DomainsSection;
