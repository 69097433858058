import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";
import AppModal from "components/shared/modal/AppModal";

import { postUploadRidDocument } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

import FileUploader from "../sections/components/FileUploader";

const UploadDocument = ({ id }: { id: number }): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [openModal, setOpenModal] = useState<boolean>(false);
 const [identityFile, setIdentityFile] = useState<string>("");
 const [cfFile, setCfFile] = useState<string>("");
 const [sepaFile, setSepaFile] = useState<string>("");
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => setOpenModal(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postUploadRidDocument(id, identityFile, cfFile, sepaFile));
  setLoading(false);
 };

 const checkDisabled = () => {
  return identityFile === "" || cfFile === "" || sepaFile === "";
 };

 const reset = () => {
  setIdentityFile("");
  setCfFile("");
  setSepaFile("");
 };

 //insert changing logo when user upload file, check for existing file, insert new message when a file is uploaded

 return (
  <>
   <Tooltip title={<FormattedMessage id="profile.security.uploadCompileDocument" />}>
    <IconButton onClick={handleOpen}>
     <IconSelector icon="FileUploadIcon" />
    </IconButton>
   </Tooltip>
   <AppModal
    open={openModal}
    close={handleClose}
    handleClose={handleClose}
    handleConfirm={handleConfirm}
    loading={loading}
    disabled={loading || checkDisabled()}
    title={intl.formatMessage({ id: "profile.security.uploadCompileDocument" })}>
    <Stack spacing={2}>
     <Alert severity="info">
      <Typography>
       <FormattedMessage id="profile.security.howToUploadFile" />
      </Typography>
     </Alert>
     <FileUploader
      description="profile.security.identityFileUpload"
      setFile={(file) => setIdentityFile(file)}
     />
     <FileUploader
      description="profile.security.cfFileUpload"
      setFile={(file) => setCfFile(file)}
     />
     <FileUploader
      description="profile.security.sepaFileUpload"
      setFile={(file) => setSepaFile(file)}
     />
    </Stack>
   </AppModal>
  </>
 );
};

export default UploadDocument;
