import React, { ReactElement, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";

import { getAllAddons } from "redux/handlers/addonsHandle";
import { getAllDomainsNotPaged } from "redux/handlers/domainsHandler";
import { getAllMailsNotPaged } from "redux/handlers/mailsHandler";
import { getAllServersNotPaged } from "redux/handlers/serverHandler";
import { getUserSettings, postChangeUserSettings } from "redux/handlers/teamsHandler";
import { getAllWebsitesNotPaged } from "redux/handlers/websitesHandler";
import { getAllZoneNotPaged } from "redux/handlers/zoneHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IAllAddonsAPI } from "types/api/addonsApiInterface";
import { IZoneList } from "types/api/appsApiInterface";
import { ISimpleDomainData } from "types/api/domainsApiInterface";
import { IMailNotPagedData } from "types/api/mailApiInterface";
import { IAllServers } from "types/api/serversApiInterface";
import { IAllWebsiteData } from "types/api/websitesApiInterface";
import { settingsType } from "types/global/user";

import AddonsSection from "./settingsSections/AddonsSection";
import AppsSection from "./settingsSections/AppsSection";
import DomainsSection from "./settingsSections/DomainsSections";
import ServersSection from "./settingsSections/ServersSection";

type sectionsType =
 | "cloudboxes"
 | "volumes"
 | "load_balancers"
 | "floating_ips"
 | "snapshots"
 | "networks"
 | "firewalls"
 | "domains"
 | "servers"
 | "sites"
 | "mails"
 | "zones"
 | "cloudbuckets";

const UpdateUserSettings = ({ id }: { id: number }): ReactElement => {
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [loadingData, setLoadingData] = useState<boolean>(false);
 const [domains, setDomains] = useState<Array<ISimpleDomainData>>([]);
 const [servers, setServers] = useState<Array<IAllServers>>([]);
 const [sites, setSites] = useState<Array<IAllWebsiteData>>([]);
 const [mails, setMails] = useState<Array<IMailNotPagedData>>([]);
 const [zone, setZone] = useState<Array<IZoneList>>([]);
 const [addons, setAddons] = useState<IAllAddonsAPI | null>(null);

 const { control, handleSubmit, setValue, watch, reset } = useForm({
  defaultValues: {
   domains: [],
   servers: [],
   sites: [],
   mails: [],
   zones: [],
   cloudboxes: [],
   volumes: [],
   floating_ips: [],
   load_balancers: [],
   networks: [],
   snapshots: [],
   firewalls: [],
   cloudbuckets: [],
   showAuthinfo: false,
   showDns: false,
   showOwners: false,
   showNs: false,
   editAuthinfo: false,
   editDns: false,
   editNs: false,
   editRebootServer: false,
   editPowerOffOnServer: false,
   showBackupServer: false,
   editBackupServer: false,
   showRebuildServer: false,
   editRebuildServer: false,
   editVncServer: false,
   editIsoServer: false,
   showCredentialsServer: false,
   editCredentialsServer: false,
   showIpRdnsServer: false,
   editIpRdnsServer: false,
   showServicesServer: false,
   editServicesServer: false,
   editRebootAgent: false,
   showServerStatistics: false,
   editPowerOffOnSite: false,
   editPowerOffOnMail: false,
   editPowerOffOnZone: false,
   showZoneRecords: false,
   editZoneRecords: false,
   showMailDetails: false,
   editMailDetails: false,
   showMailbox: false,
   editMailbox: false,
   showAutomaticMail: false,
   editAutomaticMail: false,
   showWhiteBlackMailList: false,
   editWhiteBlackMailList: false,
   showSiteDetails: false,
   editSiteDetails: false,
   showSiteFtpCredentials: false,
   editSiteFtpCredentials: false,
   showSiteAlias: false,
   editSiteAlias: false,
   showSiteDatabase: false,
   editSiteDatabase: false,
   showSiteBackups: false,
   editSiteBackups: false,
   showSiteCronjobs: false,
   editSiteCronjobs: false,
   showCloudbox: false,
   showFirewall: false,
   showFloatingIp: false,
   showNetwork: false,
   showVolume: false,
   showLoadBalancer: false,
   editFloatingIp: false,
   editFloatingIpRdns: false,
   editFloatingIpServer: false,
   editVolume: false,
   editVolumeMount: false,
   editVolumeServer: false,
   editLoadBalancer: false,
   showCloudboxCommands: false,
   showCloudboxDetails: false,
   editCloudboxDetails: false,
   showCloudboxSettings: false,
   editCloudboxSettings: false,
   showCloudboxServer: false,
   editCloudboxServer: false,
   showCloudboxSnapshot: false,
   editCloudboxSnapshot: false,
   showNetworkDetails: false,
   editNetworkDetails: false,
   showNetworkSubnet: false,
   editNetworkSubnet: false,
   showNetworkRoute: false,
   editNetworkRoute: false,
   showFirewallDetails: false,
   editFirewallDetails: false,
   showFirewallServer: false,
   editFirewallServer: false,
   showFirewallInboundRules: false,
   editFirewallInboundRules: false,
   showFirewallOutboundRules: false,
   editFirewallOutboundRules: false,
   showLoadBalancerDetails: false,
   editLoadBalancerDetails: false,
   showLoadBalancerGraph: false,
   showLoadBalancerCertificate: false,
   editLoadBalancerCertificate: false,
   showLoadBalancerService: false,
   showLoadBalancerTarget: false,
   showLoadBalancerNetwork: false,
   editLoadBalancerNetwork: false,
   showSnapshot: false,
   editSnapshot: false,
   showCloudbuckets: false,
   editConnectCloudbucket: false
  } as settingsType
 });

 const onSubmit: SubmitHandler<settingsType> = async (data: settingsType) => {
  setIsLoading(true);
  await dispatch(postChangeUserSettings(id, data));
  setIsLoading(false);
 };

 useEffect(() => {
  (async () => {
   reset();
   setLoadingData(true);
   const preferences = await dispatch(getUserSettings(id));
   if (preferences) {
    setValue("domains", preferences.domains);
    setValue("servers", preferences.servers);
    setValue("sites", preferences.sites);
    setValue("mails", preferences.mails);
    setValue("zones", preferences.zones);
    setValue("showAuthinfo", preferences.showAuthinfo);
    setValue("showDns", preferences.showDns);
    setValue("showOwners", preferences.showOwners);
    setValue("showNs", preferences.showNs);
    setValue("editAuthinfo", preferences.editAuthinfo);
    setValue("editDns", preferences.editDns);
    setValue("editNs", preferences.editNs);
    setValue("editRebootServer", preferences.editRebootServer);
    setValue("editPowerOffOnServer", preferences.editPowerOffOnServer);
    setValue("editBackupServer", preferences.editBackupServer);
    setValue("showBackupServer", preferences.showBackupServer);
    setValue("editRebuildServer", preferences.editRebuildServer);
    setValue("editPowerOffOnSite", preferences.editPowerOffOnSite);
    setValue("editPowerOffOnMail", preferences.editPowerOffOnMail);
    setValue("editPowerOffOnZone", preferences.editPowerOffOnZone);
    setValue("showZoneRecords", preferences.showZoneRecords);
    setValue("editZoneRecords", preferences.editZoneRecords);
    setValue("cloudboxes", preferences.cloudboxes);
    setValue("volumes", preferences.volumes);
    setValue("networks", preferences.networks);
    setValue("firewalls", preferences.firewalls);
    setValue("snapshots", preferences.snapshots);
    setValue("cloudbuckets", preferences.buckets);
    setValue("load_balancers", preferences.load_balancers);
    setValue("showMailDetails", preferences.showMailDetails);
    setValue("editMailDetails", preferences.editMailDetails);
    setValue("showMailbox", preferences.showMailbox);
    setValue("editMailbox", preferences.editMailbox);
    setValue("showAutomaticMail", preferences.showAutomaticMail);
    setValue("editAutomaticMail", preferences.editAutomaticMail);
    setValue("floating_ips", preferences.floating_ips);
    setValue("showWhiteBlackMailList", preferences.showWhiteBlackMailList);
    setValue("editWhiteBlackMailList", preferences.editWhiteBlackMailList);
    setValue("showSiteDetails", preferences.showSiteDetails);
    setValue("editSiteDetails", preferences.editSiteDetails);
    setValue("showSiteFtpCredentials", preferences.showSiteFtpCredentials);
    setValue("editSiteFtpCredentials", preferences.editSiteFtpCredentials);
    setValue("showSiteAlias", preferences.showSiteAlias);
    setValue("editSiteAlias", preferences.editSiteAlias);
    setValue("showSiteDatabase", preferences.showSiteDatabase);
    setValue("editSiteDatabase", preferences.editSiteDatabase);
    setValue("showSiteBackups", preferences.showSiteBackups);
    setValue("editSiteBackups", preferences.editSiteBackups);
    setValue("showSiteCronjobs", preferences.showSiteCronjobs);
    setValue("editSiteCronjobs", preferences.editSiteCronjobs);
    setValue("showCloudbox", preferences.showCloudbox);
    setValue("showFirewall", preferences.showFirewall);
    setValue("showFloatingIp", preferences.showFloatingIp);
    setValue("showNetwork", preferences.showNetwork);
    setValue("showVolume", preferences.showVolume);
    setValue("showLoadBalancer", preferences.showLoadBalancer);
    setValue("editFloatingIp", preferences.editFloatingIp);
    setValue("editFloatingIpRdns", preferences.editFloatingIpRdns);
    setValue("editFloatingIpServer", preferences.editFloatingIpServer);
    setValue("editVolume", preferences.editVolume);
    setValue("editVolumeMount", preferences.editVolumeMount);
    setValue("editVolumeServer", preferences.editVolumeServer);
    setValue("editLoadBalancer", preferences.editLoadBalancer);
    setValue("showCloudboxCommands", preferences.showCloudboxCommands);
    setValue("showCloudboxDetails", preferences.showCloudboxDetails);
    setValue("editCloudboxDetails", preferences.editCloudboxDetails);
    setValue("showCloudboxSettings", preferences.showCloudboxSettings);
    setValue("editCloudboxSettings", preferences.editCloudboxSettings);
    setValue("showCloudboxServer", preferences.showCloudboxServer);
    setValue("editCloudboxServer", preferences.editCloudboxServer);
    setValue("showCloudboxSnapshot", preferences.showCloudboxSnapshot);
    setValue("editCloudboxSnapshot", preferences.editCloudboxSnapshot);
    setValue("showNetworkDetails", preferences.showNetworkDetails);
    setValue("editNetworkDetails", preferences.editNetworkDetails);
    setValue("showNetworkSubnet", preferences.showNetworkSubnet);
    setValue("editNetworkSubnet", preferences.editNetworkSubnet);
    setValue("showNetworkRoute", preferences.showNetworkRoute);
    setValue("editNetworkRoute", preferences.editNetworkRoute);
    setValue("showFirewallDetails", preferences.showFirewallDetails);
    setValue("editFirewallDetails", preferences.editFirewallDetails);
    setValue("showFirewallServer", preferences.showFirewallServer);
    setValue("editFirewallServer", preferences.editFirewallServer);
    setValue("showFirewallInboundRules", preferences.showFirewallInboundRules);
    setValue("editFirewallInboundRules", preferences.editFirewallInboundRules);
    setValue("showFirewallOutboundRules", preferences.showFirewallOutboundRules);
    setValue("editFirewallOutboundRules", preferences.editFirewallOutboundRules);
    setValue("showLoadBalancerDetails", preferences.showLoadBalancerDetails);
    setValue("editLoadBalancerDetails", preferences.editLoadBalancerDetails);
    setValue("showLoadBalancerGraph", preferences.showLoadBalancerGraph);
    setValue("showLoadBalancerCertificate", preferences.showLoadBalancerCertificate);
    setValue("editLoadBalancerCertificate", preferences.editLoadBalancerCertificate);
    setValue("showLoadBalancerService", preferences.showLoadBalancerService);
    setValue("showLoadBalancerTarget", preferences.showLoadBalancerTarget);
    setValue("showLoadBalancerNetwork", preferences.showLoadBalancerNetwork);
    setValue("editLoadBalancerNetwork", preferences.editLoadBalancerNetwork);
    setValue("showSnapshot", preferences.showSnapshot);
    setValue("editSnapshot", preferences.editSnapshot);
    setValue("showCloudbuckets", preferences.showCloudbuckets);
    setValue("editConnectCloudbucket", preferences.editConnectCloudbucket);
    setValue("showRebuildServer", preferences.showRebuildServer);
    setValue("editVncServer", preferences.editVncServer);
    setValue("showCredentialsServer", preferences.showCredentialsServer);
    setValue("editCredentialsServer", preferences.editCredentialsServer);
    setValue("showIpRdnsServer", preferences.showIpRdnsServer);
    setValue("editIpRdnsServer", preferences.editIpRdnsServer);
    setValue("showServerStatistics", preferences.showServerStatistics);
    setValue("editRebootAgent", preferences.editRebootAgent);
    setValue("showServicesServer", preferences.showServicesServer);
    setValue("editServicesServer", preferences.editServicesServer);
    setValue("editIsoServer", preferences.editIsoServer);
   }
   setDomains(await dispatch(getAllDomainsNotPaged()));
   setServers(await dispatch(getAllServersNotPaged()));
   setAddons(await dispatch(getAllAddons()));
   setSites(await dispatch(getAllWebsitesNotPaged()));
   setMails(await dispatch(getAllMailsNotPaged()));
   setZone(await dispatch(getAllZoneNotPaged()));
   setLoadingData(false);
  })();
 }, []);

 const checkDisabled = (): boolean => {
  return watch("domains").length === 0 && watch("servers").length === 0;
 };

 const handleControlSelection = (
  section: sectionsType,
  addin: Array<{ id: number; label: string }>
 ) => {
  const addins = addin.map((element) => {
   return element.id;
  });
  setValue(section, addins);
 };

 const handleSetApp = (section: string, value: Array<number>) => {
  switch (section) {
   case "sites":
    setValue("sites", value);
    break;
   case "mails":
    setValue("mails", value);
    break;
   case "zones":
    setValue("zones", value);
    break;
   default:
    setValue("sites", value);
    break;
  }
 };

 return loadingData ? (
  <CircularProgress />
 ) : (
  <>
   <form onSubmit={handleSubmit(onSubmit)}>
    <Alert severity="info" sx={{ mb: 2, textAlign: "left" }}>
     <FormattedMessage id="teams.usersettings.explainations" />
    </Alert>
    <Stack>
     <DomainsSection
      control={control}
      domains={domains}
      selectedDomains={watch("domains")}
      domainPermissions={{
       showAuthinfo: watch("showAuthinfo"),
       showDns: watch("showDns"),
       showOwners: watch("showOwners"),
       showNs: watch("showNs"),
       editAuthinfo: watch("editAuthinfo"),
       editDns: watch("editDns"),
       editNs: watch("editNs")
      }}
      setDomainsList={(domains) => setValue("domains", domains)}
      addDomain={(domain) => handleControlSelection("domains", domain)}
      setPermission={(section, value) => setValue(section, value)}
     />
     <ServersSection
      control={control}
      servers={servers}
      selectedServers={watch("servers")}
      serverPermissions={{
       editRebootServer: watch("editRebootServer"),
       editPowerOffOnServer: watch("editPowerOffOnServer"),
       showBackupServer: watch("showBackupServer"),
       editBackupServer: watch("editBackupServer"),
       showRebuildServer: watch("showRebuildServer"),
       editRebuildServer: watch("editRebuildServer"),
       editVncServer: watch("editVncServer"),
       editIsoServer: watch("editIsoServer"),
       showCredentialsServer: watch("showCredentialsServer"),
       editCredentialsServer: watch("editCredentialsServer"),
       showIpRdnsServer: watch("showIpRdnsServer"),
       editIpRdnsServer: watch("editIpRdnsServer"),
       showServicesServer: watch("showServicesServer"),
       editServicesServer: watch("editServicesServer"),
       editRebootAgent: watch("editRebootAgent"),
       showServerStatistics: watch("showServerStatistics")
      }}
      setServers={(servers) => setValue("servers", servers)}
      addServer={(server) => handleControlSelection("servers", server)}
      setPermission={(section, value) => setValue(section, value)}
     />
     <AppsSection
      control={control}
      sites={sites}
      mails={mails}
      zone={zone}
      selectedSites={watch("sites")}
      selectedMails={watch("mails")}
      selectedZone={watch("zones")}
      appsPermissions={{
       editPowerOffOnSite: watch("editPowerOffOnSite"),
       editPowerOffOnMail: watch("editPowerOffOnMail"),
       editPowerOffOnZone: watch("editPowerOffOnZone"),
       showZoneRecords: watch("showZoneRecords"),
       editZoneRecords: watch("editZoneRecords"),
       showMailDetails: watch("showMailDetails"),
       editMailDetails: watch("editMailDetails"),
       showMailbox: watch("showMailbox"),
       editMailbox: watch("editMailbox"),
       showAutomaticMail: watch("showAutomaticMail"),
       editAutomaticMail: watch("editAutomaticMail"),
       showWhiteBlackMailList: watch("showWhiteBlackMailList"),
       editWhiteBlackMailList: watch("editWhiteBlackMailList"),
       showSiteDetails: watch("showSiteDetails"),
       editSiteDetails: watch("editSiteDetails"),
       showSiteFtpCredentials: watch("showSiteFtpCredentials"),
       editSiteFtpCredentials: watch("editSiteFtpCredentials"),
       showSiteAlias: watch("showSiteAlias"),
       editSiteAlias: watch("editSiteAlias"),
       showSiteDatabase: watch("showSiteDatabase"),
       editSiteDatabase: watch("editSiteDatabase"),
       showSiteBackups: watch("showSiteBackups"),
       editSiteBackups: watch("editSiteBackups"),
       showSiteCronjobs: watch("showSiteCronjobs"),
       editSiteCronjobs: watch("editSiteCronjobs")
      }}
      setApp={(section, value) => handleSetApp(section, value)}
      addApp={(section, value) => handleControlSelection(section, value)}
      setPermission={(section, value) => setValue(section, value)}
     />
     <AddonsSection
      control={control}
      addons={addons}
      selectedAddons={{
       cloudboxes: watch("cloudboxes"),
       volumes: watch("volumes"),
       snapshots: watch("snapshots"),
       firewalls: watch("firewalls"),
       floating_ips: watch("floating_ips"),
       networks: watch("networks"),
       load_balancers: watch("load_balancers"),
       cloudbuckets: watch("cloudbuckets")
      }}
      setAddons={(section, value) => setValue(section, value)}
      addAddons={(section, value) => handleControlSelection(section, value)}
      setPermission={(section, value) => setValue(section, value)}
      addonsPermissions={{
       showCloudbox: watch("showCloudbox"),
       showFirewall: watch("showFirewall"),
       showFloatingIp: watch("showFloatingIp"),
       showNetwork: watch("showNetwork"),
       showVolume: watch("showVolume"),
       showLoadBalancer: watch("showLoadBalancer"),
       editFloatingIp: watch("editFloatingIp"),
       editFloatingIpRdns: watch("editFloatingIpRdns"),
       editFloatingIpServer: watch("editFloatingIpServer"),
       editVolume: watch("editVolume"),
       editVolumeMount: watch("editVolumeMount"),
       editVolumeServer: watch("editVolumeServer"),
       editLoadBalancer: watch("editLoadBalancer"),
       showCloudboxCommands: watch("showCloudboxCommands"),
       showCloudboxDetails: watch("showCloudboxDetails"),
       editCloudboxDetails: watch("editCloudboxDetails"),
       showCloudboxSettings: watch("showCloudboxSettings"),
       editCloudboxSettings: watch("editCloudboxSettings"),
       showCloudboxServer: watch("showCloudboxServer"),
       editCloudboxServer: watch("editCloudboxServer"),
       showCloudboxSnapshot: watch("showCloudboxSnapshot"),
       editCloudboxSnapshot: watch("editCloudboxSnapshot"),
       showNetworkDetails: watch("showNetworkDetails"),
       editNetworkDetails: watch("editNetworkDetails"),
       showNetworkSubnet: watch("showNetworkSubnet"),
       editNetworkSubnet: watch("editNetworkSubnet"),
       showNetworkRoute: watch("showNetworkRoute"),
       editNetworkRoute: watch("editNetworkRoute"),
       showFirewallDetails: watch("showFirewallDetails"),
       editFirewallDetails: watch("editFirewallDetails"),
       showFirewallServer: watch("showFirewallServer"),
       editFirewallServer: watch("editFirewallServer"),
       showFirewallInboundRules: watch("showFirewallInboundRules"),
       editFirewallInboundRules: watch("editFirewallInboundRules"),
       showFirewallOutboundRules: watch("showFirewallOutboundRules"),
       editFirewallOutboundRules: watch("editFirewallOutboundRules"),
       showLoadBalancerDetails: watch("showLoadBalancerDetails"),
       editLoadBalancerDetails: watch("editLoadBalancerDetails"),
       showLoadBalancerGraph: watch("showLoadBalancerGraph"),
       showLoadBalancerCertificate: watch("showLoadBalancerCertificate"),
       editLoadBalancerCertificate: watch("editLoadBalancerCertificate"),
       showLoadBalancerService: watch("showLoadBalancerService"),
       showLoadBalancerTarget: watch("showLoadBalancerTarget"),
       showLoadBalancerNetwork: watch("showLoadBalancerNetwork"),
       editLoadBalancerNetwork: watch("editLoadBalancerNetwork"),
       showSnapshot: watch("showSnapshot"),
       editSnapshot: watch("editSnapshot"),
       showCloudbuckets: watch("showCloudbuckets"),
       editConnectCloudbucket: watch("editConnectCloudbucket")
      }}
     />
    </Stack>
   </form>
   <Stack pt={2} direction="row">
    <Button
     variant="kxActionButton"
     disabled={checkDisabled() || isLoading}
     onClick={handleSubmit(onSubmit)}>
     Conferma
    </Button>
   </Stack>
  </>
 );
};

export default UpdateUserSettings;
