/* eslint-disable react/style-prop-object */
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import { checkWeekBefore } from "helpers/generic";

import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import AutoRenew from "components/profile/services/actions/AutoRenew";
import ChangeServiceExpire from "components/profile/services/actions/ChangeServiceExpire";
import DeleteService from "components/profile/services/actions/DeleteService";
import ManualRenew from "components/profile/services/actions/ManualRenew";
import RestoreDomain from "components/profile/services/actions/RestoreDomain";
import AdminWrapper from "components/shared/admin/AdminWrapper";
import IconSelector from "components/shared/images/IconSelector";

import { postChangeDomainAutomigration } from "redux/handlers/domainsHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { serviceTableData } from "types/global/table";

const ServicesTable = ({
 rowData,
 index
}: {
 rowData: serviceTableData;
 index: number;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const [check, setCheck] = useState<boolean>(rowData.autorenew);
 const [automigration, setAutomigration] = useState<boolean>(rowData.automigration || false);

 useEffect(() => {
  setCheck(rowData.autorenew);
 }, [rowData.autorenew]);

 const iconFilter = (status: string | null) => {
  switch (status) {
   case "active":
    return "StatusIcon";
   case "expiring":
    return "WarningIcon";
   default:
    return "ReportIcon";
  }
 };

 const colorFilter = (status: string | null) => {
  switch (status) {
   case "active":
    return "success";
   case "expiring":
    return "warning";
   case "in renew":
    return "warning";
   default:
    return "error";
  }
 };

 const colorDateFilter = (status: string | null) => {
  switch (status) {
   case "active":
    return "black";
   case "expiring":
    return "orange";
   case "in renew":
    return "orange";
   default:
    return "red";
  }
 };

 const RenderManualRenew = ({
  status,
  autorenew,
  type,
  expire,
  restore_price
 }: {
  status: string;
  autorenew: boolean;
  type: string;
  expire: string;
  restore_price: string;
 }): ReactElement => {
  if (autorenew && status === "in renew") {
   return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ cursor: "pointer" }}>
     <Typography>
      <FormattedMessage id="app.autorenew" />
     </Typography>
     <IconSelector icon="CheckCircleIcon" props={{ color: "success" }} />
     <Tooltip title={<FormattedMessage id="app.inRenewAutorenewMessage" />} placement="top">
      <Stack>
       <IconSelector icon="InfoIcon" props={{ color: "info" }} />
      </Stack>
     </Tooltip>
    </Stack>
   );
  } else if (autorenew) {
   return <AutoRenew id={rowData.id} type={type} startCheck={check} />;
  } else if (!autorenew && status === "expiring" && checkWeekBefore(expire)) {
   return <ManualRenew rowData={rowData} status={status} restore_price={restore_price} />;
  } else if (!autorenew && status === "expired" && type !== "domain") {
   return <ManualRenew rowData={rowData} status={status} restore_price={restore_price} />;
  } else if (!autorenew && status === "expired" && type === "domain") {
   return <RestoreDomain domain_id={rowData.domain_id_db} />;
  } else {
   return <AutoRenew id={rowData.id} type={type} startCheck={check} />;
  }
 };

 const handleChangeAutomigration = async (event: ChangeEvent<HTMLInputElement>) => {
  setAutomigration(event.target.checked);
  await dispatch(postChangeDomainAutomigration(rowData.domain_id_db, event.target.checked));
 };

 return (
  <TableRow key={`host-list-${index}`}>
   <TableCell component="th" scope="row">
    <Tooltip title={rowData.status} placement="top">
     <IconSelector
      icon={iconFilter(rowData.status)}
      props={{ color: colorFilter(rowData.status) }}
     />
    </Tooltip>
   </TableCell>
   <TableCell component="th" scope="row">
    {rowData.type}
    <AdminWrapper>
     <DeleteService id={rowData.id} />
    </AdminWrapper>
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack>
     {rowData.name}
     <AdminWrapper>
      <Typography variant="overline">{rowData.personal_name}</Typography>
     </AdminWrapper>
    </Stack>
   </TableCell>
   <TableCell component="th" scope="row">
    <Typography variant="subtitle2" sx={{ color: colorDateFilter(rowData.status) }}>
     <FormattedDate value={rowData.expire} />
    </Typography>
    <AdminWrapper>
     <ChangeServiceExpire id={rowData.id} />
    </AdminWrapper>
   </TableCell>
   <TableCell component="th" scope="row">
    <FormattedNumber value={rowData.price} style="currency" currency="EUR" />
   </TableCell>
   <TableCell component="th" scope="row">
    <Stack>
     <RenderManualRenew
      autorenew={rowData.autorenew}
      type={rowData.type}
      status={rowData.status}
      expire={rowData.expire}
      restore_price={rowData.restore_price}
     />
     {rowData.type === "domain" && rowData.status === "active" && (
      <Stack direction="row" alignItems="center" spacing={1}>
       <FormControlLabel
        label={<FormattedMessage id="domain.automigrate" />}
        control={<Switch checked={automigration} onChange={handleChangeAutomigration} />}
       />
      </Stack>
     )}
    </Stack>
   </TableCell>
  </TableRow>
 );
};

export default ServicesTable;
