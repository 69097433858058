/* eslint-disable react/style-prop-object */
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getRidConfirm, getStripeAutopayment } from "redux/selectors/userSelector";

import SepaPaymentCard from "./components/SepaPaymentCard";
import StripePaymentCard from "./components/StripePaymentCard";

const AutomaticPayment = (): ReactElement => {
 const confirm = useSelector(getRidConfirm);
 const confirmStripe = useSelector(getStripeAutopayment);

 const verifyPayments = (): boolean => {
  return confirm !== null || confirmStripe !== 0;
 };

 return (
  <Stack px={2} pt={1}>
   <Typography textAlign="left" variant="h6">
    <FormattedMessage id="profile.security.autoPayment" />
   </Typography>
   <Divider sx={{ mb: 1 }} />
   <Typography textAlign="left" variant="subtitle2">
    <FormattedMessage id="profile.security.automaticPaymentMessage" />
   </Typography>
   <Divider sx={{ mb: 1 }} />
   {!verifyPayments() ? (
    <Alert severity="warning" sx={{ mt: 2 }}>
     <FormattedMessage id="profile.security.noAutomaticPayment" />
    </Alert>
   ) : (
    <Stack pt={1} spacing={2}>
     {confirm !== null && <SepaPaymentCard confirm={confirm || false} />}
     {confirmStripe !== 0 && <StripePaymentCard />}
    </Stack>
   )}
  </Stack>
 );
};

export default AutomaticPayment;
