import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import IconSelector from "components/shared/images/IconSelector";

import { postCreatePortalSession } from "redux/handlers/rechargeHandler";
import { postChangeStripeSettings } from "redux/handlers/userHandler";

import { getStripeAutopayment, getUserId } from "redux/selectors/userSelector";

import { useAppDispatch } from "hooks/reduxHook";

const AutopaymentResultPage = (): ReactElement => {
 const navigate = useNavigate();
 const dispatch = useAppDispatch();

 const id = useSelector(getUserId);
 const confirm = useSelector(getStripeAutopayment);

 const [success, setSuccess] = useState<boolean>(false);
 const [sessionId, setSessionId] = useState<string>("");
 const [amount, setAmount] = useState<number>(0);
 const [url, setUrl] = useState<string>("");

 useEffect(() => {
  // Check to see if this is a redirect back from Checkout
  const query = new URLSearchParams(window.location.search);

  if (query.get("success")) {
   setSuccess(true);
   setSessionId(query.get("session_id") || "");
   setAmount(Number(query.get("amount")) || 0);
  }

  if (query.get("canceled")) {
   setSuccess(false);
  }
 }, [sessionId]);

 useEffect(() => {
  (async () => {
   if (success && confirm !== 1) {
    setUrl(
     await dispatch(
      postCreatePortalSession(sessionId, "https://konsolex.onthecloud.srl/profile/recharge")
     )
    );
    await dispatch(postChangeStripeSettings(id, 1, amount));
   }
  })();
 }, [sessionId, success]);

 const handleReturn = async () => {
  navigate("/profile/recharge");
 };

 const handleBilling = async () => {
  if (url !== "") window.location.href = url;
 };

 return (
  <Container>
   <Stack justifyContent="center" height="100vh">
    <Paper elevation={0} sx={{ borderRadius: "10px", boxShadow: 0, mt: 2 }}>
     {success ? (
      <Stack spacing={2} p={2} alignItems="center">
       <IconSelector icon="CheckCircleIcon" props={{ sx: { fontSize: 100 }, color: "success" }} />
       <Typography fontWeight="bold" variant="h6">
        <FormattedMessage id="recharge.autopayment.successTitle" />
       </Typography>
       <Typography>
        <FormattedMessage id="recharge.autopayment.successMessage" />
       </Typography>
       <Stack direction="row">
        <Button onClick={handleBilling}>
         <FormattedMessage id="recharge.goToBilling" />
        </Button>
        <Button onClick={handleReturn}>
         <FormattedMessage id="recharge.returnToProfile" />
        </Button>
       </Stack>
      </Stack>
     ) : (
      <Stack spacing={2} p={2} alignItems="center">
       <IconSelector icon="DangerousIcon" props={{ sx: { fontSize: 100 }, color: "error" }} />
       <Typography fontWeight="bold" variant="h6">
        <FormattedMessage id="recharge.autopayment.errorTitle" />
       </Typography>
       <Typography>
        <FormattedMessage id="recharge.autopayment.errorMessage" />
       </Typography>
       <Stack direction="row">
        <Button onClick={handleReturn}>
         <FormattedMessage id="recharge.returnToProfile" />
        </Button>
       </Stack>
      </Stack>
     )}
    </Paper>
   </Stack>
  </Container>
 );
};

export default AutopaymentResultPage;
