import React, { ReactElement } from "react";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import { isNil } from "ramda";

import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { IAllAddonsAPI, IAllAddonsDataset } from "types/api/addonsApiInterface";
import { addonsSectionType, addonsTeamsPermissionType } from "types/global/teams";
import { addonsPermissionType, addonsSelectedList } from "types/global/user";

const TeamsLoadBalancer = ({
 control,
 addons,
 selectedAddons,
 addonsPermissions,
 setAddons,
 addAddons,
 setPermission
}: {
 control: any;
 addons: IAllAddonsAPI | null;
 selectedAddons: addonsSelectedList;
 addonsPermissions: addonsPermissionType;
 setAddons: (section: addonsSectionType, value: Array<number>) => void;
 addAddons: (section: addonsSectionType, value: Array<{ id: number; label: string }>) => void;
 setPermission: (section: addonsTeamsPermissionType, value: boolean) => void;
}): ReactElement => {
 const intl = useIntl();

 return (
  <Stack spacing={2}>
   <Controller
    name="load_balancers"
    control={control}
    render={({ field }) => (
     <Autocomplete
      {...field}
      multiple
      id="tags-outlined"
      options={
       addons?.load_balancers
        ? addons?.load_balancers?.map((element: IAllAddonsDataset) => {
           return {
            id: element.id,
            label: element.name
           };
          })
        : []
      }
      filterSelectedOptions
      value={addons?.load_balancers
       .filter((element) => selectedAddons.load_balancers.includes(element.id))
       .map((element) => {
        return {
         id: element.id,
         label: element.name
        };
       })}
      getOptionLabel={(option) => option.label}
      onChange={(e, value) => {
       isNil(value)
        ? setAddons("load_balancers", selectedAddons.load_balancers)
        : addAddons("load_balancers", value);
      }}
      renderInput={(params) => (
       <TextField
        {...params}
        label={intl.formatMessage({ id: "teams.usersettings.selectLoadBalancers" })}
        placeholder="Load balancer..."
       />
      )}
     />
    )}
   />
   <Stack direction="row">
    <Stack>
     <Controller
      name="showLoadBalancer"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showLoadBalancer}
          onChange={(e, checked) => {
           setPermission("showLoadBalancer", checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showLoadBalancer" })}
       />
      )}
     />
     <Controller
      name="showLoadBalancerGraph"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showLoadBalancerGraph}
          onChange={(e, checked) => {
           setPermission("showLoadBalancerGraph", checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showLoadBalancerGraph" })}
       />
      )}
     />
     <Controller
      name="showLoadBalancerTarget"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showLoadBalancerTarget}
          onChange={(e, checked) => {
           setPermission("showLoadBalancerTarget", checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showLoadBalancerTarget" })}
       />
      )}
     />
     <Controller
      name="showLoadBalancerService"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showLoadBalancerService}
          onChange={(e, checked) => {
           setPermission("showLoadBalancerService", checked);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showLoadBalancerService" })}
       />
      )}
     />
     <Controller
      name="showLoadBalancerDetails"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showLoadBalancerDetails}
          onChange={(e, checked) => {
           setPermission("showLoadBalancerDetails", checked);
           if (!checked) setPermission("editLoadBalancerDetails", false);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showLoadBalancerDetails" })}
       />
      )}
     />
     <Controller
      name="showLoadBalancerCertificate"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showLoadBalancerCertificate}
          onChange={(e, checked) => {
           setPermission("showLoadBalancerCertificate", checked);
           if (!checked) setPermission("editLoadBalancerCertificate", false);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showLoadBalancerCertificate" })}
       />
      )}
     />
     <Controller
      name="showLoadBalancerNetwork"
      control={control}
      render={({ field }) => (
       <FormControlLabel
        control={
         <Checkbox
          {...field}
          checked={addonsPermissions.showLoadBalancerNetwork}
          onChange={(e, checked) => {
           setPermission("showLoadBalancerNetwork", checked);
           if (!checked) setPermission("editLoadBalancerNetwork", false);
          }}
         />
        }
        label={intl.formatMessage({ id: "teams.usersettings.showLoadBalancerNetwork" })}
       />
      )}
     />
    </Stack>
    <Stack>
     {addonsPermissions.showLoadBalancerDetails && (
      <Controller
       name="editLoadBalancerDetails"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         control={
          <Checkbox
           {...field}
           checked={addonsPermissions.editLoadBalancerDetails}
           onChange={(e, checked) => {
            setPermission("editLoadBalancerDetails", checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "teams.usersettings.editLoadBalancerDetails" })}
        />
       )}
      />
     )}
     {addonsPermissions.showLoadBalancerCertificate && (
      <Controller
       name="editLoadBalancerCertificate"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         control={
          <Checkbox
           {...field}
           checked={addonsPermissions.editLoadBalancerCertificate}
           onChange={(e, checked) => {
            setPermission("editLoadBalancerCertificate", checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "teams.usersettings.editLoadBalancerCertificate" })}
        />
       )}
      />
     )}
     {addonsPermissions.showLoadBalancerNetwork && (
      <Controller
       name="editLoadBalancerNetwork"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         control={
          <Checkbox
           {...field}
           checked={addonsPermissions.editLoadBalancerNetwork}
           onChange={(e, checked) => {
            setPermission("editLoadBalancerNetwork", checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "teams.usersettings.editLoadBalancerNetwork" })}
        />
       )}
      />
     )}
    </Stack>
   </Stack>
  </Stack>
 );
};

export default TeamsLoadBalancer;
