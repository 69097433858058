import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import DatabaseFrame from "components/shared/externalApps/DatabaseFrame";

import {
 postGetDatabaseData,
 postInstallAdminer,
 postInstallPhpAdmin
} from "redux/handlers/dockerHandler";

import { getServerDetails } from "redux/selectors/serversSelector";
import { getPhpSession } from "redux/selectors/sessionSelector";

import { useAppDispatch } from "hooks/reduxHook";

import { IDatabaseData } from "types/api/dockerApiInterface";

const defaultDatabase = {
 container_id: "",
 db_name: "",
 db_password: "",
 db_root_password: "",
 db_user: "",
 db_type: "",
 id: 0,
 db_host: "",
 remote: false,
 remote_port: null
};

const DbNavigatorSection = ({
 id,
 dbType,
 container_id,
 active,
 adminer
}: {
 id: number;
 dbType: string;
 container_id: string;
 active: boolean;
 adminer: boolean;
}): ReactElement => {
 const dispatch = useAppDispatch();

 const server = useSelector(getServerDetails);
 const sessionActive = useSelector(getPhpSession);

 const [loading, setLoading] = useState<boolean>(false);
 const [database, setDatabase] = useState<IDatabaseData | null>(null);
 const [selectedNavigator, setSelectedNavigator] = useState<string>("");

 useEffect(() => {
  (async () => {
   setLoading(true);
   const dblist = await dispatch(postGetDatabaseData(container_id));
   setDatabase(dblist[0]);
   setLoading(false);
  })();
 }, []);

 const handleLoadData = async () => {
  setLoading(true);
  if (dbType === "postgres") {
   const result = await dispatch(postInstallAdminer(id));
   if (result && container_id) {
    const dblist = await dispatch(postGetDatabaseData(container_id));
    setDatabase(dblist[0]);
   }
  } else {
   if (selectedNavigator === "phpmyadmin") {
    const result = await dispatch(postInstallPhpAdmin(id));
    if (result && container_id) {
     const dblist = await dispatch(postGetDatabaseData(container_id));
     setDatabase(dblist[0]);
    }
   } else {
    const result = await dispatch(postInstallAdminer(id));
    if (result && container_id) {
     const dblist = await dispatch(postGetDatabaseData(container_id));
     setDatabase(dblist[0]);
    }
   }
  }
  setLoading(false);
 };

 const RenderNavigatorBox = (): ReactElement => {
  switch (dbType) {
   case "postgres":
    return (
     <Stack spacing={2}>
      <Alert severity="warning">
       <FormattedMessage id="docker.website.AdminerOff" />
      </Alert>
      <Stack direction="row">
       <Button onClick={handleLoadData}>
        <FormattedMessage id="docker.website.activateAdminer" />
       </Button>
      </Stack>
     </Stack>
    );
   default:
    return (
     <Stack spacing={2}>
      <Alert severity="warning">
       <FormattedMessage id="docker.website.navigatorOff" />
      </Alert>
      <FormControl>
       <InputLabel>
        <FormattedMessage id="docker.website.service" />
       </InputLabel>
       <Select
        label={<FormattedMessage id="docker.website.service" />}
        value={selectedNavigator}
        onChange={(e) => setSelectedNavigator(e.target.value)}>
        <MenuItem key="http-key" value="adminer">
         Adminer
        </MenuItem>
        <MenuItem key="https-key" value="phpmyadmin">
         PHPMyAdmin
        </MenuItem>
       </Select>
      </FormControl>
      <Stack direction="row">
       <Button disabled={selectedNavigator === ""} onClick={handleLoadData}>
        <FormattedMessage id="docker.website.activateNavigator" />
       </Button>
      </Stack>
     </Stack>
    );
  }
 };

 return (
  <>
   {loading ? (
    <Stack direction="row" alignItems="center" spacing={2}>
     <CircularProgress />
     <Typography>
      <FormattedMessage id="docker.website.loadingDatabaseData" />
     </Typography>
    </Stack>
   ) : active || sessionActive === id ? (
    <DatabaseFrame
     id={id}
     link={`http://${server.ipv4}:8089`}
     database={database || defaultDatabase}
     adminer={adminer}
    />
   ) : (
    <RenderNavigatorBox />
   )}
  </>
 );
};

export default DbNavigatorSection;
