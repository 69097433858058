import React, { ReactElement, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";

import { websiteContainerDatabaseSteps } from "constants/server";

const DbVersion = ({
 activeStep,
 firstValue,
 dbVersions,
 handleNext,
 handleBack,
 handleReset,
 setPropsValue
}: {
 activeStep: number;
 firstValue: string;
 dbVersions: Array<{ id: number; name: string }>;
 handleNext: () => void;
 handleBack: () => void;
 handleReset: () => void;
 setPropsValue: (value: string) => void;
}): ReactElement => {
 const [selectedVers, setSelectedVers] = useState<string>(firstValue);

 const goNext = () => {
  setPropsValue(selectedVers);
  handleNext();
 };

 return (
  <Stack p={2} spacing={2}>
   <FormControl fullWidth>
    <InputLabel>
     <FormattedMessage id="docker.website.databaseVersion" />
    </InputLabel>
    <Select
     value={selectedVers}
     label={<FormattedMessage id="docker.website.databaseVersion" />}
     onChange={(e) => setSelectedVers(e.target.value)}>
     {dbVersions.map((element, index) => {
      return (
       <MenuItem value={element.name} key={`db-vers-${index}`}>
        {element.name}
       </MenuItem>
      );
     })}
    </Select>
   </FormControl>
   <Stack direction="row" spacing={2} justifyContent="center">
    <Button disabled={activeStep === 0} onClick={handleBack}>
     <FormattedMessage id="app.back" />
    </Button>
    <Button onClick={handleReset}>Reset</Button>
    <Button
     disabled={activeStep === websiteContainerDatabaseSteps.length - 1 || selectedVers === ""}
     onClick={goNext}>
     <FormattedMessage id="app.next" />
    </Button>
   </Stack>
  </Stack>
 );
};

export default DbVersion;
