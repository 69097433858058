export const moneyList = [
 { label: "25€", value: 25, available: ["card", "bank", "auto_pay"] },
 { label: "50€", value: 50, available: ["card", "bank", "auto_pay"] },
 { label: "100€", value: 100, available: ["card", "bank", "sepa_bank", "auto_pay"] },
 { label: "150€", value: 150, available: ["card", "bank", "sepa_bank", "auto_pay"] },
 { label: "200€", value: 200, available: ["card", "bank", "sepa_bank", "auto_pay"] },
 { label: "300€", value: 300, available: ["card", "bank", "sepa_bank", "auto_pay"] },
 { label: "500€", value: 500, available: ["card", "bank", "sepa_bank", "auto_pay"] },
 { label: "1000€", value: 1000, available: ["card", "bank", "sepa_bank", "auto_pay"] },
 { label: "1500€", value: 1500, available: ["card", "bank", "sepa_bank", "auto_pay"] },
 { label: "2000€", value: 2000, available: ["card", "bank", "sepa_bank", "auto_pay"] },
 { label: "3000€", value: 3000, available: ["card", "bank", "sepa_bank", "auto_pay"] }
];

export const paymentMethod = [
 { label: "recharge.creditCard", code: "card" },
 { label: "recharge.bankTransfer", code: "bank" },
 { label: "recharge.sepaAutomaticBank", code: "sepa_bank" },
 { label: "recharge.autoCardPayment", code: "auto_pay" }
];

export const sepaTexts = {
 firstSection: ["recharge.sepaFirstList", "recharge.sepaSecondList", "recharge.sepaThirdList"],
 secondSection: ["recharge.sepaFourthList", "recharge.sepaFifthList"]
};
