import { AppAction } from "redux/store";

import NewMessageReducer from "redux/reducers/newMessageReducer";

export const saveNewMessageId =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  console.log(id);
  dispatch(NewMessageReducer.actions.setNewMessages(id));
 };

export const removeMessageId =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  dispatch(NewMessageReducer.actions.removeMessage(id));
 };
