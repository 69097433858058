import { generateUniqueId } from "helpers/generic";
import { AppAction } from "redux/store";

import notificationReducer from "redux/reducers/notificationReducer";
import PermissionReducer from "redux/reducers/permissionReducer";
import userReducer from "redux/reducers/userReducer";

import { IAddonsInfo } from "types/api/addonsApiInterface";
import { IAppsInfo } from "types/api/appsApiInterface";
import {
 I2FAdataAPI,
 IAuthApi,
 IExpensesAPI,
 IPermissionsApi,
 IRidUserAPI,
 IUserApi,
 IUsersList
} from "types/api/userApiInterface";
import { IProfile } from "types/redux/userInterfaces";

import { ApiService } from "service/ApiService";

export const postLogin =
 (email: string, password: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   //da inserire quando si applica la modifica dell'ApiService
   //const ApiData = { url: "/auth", method: "POST", params: { email, password } };
   const { data, status } = await ApiService<IAuthApi>("/auth/login", "POST", { email, password });
   const token = data?.token;
   if (!token || status !== 200) return false; // TODO: handle error with no token
   // Save token in local storage
   localStorage.setItem("jwt", token);
   // Call user info
   await dispatch(getMe());
   await dispatch(getProviderPermissions());
   return true;
  } catch (error) {
   return false;
  }
 };

export const getMe = (): AppAction<Promise<void>> => async (dispatch) => {
 try {
  const { data, status } = await ApiService<IUserApi>("/users/me", "GET");
  status === 200 && dispatch(userReducer.actions.setUser(data));
  localStorage.setItem("language", data?.language || "it");
  if (localStorage.getItem("doubleAuth") === "true")
   dispatch(userReducer.actions.setDoubleAuthenticated());
 } catch (error) {
  localStorage.removeItem("jwt");
  console.warn("FAILED TO GET USER INFO");
 }
};

export const logoutAction = (): AppAction<void> => (dispatch) => {
 localStorage.removeItem("jwt");
 localStorage.removeItem("doubleAuth");
 dispatch(userReducer.actions.resetUser());
};

export const postConfirmContract = (): AppAction<Promise<void>> => async (dispatch) => {
 try {
  await ApiService("/user/editprofiledata", "POST", {
   contract_accepted: true
  });
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to change contract value - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
 }
};

export const postRegistration =
 (
  email: string,
  password: string,
  name: string,
  surname: string,
  repeatPassword: string,
  companyName: string,
  telephone: string
 ): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   const { status } = await ApiService("/auth/register", "POST", {
    email: email,
    password: password,
    firstname: name,
    lastname: surname,
    password2: repeatPassword,
    companyName,
    telephone
   });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Registration successful",
     timestamp: Date.now(),
     type: "success"
    })
   );
   if (status !== 201) return false; // TODO: handle error for status !== 200
   return true;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to register - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const resetAction =
 (email: string): AppAction<Promise<number>> =>
 async (dispatch) => {
  try {
   const { status } = await ApiService("/user/forgetpwd", "POST", { email: email });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Recover email sent",
     timestamp: Date.now(),
     type: "success"
    })
   );
   return status;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to send recover mail - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return 400;
  }
 };

export const getConfirmEmail =
 (token: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { status } = await ApiService("/verify", "POST", { token: token });
   if (status)
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Registration successful",
      timestamp: Date.now(),
      type: "success"
     })
    );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to register - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const hashAction = async (hash: string): Promise<boolean> => {
 try {
  await ApiService("/users/resetpass", "POST", { hash: hash });
  return true;
 } catch (error) {
  console.warn("FAILED TO VERIFY HASH");
  return false;
 }
};

export const resetPasswordAction =
 (hash: string, password: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   await ApiService("/user/resetpwd", "POST", { token: hash, password: password });
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Password changed succesfully",
     timestamp: Date.now(),
     type: "success"
    })
   );
   return true;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change password - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return false;
  }
 };

export const postChangeLanguage =
 (language: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/user/changelang", "POST", { language });
   await dispatch(getMe());
  } catch (error) {
   console.warn("FAILED TO CHANGE LANGUAGE");
  }
 };

export const postChangeProfile =
 (data: IProfile): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   await ApiService("/user/editprofiledata", "POST", {
    firstname: data.firstname,
    lastname: data.lastname,
    companyName: data.companyName,
    email: data.email,
    address: data.street,
    city: data.city,
    country: data.country,
    partitaIVA: data.vatCode,
    zip: data.zipCode,
    telephone: data.phone,
    sdi: data.sdi,
    pec: data.pec
   });
   await dispatch(getMe());
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Changing user data",
     timestamp: Date.now(),
     type: "success"
    })
   );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change user data - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangePassword =
 (oldPassword: string, newPassword: string, newPassword2: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/user/changepassword", "POST", {
    oldPassword: oldPassword,
    newPassword: newPassword,
    newPassword2: newPassword2
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing user password",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change password - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangeProfilePicture = async (avatar: string): Promise<void> => {
 try {
  await ApiService("/users/propicupdate", "POST", { propic: avatar });
 } catch (error) {
  console.warn("FAILED TO CHANGE PROFILE PICTURE");
 }
};

export const getUsersList = (): AppAction<Promise<Array<IUsersList>>> => async (dispatch) => {
 try {
  const { data } = await ApiService<{ users: Array<IUsersList> }>("/user/list");
  return data.users;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to load users - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return [];
 }
};

export const getProviderPermissions = (): AppAction<Promise<void>> => async (dispatch) => {
 try {
  const { data } = await ApiService<IPermissionsApi>("/server/getproviderssetup");
  dispatch(PermissionReducer.actions.setServerPermissions(data.server || []));
  dispatch(PermissionReducer.actions.setSnapshotPermissions(data.snapshot || []));
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to load server permissions - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
 }
};

export const postLogAsClient =
 (userid: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data, status } = await ApiService("/user/adminauth", "POST", {
    userid: userid
   });
   status === 200 && localStorage.setItem("jwt", data?.token || "not provided");
   status === 200 && localStorage.setItem("oldId", data?.oldUserId || "not provided");
  } catch (error: any) {
   localStorage.removeItem("jwt");
   localStorage.removeItem("oldId");
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load backups - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRestoreAsClient =
 (userid: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data, status } = await ApiService("/user/restore", "POST", {
    userid: userid
   });
   status === 200 && localStorage.setItem("jwt", data?.token || "not provided");
   localStorage.removeItem("oldId");
  } catch (error: any) {
   localStorage.removeItem("jwt");
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to load backups - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const generatePassword = async (length?: number): Promise<string> => {
 try {
  const { data } = await ApiService<string>("/user/generatepwd", "POST", { length: length || 10 });
  return data;
 } catch (error) {
  console.warn("FAILED TO GENERATE PASSWORD");
  return "";
 }
};

export const getMonthlyExpenses =
 (): AppAction<Promise<Array<IExpensesAPI>>> => async (dispatch) => {
  try {
   const { data } = await ApiService<Array<IExpensesAPI>>("/movement/monthlyexpenses");
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get expenses - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postChangePartnerStatus =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/user/changeactive", "POST", {
    id
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing partner status",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change partner status - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangePartnerTax =
 (id: number, tax: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/user/updatedatas", "POST", {
    id,
    tax
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Changing partner tax",
      timestamp: Date.now(),
      type: "success"
     })
    );
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to change partner tax - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangeRidSettings =
 (
  id: number,
  iban: string,
  rid_amount: number,
  rid_confirmed: boolean,
  rid_balance_limit: number,
  rid_bank: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/user/updatedatas", "POST", {
    id,
    iban,
    rid_amount,
    rid_confirmed,
    rid_balance_limit,
    rid_bank
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Setting automatic payment",
      timestamp: Date.now(),
      type: "success"
     })
    );
    await dispatch(getMe());
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to set automatic payment - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postUploadRidDocument =
 (
  id: number,
  rid_document: string,
  rid_identity_card: string,
  rid_fc_card: string
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/user/updatedatas", "POST", {
    id,
    rid_document,
    rid_identity_card,
    rid_fc_card
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Document uploaded",
      timestamp: Date.now(),
      type: "success"
     })
    );
    await dispatch(getMe());
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to upload document - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postEnableAutoPayment =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/user/updatedatas", "POST", {
    id,
    rid_confirmed: true
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Enabling automatic payment",
      timestamp: Date.now(),
      type: "success"
     })
    );
    await dispatch(getMe());
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to enable automatic payment - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDisableAutoPayment =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/user/updatedatas", "POST", {
    id,
    rid_confirmed: false
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Disabling automatic payment",
      timestamp: Date.now(),
      type: "success"
     })
    );
    await dispatch(getMe());
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to disable automatic payment - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postRemovePayment =
 (id: number): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/user/updatedatas", "POST", {
    id,
    cancel_rid_datas: true
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Removing automatic payment data",
      timestamp: Date.now(),
      type: "success"
     })
    );
    await dispatch(getMe());
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to remove automatic payment data - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postChangeStripeSettings =
 (
  id: number,
  stripe_autopayment: number,
  stripe_recharge_amount: number
 ): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/user/updatedatas", "POST", {
    id,
    stripe_autopayment,
    stripe_recharge_amount
   });
   if (data) {
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Setting automatic payment",
      timestamp: Date.now(),
      type: "success"
     })
    );
    await dispatch(getMe());
   }
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to set automatic payment - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };

export const postDisableStripeAutoPayment = (): AppAction<Promise<void>> => async (dispatch) => {
 try {
  const { data } = await ApiService("/stripe/deletesubscription", "POST");
  if (data) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Disabling automatic payment",
     timestamp: Date.now(),
     type: "success"
    })
   );
   await dispatch(getMe());
  }
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to disable automatic payment - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
 }
};

export const getExportOrders = (): AppAction<Promise<Blob | null>> => async (dispatch) => {
 try {
  const { data } = await ApiService("/order/exportorders", "GET");
  if (data.message && data.message.includes("No valid orders found to export")) return null;
  const blob = new Blob([data], { type: "text/csv" });
  return blob;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to export orders - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return null;
 }
};

export const getExportStatements = (): AppAction<Promise<Blob | null>> => async (dispatch) => {
 try {
  const { data } = await ApiService("/order/exportordersstatements", "GET");
  if (data.message && data.message.includes("No valid orders found to export")) return null;
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
   byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/zip" });
  return blob;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to export statements - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return null;
 }
};

export const postObtainAddonsRecap =
 (): AppAction<Promise<IAddonsInfo | null>> => async (dispatch) => {
  try {
   const { data } = await ApiService<IAddonsInfo>("/services/addonsinfo", "POST");
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: `Failed to get addons info - ${error?.message || "unknown error"}`,
     timestamp: Date.now(),
     type: "error"
    })
   );
   return null;
  }
 };

export const postObtainAppsRecap = (): AppAction<Promise<IAppsInfo | null>> => async (dispatch) => {
 try {
  const { data } = await ApiService<IAppsInfo>("/app/getstats");
  return data;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to change partner tax - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return null;
 }
};

export const getEnable2FA = (): AppAction<Promise<I2FAdataAPI | null>> => async (dispatch) => {
 try {
  const { data } = await ApiService<I2FAdataAPI>("/auth/enable-2fa");
  return data;
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: `Failed to change partner tax - ${error?.message || "unknown error"}`,
    timestamp: Date.now(),
    type: "error"
   })
  );
  return null;
 }
};

export const getDisable2FA = (): AppAction<Promise<void>> => async (dispatch) => {
 try {
  await ApiService("/auth/disable-2fa");
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: "2FA disabled successfully",
    timestamp: Date.now(),
    type: "success"
   })
  );
  await dispatch(getMe());
 } catch (error: any) {
  dispatch(
   notificationReducer.actions.addAlert({
    id: generateUniqueId(),
    message: "Failed to disable 2FA",
    timestamp: Date.now(),
    type: "error"
   })
  );
 }
};

export const postFirst2FAVerify =
 (token: string): AppAction<Promise<Array<string>>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<Array<string>>("/auth/verify-2fa", "POST", {
    token,
    firstVerify: true
   });
   await dispatch(getMe());
   return data;
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Failed to verify OTP, wrong OTP",
     timestamp: Date.now(),
     type: "error"
    })
   );
   return [];
  }
 };

export const postLoginWith2FA =
 (token: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   //da inserire quando si applica la modifica dell'ApiService
   //const ApiData = { url: "/auth", method: "POST", params: { email, password } };
   const { status } = await ApiService("/auth/verify-2fa", "POST", {
    token
   });
   if (!token || status !== 200) return false; // TODO: handle error with no token
   // Save status in local storage
   localStorage.setItem("doubleAuth", "true");
   return true;
  } catch (error) {
   return false;
  }
 };

export const postLoginWithBackupCode =
 (token: string): AppAction<Promise<boolean>> =>
 async (dispatch) => {
  try {
   //da inserire quando si applica la modifica dell'ApiService
   //const ApiData = { url: "/auth", method: "POST", params: { email, password } };
   const { status } = await ApiService("/auth/recovery", "POST", {
    recoveryCode: token
   });
   if (!token || status !== 200) return false; // TODO: handle error with no token
   // Save status in local storage
   localStorage.setItem("doubleAuth", "true");
   return true;
  } catch (error) {
   return false;
  }
 };

export const getAllRidUsers =
 (
  currentIndex: number,
  sizePerPage: number,
  q?: string,
  userid?: string,
  id?: number
 ): AppAction<Promise<IRidUserAPI>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService<IRidUserAPI>(
    `/user/getridusers?page=${currentIndex}&limit=${sizePerPage}&q=${q || ""}&partner=${
     userid || ""
    }&id=${id || 0}`
   );
   return data;
  } catch (error) {
   console.warn("FAILED TO GET CLOUDBUCKETS");
   return { dataset: [], totalCount: 0 };
  }
 };

export const postConfirmUserDocuments =
 (userid: string): AppAction<Promise<void>> =>
 async (dispatch) => {
  try {
   const { data } = await ApiService("/user/setriddocumentverified", "POST", { userid: userid });
   if (data)
    dispatch(
     notificationReducer.actions.addAlert({
      id: generateUniqueId(),
      message: "Setting documents as verified",
      timestamp: Date.now(),
      type: "success"
     })
    );
  } catch (error: any) {
   dispatch(
    notificationReducer.actions.addAlert({
     id: generateUniqueId(),
     message: "Failed to set document as verified",
     timestamp: Date.now(),
     type: "error"
    })
   );
  }
 };
