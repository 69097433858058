import React, { ReactElement, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import AppModal from "components/shared/modal/AppModal";

import { postConfirmUserDocuments } from "redux/handlers/userHandler";

import { useAppDispatch } from "hooks/reduxHook";

const ConfirmDocuments = ({
 userid,
 closeMenu,
 reloadData
}: {
 userid: string;
 closeMenu: () => void;
 reloadData: () => void;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [open, setOpen] = useState<boolean>(false);
 const [loading, setLoading] = useState<boolean>(false);

 const handleOpen = () => setOpen(true);
 const handleClose = () => setOpen(false);

 const handleConfirm = async () => {
  setLoading(true);
  await dispatch(postConfirmUserDocuments(userid));
  handleClose();
  setLoading(false);
  reloadData();
  closeMenu();
 };

 return (
  <>
   <MenuItem onClick={() => handleOpen()}>
    <FormattedMessage id="configurator.autopayment.confirmDocument" />
   </MenuItem>
   <AppModal
    open={open}
    close={handleClose}
    title={intl.formatMessage({ id: "configurator.autopayment.confirmDocument" })}
    handleClose={handleClose}
    disabled={loading}
    handleConfirm={handleConfirm}>
    <Typography>
     <FormattedMessage id="configurator.autopayment.confirmDocumentMessage" />
    </Typography>
   </AppModal>
  </>
 );
};

export default ConfirmDocuments;
